
import AppContext from 'context';
import { useContext, useEffect, useState, useRef, useMemo } from 'react';
import TemplateModule from '../TemplateModule';
import { useSelector } from 'react-redux';
import * as regexes from 'services/validatationRegexs';
import history from 'services/history';
import ParentHref from 'components/site/ParentHref';
import { isObjectEmpty } from 'services/helpFunctions';

const ContractPaymentHierarchyTypes = (props) => {
    const contractID = props.match.params.ContractID;
    const projectID = props.match.params.ProjectID;
    const { systemParams } = useSelector(state => state.system);
    const [contract, setContract] = useState(useSelector(state => state.modules.selectedDataRow));
    const [project, setProject] = useState(useSelector(state => state.projects.projects.filter(project => project.ProjectID.toString() === projectID)[0]));
    const user = useSelector(state => state.auths.user);
    const context = useRef(useContext(AppContext)).current;
    const [paymentHierarchyTypes, setTypes] = useState([]);
    const [properties, setProperties] = useState({
        display: ["PaymentHierarchyTypeName", "PaymentHierarchyPrice", "PaymentHierarchyPriceCurrencyType", "PaymentHierarchyQuantityStart", "PaymentHierarchyQuantityEnd"],
        dataRowViewerDisplay: ["UserName", "PaymentHierarchyTypeName", "PaymentHierarchyPrice", "PaymentHierarchyPriceCurrencyType", "PaymentHierarchyQuantityStart", "PaymentHierarchyQuantityEnd", "IsActive", "DateCreated"],
        notEditable: ["RecordID", "UserName", "DateCreated"],
        primaryKey: ["RecordID"],
        defaultOnInsert: {
            ContractID: contractID
        },
        readable: {
            removePrefix: ["PaymentHierarchy"],
            PaymentHierarchyPriceCurrencyType: "Currency"
        },
        validation: {
            PaymentHierarchyTypeName: [regexes.NotEmpty],
            PaymentHierarchyQuantityStart: [regexes.PositiveFloat],
            PaymentHierarchyQuantityEnd: [regexes.PositiveFloat],
            PaymentHierarchyPrice: [regexes.NotEmpty, regexes.PositiveFloat],
            PaymentHierarchyPriceCurrencyType: [regexes.NotEmpty],
            UserName: [regexes.NotEmpty]
        },
        sortBy: ["PaymentHierarchyTypeName", "PaymentHierarchyPrice", "PaymentHierarchyQuantityStart", "PaymentHierarchyQuantityEnd"],
        searchBy: ["PaymentHierarchyTypeName"],
        selectOptions: {},
        onInsertFunc: context.InsertNewContractPaymentHierarchyType,
        onUpdateFunc: context.UpdateContractPaymentHierarchyType,
    })

    useMemo(() => {
        if (!project) {
            context.GetProjectByID(projectID).then(project => { project ? setProject(project) : history.push('/') })
        }
    }, [project, context, projectID])

    useMemo(() => {
        if (isObjectEmpty(contract)) {
            context.GetContractByID(contractID).then(contract => { contract ? setContract(contract) : history.push('/') })
        }
    }, [context, contract, contractID])

    useEffect(() => {
        context.GetContractPaymentHierarchyTypes(contractID).then(data => {
            if (data) {
                setTypes(data);
            }
        });
        context.GetCurrencies().then(currencies => {
            setProperties(state => ({
                ...state,
                selectOptions: {
                    ...state.selectOptions,
                    PaymentHierarchyPriceCurrencyType: {
                        CurrencySymbol: currencies
                    }
                },
                defaultOnInsert: {
                    ...state.defaultOnInsert,
                    PaymentHierarchyPriceCurrencyType: currencies.find(currency => currency.CurrencyID.toString() === systemParams.DefaultCurrencyIDInDB.ParamValue)?.CurrencySymbol
                }
            }));
        });
        context.GetPaymentHierarchyTypes().then(types => {
            setProperties(state => ({
                ...state,
                selectOptions: {
                    ...state.selectOptions,
                    PaymentHierarchyTypeName: types
                }
            }));
        });
    }, [context, contractID, systemParams.DefaultCurrencyIDInDB]);

    useEffect(() => {
        setProperties(state => ({
            ...state,
            defaultOnInsert: {
                ...state.defaultOnInsert,
                UserName: user.UserName
            },
            defaultOnUpdate: {
                ...state.defaultOnUpdate,
                UserName: user.UserName
            }
        }))
    }, [user.UserName]);

    return (
        !isObjectEmpty(contract) && !!project ?
            <TemplateModule
                data={paymentHierarchyTypes}
                properties={properties}
                title={
                    <ParentHref
                        planText={["Payment Hierarchy for contract: ", "For project: "]}
                        parentsName={[contract ? `${contract["ContractName"]}` : "", project ? `${project["ProjectName"]}` : "",]}
                        parents={[contract, project]}
                    />
                }
            />
            : <></>
    );
}

export default ContractPaymentHierarchyTypes;