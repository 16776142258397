import AppContext from 'context';
import { useContext, useEffect, useState, useRef } from 'react';
import TemplateModule from '../TemplateModule';
import * as regexes from 'services/validatationRegexs';

const Customers = () => {
    const context = useRef(useContext(AppContext)).current;
    const [customers, setCustomers] = useState([]);
    const [properties] = useState({
        display: ["CustomerName", "CustomerContactFName", "CustomerContactLName", "CustomerContactEmail", "CustomerContactPhone", "CustomerAddress"],
        dataRowViewerDisplay: ["CustomerName", "CustomerContactFName", "CustomerContactLName", "CustomerContactEmail", "CustomerContactPhone", "CustomerAddress", "IsActive", "DateCreated"],
        notEditable: ["CustomerID", "DateCreated"],
        primaryKey: ["CustomerID"],
        readable: {
            removePrefix:[],
            CustomerName: "Customer Name",
            CustomerContactFName: "Contact First Name",
            CustomerContactLName: "Contact Last Name",
            CustomerContactEmail: "Contact Email",
            CustomerContactPhone: "Contact Phone",
            CustomerAddress: "Customer Address"
        },
        validation: {
            CustomerName: [regexes.Unique, regexes.NotEmpty, regexes.MaxLength100],
            CustomerAddress: [regexes.MaxLength250],
            CustomerContactEmail: [regexes.NotEmpty, regexes.MaxLength100, regexes.Email],
            CustomerContactFName: [regexes.NotEmpty, regexes.MaxLength50],
            CustomerContactLName: [regexes.NotEmpty, regexes.MaxLength50],
            CustomerContactPhone: [regexes.NotEmpty, regexes.MaxLength50],
        },
        sortBy: ["CustomerName"],
        searchBy: ["CustomerName", "CustomerContactPhone", "CustomerContactFName", "CustomerContactEmail"],
        onInsertFunc: context.InsertNewCustomer,
        onUpdateFunc: context.UpdateCustomer
    })
    
    useEffect(() =>{
        context.GetCustomers().then(data =>{
            setCustomers(data); 
        });
    }, [context]);

    return(
        <TemplateModule data={customers} properties={properties}/>
    );
}

export default Customers;