import AppContext from 'context';
import { useContext, useEffect, useState, useRef } from 'react';
import TemplateModule from '../TemplateModule';
import * as regexes from 'services/validatationRegexs';

const ActivityTypes = () => {
    const context = useRef(useContext(AppContext)).current;
    const [activities, setAtivities] = useState([]);
    const [properties, setProperties] = useState({
        display: ["ActivityTypeName", "UnitTypeName", "ActivityTypeDescription"],
        dataRowViewerDisplay: ["ActivityTypeName", "UnitTypeName", "ActivityTypeDescription", "IsActive"],
        notEditable: ["ActivityTypeID"],
        primaryKey: ["ActivityTypeID"],
        readable: {
            removePrefix: [],
            ActivityTypeID: "Activity ID",
            ActivityTypeName: "Activity Name",
            UnitTypeName: "Unit Type",
            ActivityTypeDescription: "Description"
        },
        validation: {
            ActivityTypeName: [regexes.Unique, regexes.NotEmpty, regexes.MaxLength50],
            UnitTypeName: [regexes.NotEmpty],
            AactivityTypeDescription: [regexes.MaxLength250]
        },
        sortBy: ["ActivityTypeName"],
        searchBy: ["ActivityTypeName", "UnitTypeName"],
        onInsertFunc: context.InsertNewActivityType,
        onUpdateFunc: context.UpdateActivityType
    })

    useEffect(() => {
        context.GetActivities().then(data => {
            setAtivities(data);
        });
        context.GetUnitTypes().then(data => {
            setProperties(state => ({
                ...state,
                selectOptions: {
                    ...state.selectOptions,
                    UnitTypeName: data
                }
            }));
        });
    }, [context]);

    return (
        <TemplateModule data={activities} properties={properties} />
    );
}

export default ActivityTypes;

