import AppContext from 'context';
import { useContext, useEffect, useState, useRef } from 'react';
import TemplateModule from '../TemplateModule';
import * as regexes from 'services/validatationRegexs';

const PermissionPerModule = () => {
    const context = useRef(useContext(AppContext)).current;
    const [permissions, setPermissions] = useState([]);
    const [properties] = useState({
        display: ["ModuleDisplayName", "RoleName", "ModulePermission", "EditPermission", "DeletePermission", "InsertPermission"],
        dataRowViewerDisplay:["ModuleDisplayName", "RoleName", "ModulePermission", "EditPermission", "DeletePermission", "InsertPermission"],
        notEditable: ["ModuleDisplayName", "RoleName"],
        primaryKey: ["ModuleID", "RoleID"],
        readable: {
            removePrefix:[],
            ModuleDisplayName: "Module Name",
        },
        validation: {
            ModuleDisplayName: [regexes.NotEmpty],
            RoleName: [regexes.NotEmpty],
            EditPermission: [regexes.NotEmpty],
            DeletePermission: [regexes.NotEmpty],
            InsertPermission: [regexes.NotEmpty],
            ModulePermission: [regexes.NotEmpty],
        },
        sortBy: ["ModuleDisplayName", "RoleName", "ModulePermission"],
        searchBy: ["ModuleDisplayName", "RoleName"],
        selectOptions: {},
        isAddable: false,
        isDeleteable: false,
        onUpdateFunc: context.UpdatePermission
    })
    
    useEffect(() =>{
        context.GetPermissions().then(permissions => {
            if(permissions){
                setPermissions(permissions);
            }
        })
    }, [context]);

    return(
        <TemplateModule data={permissions} properties={properties}/>
    );
}

export default PermissionPerModule;