import * as actions from "actions/actionTypes";

export const LoginUser = (user) => {
    window.sessionStorage.setItem('gid', user.AuthorizeGuid);
    return {
        type: actions.USER_LOGGEDIN,
        payload: {
            user
        }
    }
}

export const LogoutUser = () => {
    window.sessionStorage.removeItem('gid');
    return {
        type: actions.USER_LOGGEDOUT,
    }
}