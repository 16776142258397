import AppContext from 'context';
import styled from 'styled-components';
import { useContext } from 'react';
import { useTransition, animated } from 'react-spring';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Mask from 'components/site/Mask';

const ServerError = () => {
    const { showError, setShowError, errorMessage } = useContext(AppContext);

    const blockTransitions = useTransition(showError, null, {
        from: { opacity: 0, transform: 'translateX(90%)' },
        enter: { opacity: 1, transform: 'translateX(-5%)' },
        leave: { opacity: 0, transform: 'translateX(90%)' },
    })

    return (
        <>
            <Mask opacity={0.3} activate={showError} click={() => setShowError(false)} />
            {
                blockTransitions.map(({ item, key, props }) =>
                    item &&
                    <ServerErrorContainer
                        as={animated.div}
                        key={key}
                        style={props}
                    >
                        <Close
                            icon={faTimes}
                            onClick={() => setShowError(false)}
                        />
                        <Span>Something went wrong.</Span>
                        <Span>Refresh the page and try again</Span>
                        <Span>or contact your administrator</Span>
                        {/* <Span>{errorMessage}</Span> */}
                    </ServerErrorContainer>
                )
            }
        </>
    )
}

const ServerErrorContainer = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 52;
    bottom: 15px;
    right: 0;
    background-color: ${props => props.theme.colors.lightPurple};
    border: 2px solid ${props => props.theme.colors.purple};
    border-radius: 20px;
    padding: 25px;
`;

const Close = styled(FontAwesomeIcon)`
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    &:hover{
        
    }
`;

const Span = styled.span`

`;

export default ServerError;