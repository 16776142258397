import CenteredDiv from 'components/site/CenteredDiv';
import styled from 'styled-components';
import iconGear from 'media/icons/iconGear.png';
import { useSelector } from 'react-redux';
import history from 'services/history';

const PageNotFound = () =>{
    const user = useSelector(state => state.auths.user);
    const goto = user.AuthorizeGuid? 'homepage' : 'login'
    const handleGoToClick = () =>{
        history.push(user.AuthorizeGuid? '/' : '/login');
    }

    return(
        <CenteredDiv>
            <NotFoundContainer>
                {
                    !user.AuthorizeGuid &&
                    <IconGear src={iconGear} />
                }
                <ErrorContainer>
                    <ErrorCode>404</ErrorCode>
                    <ErrorMsg>Something is missing</ErrorMsg>
                    <DashLink onClick={() => handleGoToClick()}>Go back to {goto}</DashLink>
                </ErrorContainer>
            </ NotFoundContainer>
        </CenteredDiv>
    );
}

const NotFoundContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const ErrorContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
    border-radius: 30px;
    box-shadow: ${props => props.theme.shadowBox.light};
`;

const ErrorCode = styled.div`
    font-size: 70px;
`;

const ErrorMsg = styled.div`
    font-size: 30px;
`;

const IconGear = styled.img`
    width: 100px;
    margin-bottom: 20px;
`;

const DashLink = styled.a`
    color: #000;
    font-weight: bold;
    font-size: 15px;
    margin-top: 80px;
    text-decoration: underline;
    cursor: pointer;
    &:hover{
        color: #000;
    }
`;

export default PageNotFound;