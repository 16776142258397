
//Auth types
export const USER_LOGGEDIN = "USER_LOGGEDIN";
export const USER_LOGGEDOUT = "USER_LOGGEDOUT";

//Tabs Types
export const TABS_LOADED = "TABS_LOADED";
export const TAB_SELECTED = "TAB_SELECTED";
export const TAB_UPDATED = "TAB_UPDATED";
// export const TAB_ADDED = "TAB_ADDED";

//Modules types
export const MODULES_LOADED = "MODULES_LOADED";
export const MODULE_SELECTED = "MODULE_SELECTED";
export const MODULE_CLEARED = "MODULE_CLEARED";
export const MODULE_DATA_LOADED = "MODULE_DATA_LOADED";
export const MODULE_DATA_ROW_SELECTED = "MODULE_DATA_ROW_SELECTED";
export const MODULE_DATA_ISACTIVE_CHANGED = "MODULE_DATA_ISACTIVE_CHANGED";
export const MODULE_DATA_EXTERNAL_OPTIONS_LOADED = "MODULE_DATA_EXTERNAL_OPTIONS_LOADED";
export const MODULE_PROPERTIES_LOADED = "MODULE_PROPERTIES_LOADED";
export const MODULE_DATAROW_UPDATED = "MODULE_DATAROW_UPDATED";
export const MODULE_UPDATED = "MODULE_UPDATED";
export const MODULES_PERMISSIONS_LOADED = "MODULES_PERMISSIONS_LOADED";

//Search types
export const SEARCH_ITEM_SELECTED = "SEARCH_ITEM_SELECTED";
export const SEARCH_UNACTIVE_TOGGLED = "SEARCH_UNACTIVE_TOGGLED";

//Project types
export const PROJECTS_LOADED = "PROJECTS_LOADED";
export const PROJECT_ADDED = "PROJECT_ADDED";

//System types
export const SYSTEM_PARAMS_LOADED = "SYSTEM_PARAMS_LOADED";