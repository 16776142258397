import styled from 'styled-components';

const Input = styled.input`
  width: -webkit-fill-available;
  margin-top: ${props => props.marginTop}px;
  align-self: center;
  color: ${props => props.theme.colors.darkGrey};
  font-size: 15px;
  font-weight: bold;
  border: 2px solid ${props => props.theme.colors.grey};
  border-radius: 12px;
  padding: 7px 18px;
  background-color: ${props => props.disabled? props.theme.colors.lightGrey : 'initial'};
  &:focus{
    border: 2px solid ${props => props.theme.colors.purple};
    color: ${props => props.theme.colors.purple};
    outline: none;
  };
`;

export default Input;