import { SingleDatePicker } from 'react-dates';
import { useState } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import PropType from 'prop-types';

const falseFunc = () => false;
const MySingleDatePicker = ({onDateChange, displayFormat, placeholder, date, isDisabled}) =>{
    const [curDate, setDate] = useState(date);
    const [isFocused, setIsFocused] = useState(false);
    return(
        <SingleDatePickerWrapper>
            <SingleDatePicker  
                date={curDate? moment(curDate) :  null}
                onDateChange={date => {
                    setDate(date);
                    onDateChange(date? date._d : "")
                }}
                focused={isFocused}
                displayFormat={displayFormat}
                onFocusChange={({ focused }) => setIsFocused(focused)}
                placeholder={placeholder}
                isOutsideRange={falseFunc}
                //TODO - disable controller
            />
        </SingleDatePickerWrapper>
    )
}

MySingleDatePicker.defaultProps = {
    displayFormat: 'DD/MM/YYYY',
    placeholder: 'Select...',
    date: null
}

MySingleDatePicker.propTypes = {
    onDateChange: PropType.func.isRequired
}

const SingleDatePickerWrapper = styled.div`
    width: 100%;

    .CalendarDay__selected_span {
        background: ${props => props.theme.colors.lightPurple};
        color: #FFF;
        border: 1px solid ${props => props.theme.colors.darkGrey};
    }

    .CalendarDay__selected {
        background: ${props => props.theme.colors.purple};
        color: #FFF;
        border-color: ${props => props.theme.colors.darkGrey}
    }

    .CalendarDay__selected:hover {
        background: ${props => props.theme.colors.darkGrey};
        color: #FFF;
    }

    .CalendarDay__hovered_span:hover,
    .CalendarDay__hovered_span {
        background: ${props => props.theme.colors.lightPurple};
        border: 1px solid ${props => props.theme.colors.darkGrey};
        color: #FFF;
    }

    .DayPickerKeyboardShortcuts_show__bottomRight::before {
        border-right: 33px solid ${props => props.theme.colors.lightPurple}
    }

    .DateInput_input__focused {
        border-bottom: 2px solid ${props => props.theme.colors.lightPurple};
    }

    .SingleDatePickerInput_arrow_svg {
        fill: ${props => props.theme.colors.lightPurple};
    }

    .SingleDatePickerInput__withBorder {
        border: 2px solid ${props => props.theme.colors.grey};
        border-radius: 12px;
        width: 100%;
    }

    .DateInput_input{
        font-size: 15px;
        color: ${props => props.theme.colors.darkGrey};
        font-weight: bold;
        padding: 7.5px 0px 2.5px 11px;
    }

    .DateInput {
        margin: 0 8px;
        width: 103px;
    }

    .SingleDatePicker {
        width: 100%;
    }

    .DayPickerNavigation_svg__horizontal {
        fill: ${props => props.theme.colors.lightPurple};
    }
`;

export default MySingleDatePicker;
