import AppContext from 'context';
import { useContext, useEffect, useState, useRef } from 'react';
import TemplateModule from '../TemplateModule';
import * as regexes from 'services/validatationRegexs';

const Modules = () => {
    const context = useRef(useContext(AppContext)).current;
    const [modules, setModules] = useState([]);
    const [properties, setProperties] = useState({
        display: ["ModuleDisplayName", "MenuTabDisplayName", "ModuleDescription", "RoleName"],
        dataRowViewerDisplay: ["ModuleDisplayName", "ModuleDescription", "RoleName", "MenuTabDisplayName", "ParentModule", "ModuleUrl", "IsActive", "DateCreated"],
        notEditable: ["ModuleID", "ModuleName", "DateCreated", "ModuleUrl"],
        primaryKey: ["ModuleID"],
        readable: {
            removePrefix:[],
            ModuleDisplayName: "Module Name",
            ModuleDescription: "Description",
            MenuTabDisplayName: "Menu Tab",
            RoleName: "Roles"
        },
        validation: {
            ModuleDisplayName: [regexes.Unique, regexes.NotEmpty, regexes.MaxLength100],
            ModuleDescription: [regexes.MaxLength250],
            MenuTabDisplayName: [regexes.NotEmpty],
        },
        sortBy: ["ModuleDisplayName", "MenuTabDisplayName"],
        searchBy: ["ModuleDisplayName", "MenuTabDisplayName", "RoleName"],
        selectOptions: {},
        multiSelectOptions: {},
        isAddable: false,
        onUpdateFunc: context.UpdateModule
    })
    
    useEffect(() =>{
        context.GetModules().then(modules =>{
            setModules([]);
            setProperties(state => ({
                ...state,
                selectOptions:{
                    ...state.selectOptions,
                    ParentModule: {
                        ModuleDisplayName: modules
                    }
                }
            }))
            modules.forEach(module =>{
                context.GetRolesByModuleID(module.ModuleID).then(roles =>{
                    roles = roles || [];
                    setModules(state => [
                        ...state.filter(otherModule => otherModule.ModuleDisplayName <= module.ModuleDisplayName),
                        Object.assign(module, {RoleName: roles.map(role => role.RoleName).join(', ')}),
                        ...state.filter(otherModule => otherModule.ModuleDisplayName > module.ModuleDisplayName),
                    ]);
                });
            });
        });
        context.GetRoles().then(roles=>{
            setProperties(state => ({
                ...state,
                multiSelectOptions:{
                    ...state.multiSelectOptions,
                    RoleName: roles
                }
            }))
        });
        context.GetTabs().then(tabs=>{
            setProperties(state =>({
                ...state,
                selectOptions:{
                    ...state.selectOptions,
                    MenuTabDisplayName: tabs
                }
            }))
        })
    }, [context]);

    return(
        <TemplateModule data={modules} properties={properties}/>
    );
}

export default Modules;