import * as actions from "actions/actionTypes";

export const insertProjects = (projects) => {
    return {
        type: actions.PROJECTS_LOADED,
        payload: {
            projects
        }
    }
}

export const addNewProject = (project) => {
    return {
        type: actions.PROJECT_ADDED,
        payload: {
            project
        }
    }
}