import styled from 'styled-components';
import Image1 from 'media/images/homeImg1.png';
import Image2 from 'media/images/homeImg2.png';
import Image3 from 'media/images/homeImg3.png';

const Home = () =>{
    return(
        <PageContainer>
            <ImagesContainer>
                <MainImage src={Image1}/>
                <SmallImagesContainer >
                    <SmallImage src={Image1} />
                    <SmallImage src={Image2} />
                    <SmallImage src={Image3} />
                </SmallImagesContainer> 
            </ImagesContainer>
        </PageContainer>
    );
};

const PageContainer = styled.div`
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;
const ImagesContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;

const MainImage = styled.img`
    width: 800px;
    border-radius: 30px;
    box-shadow: ${props => props.theme.shadowBox.bold};
`;

const SmallImagesContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 55px;
    justify-content: space-between;
    height: 600px;
`;

const SmallImage = styled.img`
    width: 250px;
    box-shadow: ${props => props.theme.shadowBox.bold};
    border-radius: 20px;
`;

export default Home;