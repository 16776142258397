const API = process.env.REACT_APP_API_URL;
const headers = new Headers();
headers.append('Authorization', '');
headers.append('Access-Control-Allow-Origin', '*');
headers.append('Content-Type', 'application/json');
const mocking = process.env.REACT_APP_MOCK_DATA;

const Fetch = async (EndPoint, Options = {}, includeHeaders = true) => {
    if (EndPoint) {
        try {
            const gid = window.sessionStorage.getItem('gid');
            if (mocking === 'true') {
                try {
                    const path = EndPoint.split('?')[0]; // remove url params
                    const mocking = (await import(`./mockdata/${path}.json`)).default;
                    var blob = new Blob([JSON.stringify(mocking)], { type: 'application/json' });
                    return new Response(blob);
                } catch (error) {
                    console.warn(`fetch: mocking data on ${EndPoint}`, error);
                }
            }
            if (headers.get('Authorization') !== gid)
                headers.set('Authorization', gid);
            let options = { ...Options };
            if (includeHeaders)
                options = { ...options, headers };
            return fetch(`${API}/${EndPoint}`, { ...options })
                .then(res => { return res.json() })
                .then(data => {
                    return data;
                }).catch(error => {
                    console.error(error);
                    throw error;
                })
        } catch (error) {
            console.error(error);
            throw error;
        }
    }
}

export default Fetch;


