import AppContext from 'context';
import { useContext, useEffect, useState, useRef } from 'react';
import TemplateModule from '../TemplateModule';
import * as regexes from 'services/validatationRegexs';
import { useSelector } from 'react-redux';

const LabTests = () => {
    const context = useRef(useContext(AppContext)).current;
    const { systemParams } = useSelector(state => state.system);
    const [labTestTypes, setLabTestTypes] = useState([]);
    const [properties, setProperties] = useState({
        display: ["LabTestName", "LabTestCost", "CurrencySymbol", "RecommendedLabName", "LabTestDescription"],
        dataRowViewerDisplay: ["LabTestName", "LabTestCost", "CurrencySymbol", "RecommendedLabName", "LabTestDescription", "IsActive", "DateCreated"],
        notEditable:["LabTestTypeID", "DateCreated"],
        primaryKey: ["LabTestTypeID"],
        readable: {
            removePrefix:["Lab"],
            CurrencySymbol: "Currency",
            LabTestCost: "Estimated Cost",
            RecommendedLabName: "Recommended Lab"
        },
        validation: {
            LabTestName: [regexes.Unique, regexes.NotEmpty],
        },
        sortBy: ["LabTestName", "RecommendedLabName", "LabTestCost"],
        searchBy: ["LabTestName", "RecommendedLabName"],
        onInsertFunc: context.InsertNewLabTestType,
        onUpdateFunc: context.UpdateLabTestType
    })
    
    useEffect(() =>{
        context.GetLabTestTypes().then(labTestTypes =>{
            setLabTestTypes(labTestTypes.map(curTestType => {return {...curTestType, LabTestCost: curTestType.LabTestCost? curTestType.LabTestCost : ""}})); 
        });
        context.GetCurrencies().then(currencies =>{
            setProperties(state => ({
                ...state,
                selectOptions:{
                    ...state.selectOptions,
                        CurrencySymbol: currencies? currencies : []
                },
                defaultOnInsert:{
                    ...state.defaultOnInsert,
                    CurrencySymbol: currencies.find(currency => currency.CurrencyID.toString() === systemParams.DefaultCurrencyIDInDB.ParamValue)?.CurrencySymbol
                }
            }));
        });
        context.GetLabs().then(labs =>{
            setProperties(state => ({
                ...state,
                selectOptions:{
                    ...state.selectOptions,
                    RecommendedLabName:{
                        LabName: labs? labs : []
                    }
                }
            }));
        });
    }, [context, systemParams.DefaultCurrencyIDInDB]);

    return(
        <TemplateModule data={labTestTypes} properties={properties}/>
    );
}

export default LabTests;