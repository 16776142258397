import MainMenu from 'components/navigation/MainMenu';
import MenuLevel1 from 'components/navigation/MenuLevel1';

const Navigation = () => {

    return(
        <nav>
            <MainMenu />
            <MenuLevel1 />
        </nav>
    );
};

export default Navigation;