import React from 'react';
import ReactDOM from 'react-dom';
import 'index.css';
import App from 'App';
import { Provider as ReduxProvider } from 'react-redux';
import store from 'store/store';
import {Provider as ContextProvider} from 'context';

ReactDOM.render(
  <React.StrictMode>
    <ReduxProvider store={store}>
      <ContextProvider>
        <App />
      </ContextProvider>
    </ReduxProvider>
  </React.StrictMode>,
  document.getElementById('root')
);