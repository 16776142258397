import * as actions from "actions/actionTypes";

export const insertModules = (modules) => {
    return {
        type: actions.MODULES_LOADED,
        payload: {
            modules
        }
    }
}

export const setSelectedModule = (module) => {
    return {
        type: actions.MODULE_SELECTED,
        payload: {
            module
        }
    }
}

export const setSelectedModuleDataRow = (selectedDataRow) => {
    return {
        type: actions.MODULE_DATA_ROW_SELECTED,
        payload: {
            selectedDataRow
        }
    }
}

export const setSelectedModuleData = (moduleData) => {
    return {
        type: actions.MODULE_DATA_LOADED,
        payload: {
            moduleData
        }
    }
}

export const updateModuleDataRowActive = (selectedDataRow, IsActive) => {
    return {
        type: actions.MODULE_DATA_ISACTIVE_CHANGED,
        payload: {
            selectedDataRow,
            IsActive
        }
    }
}

export const setModuleProperties = (properties) => {
    return {
        type: actions.MODULE_PROPERTIES_LOADED,
        payload: {
            properties
        }
    }
}

export const updateDataRow = (updatedDataRow) => {
    return {
        type: actions.MODULE_DATAROW_UPDATED,
        payload: {
            updatedDataRow
        }
    }
}

export const clearModuleSelected = () => {
    return {
        type: actions.MODULE_CLEARED,
    }
}

export const updateModuleDetails = (updatedModule) => {
    return {
        type: actions.MODULE_UPDATED,
        payload: {
            updatedModule
        }
    }
}

export const insertPermissions = permissions => {
    return {
        type: actions.MODULES_PERMISSIONS_LOADED,
        payload: {
            permissions
        }
    }
}

