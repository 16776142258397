export const Unique = { name: "Unique", regex: /.*/, error: 'Already exist' }; //validation not depending on regex.
export const ExternalFunc = { name: "ExternalFunc", regex: /.*/, func: () => { }, error: 'custom error' }; //validation not depending on regex.
export const NotEmpty = { name: 'NotEmpty', regex: /.+/, error: 'Field is required' };
export const Number = { name: 'Number', regex: /^[+-]?\d+$/, error: 'Value is illegal' };
export const PositiveNumber = { name: 'PositiveNumber', regex: /^[+]?[1-9]\d*$/, error: 'Value is illegal' };
export const PositiveNumberOrEmpty = { name: 'PositiveNumberOrEmpty', regex: /^$|^[+]?[1-9]\d*$/, error: 'Value is illegal' };
export const MaxSize100 = { name: 'MaxSize100', regex: /^[0-9][0-9]?$|^100$/, error: 'Max size is 100MB' };
export const ZeroOrPositiveNumber = { name: 'ZeroOrPositiveNumber', regex: /^0$|^[1-9][0-9]*$/, error: 'Value is illegal' };
export const Alphabetic = { name: 'Alphabetic', regex: /^[a-zA-Zא-ת]+$/, error: 'Value is illegal' }
export const Float = { name: 'Float', regex: /[+-]?\d+(\.\d+)?$/, error: 'Value is illegal' };
export const FloatOrEmpty = { name: 'Float', regex: /^$|^[+-]?\d+(\.\d+)?$/, error: 'Value is illegal' };
export const PositiveFloat = { name: 'PositiveFloat', regex: /^$|^[+]?\d+(\.\d+)?$/, error: 'Value is illegal' };
export const MaxLength3 = { name: 'MaxLength3', regex: /^.{0,3}$/, error: 'Value is too long. max length: 3' };
export const MaxLength20 = { name: 'MaxLength20', regex: /^.{0,20}$/, error: 'Value is too long. max length: 20' };
export const MaxLength50 = { name: 'MaxLength50', regex: /^.{0,50}$/, error: 'Value is too long. max length: 50' };
export const MaxLength100 = { name: 'MaxLength100', regex: /^.{0,100}$/, error: 'Value is too long. max length: 100' };
export const MaxLength150 = { name: 'MaxLength150', regex: /^.{0,150}$/, error: 'Value is too long. max length: 150' };
export const MaxLength250 = { name: 'MaxLength250', regex: /^.{0,250}$/, error: 'Value is too long. max length: 250' };
export const Email = { name: 'Email', regex: /\S+@\S+\.\S+/, error: 'Illegal email' };