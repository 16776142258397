import { createContext, useCallback, useEffect, useRef, useState } from "react";
import Fetch from "services/proxy/ProxyServices";
import history from "services/history";
import { toCamelCaseNoSpace, FileToBase64String } from "services/helpFunctions";
import { addNewProject } from "actions/projectActions";
import { useDispatch } from "react-redux";

const AppContext = createContext({ loading: false });
export default AppContext;

export const Provider = props => {
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const TimingType = useRef([]);
  const dispatch = useDispatch();

  /**
   ******************* Auth Requests *******************
   */

  const UserLogin = async (username, password, reCaptchaToken) => {
    return await FetchWithLoader("api/Account/Login", {
      method: "POST",
      body: JSON.stringify({
        Username: username,
        Password: password,
        reCaptchaToken,
      }),
    });
  };

  const GetUser = async () => {
    return await FetchWithLoader("api/Account/GetUser", { method: "POST" });
  };

  const UserLogout = async () => {
    Fetch("api/Account/Logout", { method: "POST" });
    return true;
  };

  /**
   ******************* Permissions Requests *******************
   */

  const GetPermissions = async () => {
    return await FetchWithLoader("api/Permissions/GetPermissionsPerModule");
  };

  const UpdatePermission = async updatedPermission => {
    return await FetchWithLoader("api/Permissions/UpdatePermissionPerModule", {
      method: "POST",
      body: JSON.stringify(updatedPermission),
    });
  };

  /**
   ******************* SystemParams Requests *******************
   */

  const GetSystemParams = async () => {
    return await FetchWithLoader("api/SystemParams/GetSystemParams");
  };

  /**
   ******************* Role Requests *******************
   */

  const GetRoles = async () => {
    return await FetchWithLoader("api/Roles/GetRoles");
  };

  const GetRolesByModuleID = async moduleID => {
    return await FetchWithLoader(`api/Roles/GetRolesByModuleID?moduleID=${moduleID}`);
  };

  const UpdateRole = async updatedRole => {
    return await FetchWithLoader("api/Roles/UpdateRole", {
      method: "POST",
      body: JSON.stringify(updatedRole),
    });
  };

  const InsertNewRole = async newRole => {
    return await FetchWithLoader("api/Roles/InsertNewRole", {
      method: "POST",
      body: JSON.stringify({ ...newRole, RoleID: 0 }),
    });
  };

  /**
   ******************* Tabs Requests *******************
   */

  const GetTabs = async () => {
    return await FetchWithLoader("api/MenuTabs/GetTabs");
  };

  const GetTabsByRole = async () => {
    return await FetchWithLoader("api/MenuTabs/GetTabsByRole");
  };

  const UpdateMenuTab = async updatedMenuTab => {
    updatedMenuTab = {
      ...updatedMenuTab,
      MenuTabName: toCamelCaseNoSpace(updatedMenuTab.MenuTabDisplayName),
    };
    const promise = FetchWithLoader("api/MenuTabs/UpdateMenuTab", {
      method: "POST",
      body: JSON.stringify(updatedMenuTab),
    });
    promise.then(res => {
      if (res > 0) {
        history.go(0);
      }
    });
    return promise;
  };

  const InsertNewMenuTab = async newMenuTab => {
    newMenuTab = {
      ...newMenuTab,
      MenuTabID: 0,
      MenuTabName: toCamelCaseNoSpace(newMenuTab.MenuTabDisplayName),
    };
    const promise = FetchWithLoader("api/MenuTabs/InsertNewMenuTab", {
      method: "POST",
      body: JSON.stringify(newMenuTab),
    });
    promise.then(menuTabID => {
      if (menuTabID > 0) {
        history.go(0);
      }
    });
    return promise;
  };

  /**
   ******************* Modules Requests *******************
   */
  const GetModules = async () => {
    return await FetchWithLoader("api/Modules/GetModules");
  };

  const GetModulesByRole = async roleID => {
    return await FetchWithLoader(`api/Modules/GetModulesByRole?roleID=${roleID}`);
  };

  const GetModulesByTab = async tabID => {
    return await FetchWithLoader(`api/Modules/GetModulesByMenuTabID?tabID=${tabID}`);
  };

  const UpdateModule = async updatedModule => {
    var promise = FetchWithLoader("api/Modules/UpdateModule", {
      method: "POST",
      body: JSON.stringify(updatedModule),
    });
    promise.then(res => {
      if (res > 0) history.go(0);
    });
    return promise;
  };

  const GetEmptyEntity = async moduleName => {
    return await FetchWithLoader(`api/Modules/GetEmptyEntityObject?entityType=${moduleName}`, { method: "POST" });
  };

  /**
   ******************* Modules/ActivityTypes Requests *******************
   */

  const GetActivities = async () => {
    return await FetchWithLoader("api/Modules/ActivityTypes/GetActivityTypes");
  };

  const GetActivityTypeByID = async activityTypeID => {
    return await FetchWithLoader(`api/Modules/ActivityTypes/GetActivityTypeByID?activityTypeID=${activityTypeID}`);
  };

  const InsertNewActivityType = async newActivityType => {
    return await FetchWithLoader("api/Modules/ActivityTypes/InsertNewActivityType", {
      method: "POST",
      body: JSON.stringify({
        ActivityTypeID: 0,
        ...newActivityType,
      }),
    });
  };

  const UpdateActivityType = async updatedActivityType => {
    return await FetchWithLoader("api/Modules/ActivityTypes/UpdateActivityType", {
      method: "POST",
      body: JSON.stringify(updatedActivityType),
    });
  };

  /**
   ******************* Modules/UnitTypes Requests *******************
   */

  const GetUnitTypes = async () => {
    return await FetchWithLoader("api/Modules/UnitTypes/GetUnitTypes");
  };

  const InsertNewUnitType = async newUnitType => {
    return await FetchWithLoader("api/Modules/UnitTypes/InsertNewUnitType", {
      method: "POST",
      body: JSON.stringify({
        UnitTypeID: 0,
        ...newUnitType,
      }),
    });
  };

  const UpdateUnitType = async updatedUnitType => {
    return await FetchWithLoader("api/Modules/UnitTypes/UpdateUnitType", {
      method: "POST",
      body: JSON.stringify(updatedUnitType),
    });
  };

  /**
   ******************* Modules/ItemTypes Requests *******************
   */

  const GetItemTypes = async () => {
    return await FetchWithLoader("api/Modules/ItemTypes/GetItemTypes");
  };

  const InsertNewItemType = async newItemType => {
    return await FetchWithLoader("api/Modules/ItemTypes/InsertNewItemType", {
      method: "POST",
      body: JSON.stringify({
        ItemTypeID: 0,
        ...newItemType,
      }),
    });
  };

  const UpdateItemType = async updatedItemType => {
    return await FetchWithLoader("api/Modules/ItemTypes/UpdateItemType", {
      method: "POST",
      body: JSON.stringify(updatedItemType),
    });
  };

  /**
   ******************* Modules/TechnologyTypes Requests *******************
   */

  const GetTechnologyTypes = async () => {
    return await FetchWithLoader("api/Modules/TechnologyTypes/GetTechnologyTypes");
  };

  const InsertNewTechnologyType = async newTechnologyType => {
    return await FetchWithLoader("api/Modules/TechnologyTypes/InsertNewTechnologyType", {
      method: "POST",
      body: JSON.stringify({
        TechnologyTypeID: 0,
        ...newTechnologyType,
      }),
    });
  };

  const UpdateTechnologyType = async updatedTechnologyType => {
    return await FetchWithLoader("api/Modules/TechnologyTypes/UpdateTechnologyType", {
      method: "POST",
      body: JSON.stringify(updatedTechnologyType),
    });
  };

  /**
   ******************* Modules/Lab Requests *******************
   */

  const GetLabs = async () => {
    return await FetchWithLoader("api/Modules/Labs/GetLabs");
  };

  const InsertNewLab = async newLab => {
    return await FetchWithLoader("api/Modules/Labs/InsertNewLab", {
      method: "POST",
      body: JSON.stringify({
        LabID: 0,
        ...newLab,
      }),
    });
  };

  const UpdateLab = async updatedLab => {
    return await FetchWithLoader("api/Modules/Labs/UpdateLab", {
      method: "POST",
      body: JSON.stringify(updatedLab),
    });
  };

  /**
   ******************* Modules/LabTestType Requests *******************
   */

  const GetLabTestTypes = async () => {
    return await FetchWithLoader("api/Modules/LabTestTypes/GetLabTestTypes");
  };

  const InsertNewLabTestType = async newLabTestType => {
    return await FetchWithLoader("api/Modules/LabTestTypes/InsertNewLabTestType", {
      method: "POST",
      body: JSON.stringify({
        LabTestTypeID: 0,
        ...newLabTestType,
      }),
    });
  };

  const UpdateLabTestType = async updatedLabTestType => {
    return await FetchWithLoader("api/Modules/LabTestTypes/UpdateLabTestType", {
      method: "POST",
      body: JSON.stringify(updatedLabTestType),
    });
  };

  /**
   ******************* Modules/User Requests *******************
   */

  const GetUsers = async () => {
    return await FetchWithLoader("api/Modules/Users/GetUsers");
  };

  const InsertNewUser = async newUser => {
    return await FetchWithLoader("api/Modules/Users/InsertNewUser", {
      method: "POST",
      body: JSON.stringify({
        UserID: 0,
        ...newUser,
      }),
    });
  };

  const UpdateUser = async updatedUser => {
    return await FetchWithLoader("api/Modules/Users/UpdateUser", {
      method: "POST",
      body: JSON.stringify(updatedUser),
    });
  };

  /**
   ******************* Modules/Currencies Requests *******************
   */

  const GetCurrencies = async () => {
    return await FetchWithLoader("api/Modules/Currencies/GetCurrencies");
  };

  const InsertNewCurrency = async newCurrency => {
    return await FetchWithLoader("api/Modules/Currencies/InsertNewCurrency", {
      method: "POST",
      body: JSON.stringify({
        CurrencyID: 0,
        ...newCurrency,
      }),
    });
  };

  const UpdateCurrency = async updatedCurrency => {
    return await FetchWithLoader("api/Modules/Currencies/UpdateCurrency", {
      method: "POST",
      body: JSON.stringify(updatedCurrency),
    });
  };

  /**
   ******************* Modules/Customers Requests *******************
   */

  const GetCustomers = async () => {
    return await FetchWithLoader("api/Modules/Customers/GetCustomers");
  };

  const InsertNewCustomer = async newCustomer => {
    return await FetchWithLoader("api/Modules/Customers/InsertNewCustomer", {
      method: "POST",
      body: JSON.stringify({
        CustomerID: 0,
        ...newCustomer,
      }),
    });
  };

  const UpdateCustomer = async updatedCustomer => {
    return await FetchWithLoader("api/Modules/Customers/UpdateCustomer", {
      method: "POST",
      body: JSON.stringify(updatedCustomer),
    });
  };

  /**
   ******************* Modules/ProjectDataFieldTypes Requests *******************
   */

  const GetProjectDataFieldTypes = async () => {
    return await FetchWithLoader("api/Modules/ProjectDataFieldTypes/GetProjectDataFieldTypes");
  };

  const InsertNewProjectDataFieldType = async newProjectDataFieldType => {
    return await FetchWithLoader("api/Modules/ProjectDataFieldTypes/InsertNewProjectDataFieldType", {
      method: "POST",
      body: JSON.stringify({
        ProjectDataFieldTypeID: 0,
        ...newProjectDataFieldType,
      }),
    });
  };

  const UpdateProjectDataFieldType = async updatedProjectDataFieldType => {
    return await FetchWithLoader("api/Modules/ProjectDataFieldTypes/UpdateProjectDataFieldType", {
      method: "POST",
      body: JSON.stringify(updatedProjectDataFieldType),
    });
  };

  /**
   ******************* Modules/PaymentHierarchyTypes Requests *******************
   */

  const GetPaymentHierarchyTypes = async () => {
    return await FetchWithLoader("api/Modules/PaymentHierarchyTypes/GetPaymentHierarchyTypes");
  };

  const InsertNewPaymentHierarchyType = async newPaymentHierarchyType => {
    return await FetchWithLoader("api/Modules/PaymentHierarchyTypes/InsertNewPaymentHierarchyType", {
      method: "POST",
      body: JSON.stringify({
        PaymentHierarchyTypeID: 0,
        ...newPaymentHierarchyType,
      }),
    });
  };

  const UpdatePaymentHierarchyType = async updatedPaymentHierarchyType => {
    return await FetchWithLoader("api/Modules/PaymentHierarchyTypes/UpdatePaymentHierarchyType", {
      method: "POST",
      body: JSON.stringify(updatedPaymentHierarchyType),
    });
  };
  const getPaymentHierarchyTypeTimingTypes = async () => {
    if (TimingType.current.length !== 0) return TimingType.current;

    const results = await FetchWithLoader("api/Modules/PaymentHierarchyTypes/GetPaymentHierarchyTypeTimingTypes");
    TimingType.current = results;
    
    return results;
  };

  const GetTimingTypes = useCallback(() =>  TimingType.current,[TimingType.current]);

  /**
   ******************* Modules/ProjectDocumentTypes Requests *******************
   */

  const GetDocumentTypes = async () => {
    return await FetchWithLoader("api/Modules/ProjectDocumentTypes/GetDocumentTypes");
  };

  const GetDocumentMaxFileSize = async documentType => {
    return await FetchWithLoader(`api/Modules/ProjectDocumentTypes/GetDocumentMaxFileSize?documentType=${documentType}`);
  };

  const InsertNewDocumentType = async newDocumentType => {
    return await FetchWithLoader("api/Modules/ProjectDocumentTypes/InsertNewDocumentType", {
      method: "POST",
      body: JSON.stringify({
        DocumentTypeID: 0,
        ...newDocumentType,
      }),
    });
  };

  const UpdateDocumentType = async updatedDocumentType => {
    return await FetchWithLoader("api/Modules/ProjectDocumentTypes/UpdateDocumentType", {
      method: "POST",
      body: JSON.stringify(updatedDocumentType),
    });
  };

  /**
   ******************* Modules/DocumentFormatTypes Requests *******************
   */

  const GetFormatTypes = async () => {
    return await FetchWithLoader("api/Modules/DocumentSupportedFormatTypes/GetDocumentFormatTypes");
  };

  const GetAllowedFormats = async documentType => {
    return await FetchWithLoader(
      `api/Modules/DocumentSupportedFormatTypes/GetAllowedFormatsByDocumentType?documentType=${documentType}`
    );
  };

  const InsertNewFormatType = async newFormatType => {
    return await FetchWithLoader("api/Modules/DocumentSupportedFormatTypes/InsertNewDocumentFormatType", {
      method: "POST",
      body: JSON.stringify({
        FormatTypeID: 0,
        ...newFormatType,
      }),
    });
  };

  const UpdateFormatType = async updatedFormatType => {
    return await FetchWithLoader("api/Modules/DocumentSupportedFormatTypes/UpdateDocumentFormatType", {
      method: "POST",
      body: JSON.stringify(updatedFormatType),
    });
  };

  /**
   ******************* Modules/PaymentIndexTypes Requests *******************
   */

  const GetPaymentIndexTypes = async () => {
    return await FetchWithLoader("api/Modules/PaymentIndexTypes/GetPaymentIndexTypes");
  };

  const InsertNewPaymentIndexType = async newPaymentIndexType => {
    return await FetchWithLoader("api/Modules/PaymentIndexTypes/InsertNewPaymentIndexType", {
      method: "POST",
      body: JSON.stringify({
        PaymentIndexTypeID: 0,
        ...newPaymentIndexType,
      }),
    });
  };

  const UpdatePaymentIndexType = async updatedPaymentIndexType => {
    return await FetchWithLoader("api/Modules/PaymentIndexTypes/UpdatePaymentIndexType", {
      method: "POST",
      body: JSON.stringify(updatedPaymentIndexType),
    });
  };

  /**
   ******************* Modules/PaymentIndexTypeValues Requests *******************
   */

  const GetPaymentIndexTypeValues = async () => {
    return await FetchWithLoader("api/Modules/PaymentIndexTypeValues/GetPaymentIndexTypeValues");
  };

  const InsertNewPaymentIndexTypeValue = async newPaymentIndexTypeValue => {
    return await FetchWithLoader("api/Modules/PaymentIndexTypeValues/InsertNewPaymentIndexTypeValue", {
      method: "POST",
      body: JSON.stringify({
        RecordID: 0,
        ...newPaymentIndexTypeValue,
      }),
    });
  };

  const UpdatePaymentIndexTypeValue = async updatedPaymentIndexTypeValue => {
    return await FetchWithLoader("api/Modules/PaymentIndexTypeValues/UpdatePaymentIndexTypeValue", {
      method: "POST",
      body: JSON.stringify(updatedPaymentIndexTypeValue),
    });
  };

  /**
   ******************* Modules/Projects Requests *******************
   */

  const GetProjects = async () => {
    return await FetchWithLoader("api/Modules/Projects/GetProjects");
  };

  const GetProjectByID = async projectID => {
    return await FetchWithLoader(`api/Modules/Projects/GetProjectByID?projectID=${projectID}`);
  };

  const InsertNewProject = async newProject => {
    const promise = FetchWithLoader("api/Modules/Projects/InsertNewProject", {
      method: "POST",
      body: JSON.stringify({
        ProjectID: 0,
        ...newProject,
      }),
    });
    promise.then(res => {
      if (res > 0) {
        dispatch(addNewProject({ ...newProject, ProjectID: res }));
      }
    });
    return promise;
  };

  const UpdateProject = async updatedProject => {
    return await FetchWithLoader("api/Modules/Projects/UpdateProject", {
      method: "POST",
      body: JSON.stringify(updatedProject),
    });
  };

  /**
   ******************* Modules/Projects/ProjectIncomes Requests *******************
   */

  const GetProjectIncomes = async projectID => {
    return await FetchWithLoader(`api/Modules/Projects/ProjectIncomes/GetProjectIncomes?projectID=${projectID}`);
  };

  const GetProjectPlannedIncomes = async projectID => {
    return await FetchWithLoader(`api/Modules/Projects/ProjectIncomes/GetProjectPlannedIncomes?projectID=${projectID}`);
  };

  const InsertNewProjectIncome = async newProjectIncome => {
    return await FetchWithLoader("api/Modules/Projects/ProjectIncomes/InsertNewProjectIncome", {
      method: "POST",
      body: JSON.stringify(newProjectIncome),
    });
  };

  const UpdateProjectIncome = async updatedProject => {
    return await FetchWithLoader("api/Modules/Projects/ProjectIncomes/UpdateProjectIncome", {
      method: "POST",
      body: JSON.stringify(updatedProject),
    });
  };

  /**
   ******************* Modules/IncomeGenerations Requests *******************
   */

  const GetIncomeGenerations = async () => {
    return await FetchWithLoader(`api/IncomeGenerations/GetIncomeGenerations`);
  };

  const InsertNewIncomeGeneration = async newIncomeGeneration => {
    return await FetchWithLoader("api/IncomeGenerations/InsertNewIncomeGeneration", {
      method: "POST",
      body: JSON.stringify(newIncomeGeneration),
    });
  };

  const UpdateIncomeGeneration = async updatedIncomeGeneration => {
    return await FetchWithLoader("api/IncomeGenerations/UpdateIncomeGeneration", {
      method: "POST",
      body: JSON.stringify(updatedIncomeGeneration),
    });
  };

  /**
   ******************* Modules/Projects/ProjectItems Requests *******************
   */

  const GetProjectItems = async projectID => {
    return await FetchWithLoader(`api/Modules/Projects/ProjectItems/GetProjectItems?projectID=${projectID}`);
  };

  const InsertNewProjectItem = async newProjectItem => {
    return await FetchWithLoader("api/Modules/Projects/ProjectItems/InsertNewProjectItem", {
      method: "POST",
      body: JSON.stringify(newProjectItem),
    });
  };

  const UpdateProjectItem = async updatedProject => {
    return await FetchWithLoader("api/Modules/Projects/ProjectItems/UpdateProjectItem", {
      method: "POST",
      body: JSON.stringify(updatedProject),
    });
  };

  /**
   ******************* Modules/Projects/ProjectLabTests Requests *******************
   */

  const GetProjectLabTests = async projectID => {
    return await FetchWithLoader(`api/Modules/Projects/ProjectLabTests/GetProjectLabTests?projectID=${projectID}`);
  };

  const GetProjectLabTestByID = async projectLabTestID => {
    return await FetchWithLoader(`api/Modules/Projects/ProjectLabTests/GetProjectLabTestByID?projectLabTestID=${projectLabTestID}`);
  };

  const InsertNewProjectLabTest = async newProjectLabTest => {
    return await FetchWithLoader("api/Modules/Projects/ProjectLabTests/InsertNewProjectLabTest", {
      method: "POST",
      body: JSON.stringify(newProjectLabTest),
    });
  };

  const UpdateProjectLabTest = async updatedProject => {
    return await FetchWithLoader("api/Modules/Projects/ProjectLabTests/UpdateProjectLabTest", {
      method: "POST",
      body: JSON.stringify(updatedProject),
    });
  };

  /**
   ******************* Modules/Projects/ProjectActivities/ActivityLabTests Requests *******************
   */

  const GetActivityLabTests = async projectActivityID => {
    return await FetchWithLoader(
      `api/Modules/Projects/ProjectActivities/ActivityLabTests/GetActivityLabTests?projectActivityID=${projectActivityID}`
    );
  };

  const GetActivityLabTestByLabTestID = async labTestID => {
    return await FetchWithLoader(
      `api/Modules/Projects/ProjectActivities/ActivityLabTests/GetActivityLabTestByLabTestID?labTestID=${labTestID}`
    );
  };

  const InsertNewActivityLabTest = async newActivityLabTest => {
    return await FetchWithLoader("api/Modules/Projects/ProjectActivities/ActivityLabTests/InsertNewActivityLabTest", {
      method: "POST",
      body: JSON.stringify(newActivityLabTest),
    });
  };

  const UpdateActivityLabTest = async updatedActivityLabTest => {
    return await FetchWithLoader("api/Modules/Projects/ProjectActivities/ActivityLabTests/UpdateActivityLabTest", {
      method: "POST",
      body: JSON.stringify(updatedActivityLabTest),
    });
  };

  /**
   ******************* Modules/Projects/ProjectActivities/ActivityLabTestResults Requests *******************
   */

  const GetActivityLabTestResults = async activityLabTestID => {
    return await FetchWithLoader(
      `api/Modules/Projects/ProjectActivities/ActivityLabTests/ActivityLabTestResults/GetActivityLabTestResults?activityLabTestID=${activityLabTestID}`
    );
  };

  const InsertNewActivityLabTestResult = async newActivityLabTestResult => {
    return await FetchWithLoader(
      "api/Modules/Projects/ProjectActivities/ActivityLabTests/ActivityLabTestResults/InsertNewActivityLabTestResult",
      {
        method: "POST",
        body: JSON.stringify(newActivityLabTestResult),
      }
    );
  };

  const UpdateActivityLabTestResult = async updatedActivityLabTestResult => {
    return await FetchWithLoader(
      "api/Modules/Projects/ProjectActivities/ActivityLabTests/ActivityLabTestResults/UpdateActivityLabTestResult",
      {
        method: "POST",
        body: JSON.stringify(updatedActivityLabTestResult),
      }
    );
  };

  /**
   ******************* Modules/Projects/ProjectActivities/ProjectActivities Requests *******************
   */

  const GetProjectActivities = async projectID => {
    return await FetchWithLoader(`api/Modules/Projects/ProjectActivities/GetProjectActivities?projectID=${projectID}`);
  };

  const GetProjectPlannedActivities = async projectID => {
    return await FetchWithLoader(`api/Modules/Projects/ProjectActivities/GetProjectPlannedActivities?projectID=${projectID}`);
  };

  const GetProjectActivityByID = async projectActivityID => {
    return await FetchWithLoader(
      `api/Modules/Projects/ProjectActivities/GetProjectActivityByID?projectActivityID=${projectActivityID}`
    );
  };

  const InsertNewProjectActivity = async newProjectActivity => {
    return await FetchWithLoader("api/Modules/Projects/ProjectActivities/InsertNewProjectActivity", {
      method: "POST",
      body: JSON.stringify({ ...newProjectActivity, ProjectActivityID: 0 }),
    });
  };

  const UpdateProjectActivity = async updatedProject => {
    return await FetchWithLoader("api/Modules/Projects/ProjectActivities/UpdateProjectActivity", {
      method: "POST",
      body: JSON.stringify(updatedProject),
    });
  };

  const GetProjectActivitiesLevels = async projectID => {
    return await FetchWithLoader(`api/Modules/Projects/ProjectActivities/GetProjectActivitiesLevels?projectID=${projectID}`);
  };

  /**
   ******************* Modules/Projects/ProjectActivities/ProjectItemsPerActivity Requests *******************
   */

  const GetProjectItemsPerActivity = async projectActivityID => {
    return await FetchWithLoader(
      `api/Modules/Projects/ProjectItemsPerActivity/GetProjectItemsPerActivity?projectActivityID=${projectActivityID}`
    );
  };

  const InsertNewProjectItemPerActivity = async newItem => {
    return await FetchWithLoader("api/Modules/Projects/ProjectItemsPerActivity/InsertNewProjectItemPerActivity", {
      method: "POST",
      body: JSON.stringify({ ...newItem, RecordID: 0 }),
    });
  };

  const UpdateProjectItemPerActivity = async updatedItem => {
    return await FetchWithLoader("api/Modules/Projects/ProjectItemsPerActivity/UpdateProjectItemPerActivity", {
      method: "POST",
      body: JSON.stringify(updatedItem),
    });
  };

  /**
   ******************* Modules/Projects/ProjectDocuments Requests *******************
   */

  const GetProjectDocuments = async projectID => {
    return await FetchWithLoader(`api/Modules/Projects/ProjectDocuments/GetProjectDocuments?projectID=${projectID}`);
  };

  const GetDocumentByDocumentID = async projectDocumentID => {
    let doc = await FetchWithLoader(
      `api/Modules/Projects/ProjectDocuments/GetDocumentByDocumentID?projectDocumentID=${projectDocumentID}`
    );
    if (!doc) setShowError(true);
    return doc;
  };

  const InsertNewProjectDocument = async newDocument => {
    newDocument = {
      ...newDocument,
      FormatTypeMIME: newDocument.ProjectDocumentFile.type,
      ProjectDocumentFile: await FileToBase64String(newDocument.ProjectDocumentFile),
    };
    return await FetchWithLoader("api/Modules/Projects/ProjectDocuments/InsertNewProjectDocument", {
      method: "POST",
      body: JSON.stringify({ ...newDocument, ProjectDocumentID: 0 }),
    });
  };

  const UpdateProjectDocument = async updatedDocument => {
    updatedDocument = {
      ...updatedDocument,
      FormatTypeMIME: updatedDocument.ProjectDocumentFile.type,
      ProjectDocumentFile: updatedDocument.ProjectDocumentFile ? await FileToBase64String(updatedDocument.ProjectDocumentFile) : "",
    };
    return await FetchWithLoader("api/Modules/Projects/ProjectDocuments/UpdateProjectDocument", {
      method: "POST",
      body: JSON.stringify(updatedDocument),
    });
  };

  /**
   ******************* Modules/Projects/ProjectDataFieldValues Requests *******************
   */

  const GetProjectDataFieldValues = async projectID => {
    return await FetchWithLoader(`api/Modules/Projects/ProjectDataFieldValues/GetProjectDataFieldValues?projectID=${projectID}`);
  };

  const InsertNewProjectDataFieldValue = async newProjectDataFieldValue => {
    return await FetchWithLoader("api/Modules/Projects/ProjectDataFieldValues/InsertNewProjectDataFieldValue", {
      method: "POST",
      body: JSON.stringify({ ...newProjectDataFieldValue, RecordID: 0 }),
    });
  };

  const UpdateProjectDataFieldValue = async updatedProjectDataFieldValue => {
    return await FetchWithLoader("api/Modules/Projects/ProjectDataFieldValues/UpdateProjectDataFieldValue", {
      method: "POST",
      body: JSON.stringify(updatedProjectDataFieldValue),
    });
  };

  /**
   ******************* Modules/Projects/ProjectTechnologies Requests *******************
   */

  const GetProjectTechnologies = async projectID => {
    return await FetchWithLoader(`api/Modules/Projects/ProjectTechnologies/GetProjectTechnologies?projectID=${projectID}`);
  };

  const InsertNewProjectTechnology = async newProjectTechnology => {
    return await FetchWithLoader("api/Modules/Projects/ProjectTechnologies/InsertNewProjectTechnology", {
      method: "POST",
      body: JSON.stringify(newProjectTechnology),
    });
  };

  const UpdateProjectTechnology = async updatedProject => {
    return await FetchWithLoader("api/Modules/Projects/ProjectTechnologies/UpdateProjectTechnology", {
      method: "POST",
      body: JSON.stringify(updatedProject),
    });
  };

  /**
   ******************* Modules/Projects/Contracts Requests *******************
   */

  const GetContracts = async projectID => {
    return await FetchWithLoader(`api/Modules/Projects/Contracts/GetContracts?projectID=${projectID}`);
  };

  const GetContractByID = async contrctID => {
    return await FetchWithLoader(`api/Modules/Projects/Contracts/GetContractByID?contractID=${contrctID}`);
  };

  const InsertNewContract = async newContract => {
    return await FetchWithLoader("api/Modules/Projects/Contracts/InsertNewContract", {
      method: "POST",
      body: JSON.stringify({ ...newContract, ContractID: 0 }),
    });
  };

  const UpdateContract = async updatedContract => {
    return await FetchWithLoader("api/Modules/Projects/Contracts/UpdateContract", {
      method: "POST",
      body: JSON.stringify(updatedContract),
    });
  };

  /**
   ******************* Modules/Projects/Contracts/ContractPaymentHierarchyTypes Requests *******************
   */

  const GetContractPaymentHierarchyTypes = async contractID => {
    return await FetchWithLoader(
      `api/Modules/Projects/Contracts/ContractPaymentHierarchyTypes/GetContractPaymentHierarchyTypes?contractID=${contractID}`
    );
  };

  const InsertNewContractPaymentHierarchyType = async newContract => {
    return await FetchWithLoader(
      "api/Modules/Projects/Contracts/ContractPaymentHierarchyTypes/InsertNewContractPaymentHierarchyType",
      {
        method: "POST",
        body: JSON.stringify({ ...newContract, RecordID: 0 }),
      }
    );
  };

  const UpdateContractPaymentHierarchyType = async updatedContract => {
    return await FetchWithLoader("api/Modules/Projects/Contracts/ContractPaymentHierarchyTypes/UpdateContractPaymentHierarchyType", {
      method: "POST",
      body: JSON.stringify(updatedContract),
    });
  };

  /**
   ******************* Modules/Projects/Contracts/ContractPaymentIndexTypes Requests *******************
   */

  const GetContractPaymentIndexTypes = async contractID => {
    return await FetchWithLoader(
      `api/Modules/Projects/Contracts/ContractPaymentIndexTypes/GetContractPaymentIndexTypes?contractID=${contractID}`
    );
  };

  const GetPaymentIndexTypesWeight = async contractID => {
    return await FetchWithLoader(
      `api/Modules/Projects/Contracts/ContractPaymentIndexTypes/GetPaymentIndexTypesWeight?contractID=${contractID}`
    );
  };

  const InsertNewContractPaymentIndexType = async newContract => {
    return await FetchWithLoader("api/Modules/Projects/Contracts/ContractPaymentIndexTypes/InsertNewContractPaymentIndexType", {
      method: "POST",
      body: JSON.stringify({ ...newContract, RecordID: 0 }),
    });
  };

  const UpdateContractPaymentIndexType = async updatedContract => {
    return await FetchWithLoader("api/Modules/Projects/Contracts/ContractPaymentIndexTypes/UpdateContractPaymentIndexType", {
      method: "POST",
      body: JSON.stringify(updatedContract),
    });
  };

  /*************************************************************/
  /*************************************************************/
  /*************************************************************/

  const FetchWithLoader = async (EndPoint, Options = {}, includeHeaders = true) => {
    let timeOutID = setTimeout(() => setLoading(true), 200);
    try {
      return await Fetch(EndPoint, Options, includeHeaders);
    } catch (error) {
      console.error(error);
      history.push("/404");
      setShowError(true);
      setErrorMessage(error.message);
    } finally {
      clearTimeout(timeOutID);
      setLoading(false);
    }
  };

  /*************************************************************/

  const state = {
    errorMessage,
    GetEmptyEntity,
    GetSystemParams,
    loading,
    setLoading,
    showError,
    setShowError,
    UserLogin,
    UserLogout,
    GetUser,
    GetPermissions,
    UpdatePermission,
    GetLabs,
    InsertNewLab,
    UpdateLab,
    GetUsers,
    InsertNewUser,
    UpdateUser,
    GetItemTypes,
    InsertNewItemType,
    UpdateItemType,
    GetCustomers,
    InsertNewCustomer,
    UpdateCustomer,
    GetUnitTypes,
    InsertNewUnitType,
    UpdateUnitType,
    GetCurrencies,
    InsertNewCurrency,
    UpdateCurrency,
    GetRoles,
    GetRolesByModuleID,
    UpdateRole,
    InsertNewRole,
    GetTabs,
    GetTabsByRole,
    UpdateMenuTab,
    InsertNewMenuTab,
    GetLabTestTypes,
    InsertNewLabTestType,
    UpdateLabTestType,
    GetProjectItems,
    InsertNewProjectItem,
    UpdateProjectItem,
    GetModules,
    GetModulesByRole,
    GetModulesByTab,
    UpdateModule,
    GetProjects,
    GetProjectByID,
    InsertNewProject,
    UpdateProject,
    GetContracts,
    GetContractByID,
    InsertNewContract,
    UpdateContract,
    GetTechnologyTypes,
    InsertNewTechnologyType,
    UpdateTechnologyType,
    GetIncomeGenerations,
    InsertNewIncomeGeneration,
    UpdateIncomeGeneration,
    GetPaymentIndexTypes,
    InsertNewPaymentIndexType,
    UpdatePaymentIndexType,
    GetFormatTypes,
    GetAllowedFormats,
    InsertNewFormatType,
    UpdateFormatType,
    GetProjectTechnologies,
    InsertNewProjectTechnology,
    UpdateProjectTechnology,
    GetActivities,
    GetActivityTypeByID,
    InsertNewActivityType,
    UpdateActivityType,
    GetPaymentHierarchyTypes,
    getPaymentHierarchyTypeTimingTypes,
    InsertNewPaymentHierarchyType,
    UpdatePaymentHierarchyType,
    GetProjectDataFieldTypes,
    InsertNewProjectDataFieldType,
    UpdateProjectDataFieldType,
    GetDocumentTypes,
    GetDocumentMaxFileSize,
    InsertNewDocumentType,
    UpdateDocumentType,
    GetActivityLabTestResults,
    InsertNewActivityLabTestResult,
    UpdateActivityLabTestResult,
    GetProjectDataFieldValues,
    InsertNewProjectDataFieldValue,
    UpdateProjectDataFieldValue,
    GetPaymentIndexTypeValues,
    InsertNewPaymentIndexTypeValue,
    UpdatePaymentIndexTypeValue,
    GetProjectIncomes,
    GetProjectPlannedIncomes,
    InsertNewProjectIncome,
    UpdateProjectIncome,
    GetProjectItemsPerActivity,
    InsertNewProjectItemPerActivity,
    UpdateProjectItemPerActivity,
    GetProjectDocuments,
    GetDocumentByDocumentID,
    InsertNewProjectDocument,
    UpdateProjectDocument,
    GetActivityLabTests,
    GetActivityLabTestByLabTestID,
    InsertNewActivityLabTest,
    UpdateActivityLabTest,
    GetContractPaymentHierarchyTypes,
    InsertNewContractPaymentHierarchyType,
    UpdateContractPaymentHierarchyType,
    GetProjectLabTests,
    GetProjectPlannedActivities,
    GetProjectLabTestByID,
    InsertNewProjectLabTest,
    UpdateProjectLabTest,
    GetProjectActivities,
    GetProjectActivityByID,
    GetProjectActivitiesLevels,
    InsertNewProjectActivity,
    UpdateProjectActivity,
    GetContractPaymentIndexTypes,
    GetPaymentIndexTypesWeight,
    InsertNewContractPaymentIndexType,
    UpdateContractPaymentIndexType,
    GetTimingTypes,
  };

  return <AppContext.Provider value={state}>{props.children}</AppContext.Provider>;
};
