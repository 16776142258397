import { useSelector } from 'react-redux';
import styled from 'styled-components';
import Logout from 'components/site/Logout';
import Logo from 'media/images/CropedOdisLogo.png';

const Header = () =>{
    const user = useSelector(state => state.auths.user);
    return(
        <HeaderContainer>
            <OdisLogo src={Logo}/>
            <HeaderWidgetDiv>
                {user.Fname} {user.Lname} | {user.RoleName}
                <Logout />
            </HeaderWidgetDiv>
        </HeaderContainer>
    );
}

const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    position: absolute;
    width: 100%;
    padding: 19px 12px 19px 120px;
    box-sizing: border-box;
    align-items: center;
    font-family: 'MuseoSansRegular', sans-serif;
    font-size: 20px;
    color: ${props => props.theme.colors.purple};
    cursor: context-menu;
`;

const HeaderWidgetDiv = styled.div`
    display: flex;
    box-sizing: initial;
    justify-content: space-between;
    padding: 15px 30px;
    border-radius: 40px;
    width: 350px;
    height: 30px;
    font-size: 16px;
    align-items: center;
    box-shadow: ${props => props.theme.shadowBox.light};
`;

const OdisLogo = styled.img`
    width: 285px;
`;

export const HeaderIcon = styled.img`
    width: 30px;
    margin-left: 15px;
    cursor: pointer;
`;

export default Header;