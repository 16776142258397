import * as actions from "actions/actionTypes";

export const setSelectedTab = (MenuTabID, MenuTabTop = null) => {
    return {
        type: actions.TAB_SELECTED,
        payload: {
            MenuTabID,
            MenuTabTop
        }
    }
}

export const setTabs = (tabs) => {
    return {
        type: actions.TABS_LOADED,
        payload: {
            tabs
        }
    }
}