import styled from 'styled-components';
import { useTransition, animated } from 'react-spring';
import PropTypes from 'prop-types';

const Mask = ({ opacity, activate, click, zindex = 50 }) => {
    const maskTransitions = useTransition(activate, null, {
        from: { opacity: 0 },
        enter: { opacity: opacity },
        leave: { opacity: 0 },
    });

    const handleClick = () => {
        click.call();
    };

    return (
        maskTransitions.map(({ item, key, props }) =>
            item &&
            <MaskContainer zindex={zindex} as={animated.div} key={key} style={props} onClick={() => handleClick()} />
        )
    );
}

Mask.propTypes = {
    opacity: PropTypes.number,
    activate: PropTypes.bool,
    click: PropTypes.func,
}

Mask.defaultProps = {
    opacity: 0.8,
    activate: true,
    click: (() => { }),
}

const MaskContainer = styled.div`
    background:black;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: ${props => props.zindex};
`;

export default Mask;