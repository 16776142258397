import moment from 'moment';

export const isObjectEmpty = obj => {
  for (var prop in obj) {
    if (obj.hasOwnProperty(prop))
      return false;
  }
  return true;
}

export const trim = value => {
  return value.toString().trim();
}

export const strTrimLower = value => {
  return trim(value).toLowerCase();
}

export const isObjectInList = (lst, obj) => {
  const filtered = lst.filter(item => {
    return !Object.keys(item).map(key => {
      return JSON.stringify(item[key]) === JSON.stringify(obj[key])
    }).includes(false);
  })
  return filtered.length > 0
}

export const isObjectsEquals = (obj1, obj2) => {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
}

export const getCurrentDate = (separator = '/') => {
  const newDate = new Date()
  const date = newDate.getDate();
  const month = newDate.getMonth() + 1;
  const year = newDate.getFullYear();
  return `${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${date}`
}

export const toCamelCaseNoSpace = val => {
  return val.replaceAll(/(\s)(\w)/g, (_v1, _v2, v3) => { return v3.toUpperCase() }).replace(/^\w/, v1 => v1.toUpperCase())
}

export const fixColName = (colName, readable) => {
  if (readable[colName]) {
    return readable[colName]
  }
  let newName = readable.removePrefix.reduce((acc, rem) => acc.replace(rem, ''), colName);
  newName = newName.charAt(0).toUpperCase() + newName.slice(1).replaceAll(/([A-Z]+)/g, ' $1');
  return newName;
}

export const getFormatedDate = (val, format) => {
  if (format) return moment(val).format(format);
  return moment(val).format('DD/MM/YYYY');
}

export const getEmptyEntity = async (moduleName, dataRowExample, defaultOnInsert, datePickerSingle, getEntityCallback) => {
  let emptyEntity = {};
  if (!dataRowExample) {
    dataRowExample = await getEntityCallback(moduleName);
  }
  const defaultKeys = Object.keys(defaultOnInsert);
  Object.keys(dataRowExample).forEach(key => {
    if (typeof (dataRowExample[key]) === 'boolean' && !defaultKeys.includes(key)) {
      emptyEntity[key] = true;
    }
    else if (datePickerSingle.includes(key) || /datecreated/ig.test(key)) {
      emptyEntity[key] = moment()._d;
    }
    else {
      emptyEntity[key] = defaultKeys.includes(key) ? defaultOnInsert[key] : "";
    }
  })
  return emptyEntity;
}

export const debounce = (fn, delay) => {
  let timeoutID;
  return function (...args) {
    if (timeoutID) {
      clearTimeout(timeoutID)
    }
    timeoutID = setTimeout(() => {
      fn(...args)
    }, delay);
  }
}



export const isInt = (n) => {
  return Number(n) === n && n % 1 === 0;
};

export const isFloat = (n) => {
  return Number(n) === n && n % 1 !== 0;
}

/*********************** File Helper ***********************/

export const saveByteArray = (fileName, base64, mimeType) => {
  if (!base64)
    return;
  let binaryString = window.atob(base64);
  let binaryLen = binaryString.length;
  let bytes = new Uint8Array(binaryLen);
  for (var i = 0; i < binaryLen; i++) {
    var ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
  }
  let blob = new Blob([bytes], { type: mimeType });
  let link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = fileName;
  link.click();
};

export const openByteArrayAsImage = (base64, mimeType) => {
  if (!base64)
    return;
  let binaryString = window.atob(base64);
  let binaryLen = binaryString.length;
  let bytes = new Uint8Array(binaryLen);
  for (var i = 0; i < binaryLen; i++) {
    var ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
  }
  let blob = new Blob([bytes], { type: mimeType });
  var fileURL = URL.createObjectURL(blob);
  window.open(fileURL);
}

export const FileToBase64String = file => new Promise((resolve, reject) => {
  if (!file)
    return;
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});

export const saveArrayAsCSV = (fileName, objArray) => {
  const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
  let str = `${Object.keys(array[0]).map(value => `"${value}"`).join(",")}\r\n`;
  let csvContent = array.reduce((str, next) => {
    str += `${Object.values(next).map(value => `"${value}"`).join(",")}\r\n`;
    return str;
  }, str);
  const bom = '\uFEFF'
  csvContent = csvContent.replaceAll('<br>', '; ');
  var blob = new Blob([bom + csvContent], { type: 'text/csv;charset=utf-8,' });
  var url = URL.createObjectURL(blob);
  var link = document.createElement("a");
  link.setAttribute("href", url);
  link.setAttribute("download", `${fileName}.csv`);
  link.click();
}