
import AppContext from 'context';
import { useContext, useEffect, useState, useRef, useMemo } from 'react';
import TemplateModule from '../TemplateModule';
import { useSelector } from 'react-redux';
import * as regexes from 'services/validatationRegexs';
import ParentHref from 'components/site/ParentHref';
import { isObjectEmpty } from 'services/helpFunctions';
import history from 'services/history';

const ContractPaymentIndexTypes = (props) => {
    const contractID = props.match.params.ContractID;
    const projectID = props.match.params.ProjectID;
    const [contract, setContract] = useState(useSelector(state => state.modules.selectedDataRow));
    const [project, setProject] = useState(useSelector(state => state.projects.projects.filter(project => project.ProjectID.toString() === projectID)[0]));
    const context = useRef(useContext(AppContext)).current;
    const user = useSelector(state => state.auths.user);
    const [totalWeight, setTotalWeight] = useState(0);
    const [paymentIndexTypes, setTypes] = useState([]);
    const [properties, setProperties] = useState({
        display: ["PaymentIndexTypeName", "PaymentIndexTypeIDWeight", "LinkingValue", "PaymentIndexTypeDescription", "LinkingDate"],
        dataRowViewerDisplay: ["UserName", "PaymentIndexTypeName", "PaymentIndexTypeIDWeight", "LinkingValue", "PaymentIndexTypeDescription", "LinkingDate", "IsActive", "DateCreated"],
        notEditable: ["RecordID", "UserName", "DateCreated"],
        primaryKey: ["RecordID"],
        defaultOnInsert: {
            ContractID: contractID
        },
        readable: {
            removePrefix: ["PaymentIndexType"],
            PaymentIndexTypeName: "Type Name",
            PaymentIndexTypeIDWeight: "Weight (%)",
        },
        validation: {
            PaymentIndexTypeName: [regexes.NotEmpty, regexes.Unique],
            PaymentIndexTypeIDWeight: [regexes.NotEmpty, regexes.PositiveNumber],
            LinkingValue: [regexes.NotEmpty, regexes.Float],
            LinkingDate: [regexes.NotEmpty],
        },
        sortBy: ["PaymentIndexTypeName"],
        searchBy: ["PaymentIndexTypeName"],
        datePickerSingle: ["LinkingDate"],
        selectOptions: {},
        onInsertFunc: context.InsertNewContractPaymentIndexType,
        onUpdateFunc: context.UpdateContractPaymentIndexType,
        onInsertOrUpdateSucceeded: [
            () => {
                context.GetPaymentIndexTypesWeight(contractID).then(totalWeight => {
                    console.log(`totalWeight onInsertOrUpdateSucceeded`, totalWeight)
                    setTotalWeight(totalWeight);
                })
            }
        ]
    })

    useMemo(() => {
        if (!project) {
            context.GetProjectByID(projectID).then(project => { project ? setProject(project) : history.push('/') })
        }
    }, [project, context, projectID])

    useMemo(() => {
        if (isObjectEmpty(contract)) {
            context.GetContractByID(contractID).then(contract => { contract ? setContract(contract) : history.push('/') })
        }
    }, [context, contract, contractID])

    useEffect(() => {
        setProperties(state => ({
            ...state,
            validation: {
                ...state.validation,
                PaymentIndexTypeIDWeight: [
                    ...state.validation.PaymentIndexTypeIDWeight.filter(curValidation => curValidation.name !== "ExternalFunc"),
                    {
                        ...regexes.ExternalFunc,
                        func: function (originalDataRow, updateDataRow, newWeight) {
                            const originalWeight = originalDataRow["PaymentIndexTypeIDWeight"] || 0;
                            return (newWeight - originalWeight) <= (100 - (totalWeight));
                        },
                        error: `Total weight passed 100%`
                    }
                ]
            }
        }))
    }, [totalWeight])

    useEffect(() => {
        context.GetContractPaymentIndexTypes(contractID).then(data => {
            if (data) {
                setTypes(data);

            }
        });
        context.GetPaymentIndexTypes().then(types => {
            setProperties(state => ({
                ...state,
                selectOptions: {
                    ...state.selectOptions,
                    PaymentIndexTypeName: types
                }
            }));
        });

    }, [context, contractID]);

    useEffect(() => {
        setTotalWeight(paymentIndexTypes.reduce((acc, dataRow) => { return acc + (dataRow.IsActive ? dataRow.PaymentIndexTypeIDWeight : 0) }, 0));
    }, [paymentIndexTypes]);

    useEffect(() => {
        setProperties(state => ({
            ...state,
            defaultOnInsert: {
                ...state.defaultOnInsert,
                UserName: user.UserName
            },
            defaultOnUpdate: {
                ...state.defaultOnUpdate,
                UserName: user.UserName
            }
        }))
    }, [user.UserName]);

    return (
        !isObjectEmpty(contract) && !!project ?
            <TemplateModule
                data={paymentIndexTypes}
                properties={properties}
                title={
                    <ParentHref
                        planText={["Payment Index for contract: ", "For project: "]}
                        parentsName={[contract ? `${contract["ContractName"]}` : "", project ? `${project["ProjectName"]}` : ""]}
                        parents={[contract, project]}
                    />
                }
            />
            : <></>
    );
}

export default ContractPaymentIndexTypes;