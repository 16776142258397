import AppContext from 'context';
import { useContext, useEffect, useState, useRef } from 'react';
import TemplateModule from '../TemplateModule';
import * as regexes from 'services/validatationRegexs';

const ProjectDocumentTypes = () => {
    const context = useRef(useContext(AppContext)).current;
    const [units, setDocuments] = useState([]);
    const [properties, setProperties] = useState({
        display: ["DocumentTypeName", "FormatTypeName", "DocumentTypeMaxFileSize", "DocumentTypeDescription"],
        notEditable:["DocumentTypeID"],
        primaryKey: ["DocumentTypeID"],
        readable: {
            removePrefix:[],
            DocumentTypeName: "Document Name",
            FormatTypeName: "Supported Formats",
            DocumentTypeDescription: "Description",
            DocumentTypeMaxFileSize: 'Max Size(MB)'
        },
        validation: {
            DocumentTypeName: [regexes.Unique, regexes.NotEmpty, regexes.MaxLength100],
            DocumentTypeDescription: [regexes.MaxLength250],
            DocumentTypeMaxFileSize: [regexes.PositiveNumber, regexes.MaxSize100]
        },
        sortBy: ["DocumentTypeName"],
        searchBy: ["DocumentTypeName"],
        onInsertFunc: context.InsertNewDocumentType,
        onUpdateFunc: context.UpdateDocumentType
    })
    
    useEffect(() =>{
        context.GetDocumentTypes().then(data =>{
            setDocuments(data); 
        });
        context.GetFormatTypes().then(data =>{
            setProperties(state => ({
                ...state,
                multiSelectOptions:{
                    ...state.multiSelectOptions,
                    FormatTypeName: data
                }
            }))
        })
    }, [context]);

    return(
        <TemplateModule data={units} properties={properties}/>
    );
}

export default ProjectDocumentTypes;