import React, { useContext, useEffect, useRef, useState } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import Theme from 'Theme';
import { Router, Switch, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { useSelector, useDispatch } from 'react-redux';
import { LoginUser } from 'actions/authActions';
import { insertModules, insertPermissions } from 'actions/moduleActions';
import { insertSystemParams } from 'actions/systemActions'
import history from 'services/history';
import PrivateRoute from 'services/PrivateRoute';
import Navigation from 'components/navigation/Navigation';
import Header from 'components/site/Header';
import Loader from 'components/site/Loader';
import Modules from 'views/modules/index.js';
import Login from 'views/Login';
import Home from 'views/Home';
import PageNotFound from 'views/PageNotFound';
import ToBeContinued from 'views/modules/ToBeContinued';
import AppContext from 'context';
import ServerError from 'components/site/ServerError';


const App = () => {
  const user = useSelector(state => state.auths.user);
  const dispatch = useDispatch();
  const context = useRef(useContext(AppContext)).current;
  const { loading: isLoading } = useContext(AppContext)
  const [privateRoutes, setPrivateRoutes] = useState([]);

  useEffect(() => {
    if (history.location.pathname !== '/login') {
      context.GetUser()
        .then(data => {
          const user = data;
          if (user) dispatch(LoginUser(user));
          else {
            window.sessionStorage.removeItem('gid');
            history.push('/login');
          }
        }).catch(err => {
          window.sessionStorage.removeItem('gid');
          history.push('/login');
        });
    }
  }, [dispatch, context]);

  useEffect(() => {
    if (user.AuthorizeGuid) {
      context.GetModulesByRole(user.RoleID).then(data => {
        data = data || [];
        dispatch(insertModules(data));
        setPrivateRoutes(data.map(module => <PrivateRoute exact key={module.ModuleID} path={module.ModuleUrl} component={Modules[module.ModuleName] || ToBeContinued} />))
      }).catch(err => {
        console.log(err);
      });
      context.GetPermissions().then(permissions => {
        if (permissions) {
          dispatch(insertPermissions(permissions.filter(permission => permission.RoleID === user.RoleID)));
        }
      })
      context.GetSystemParams().then(params => {
        if (params)
          dispatch(insertSystemParams(params))
      })
    }
  }, [user, dispatch, context])




  return (
    <ThemeProvider theme={Theme}>
      <Loader visible={isLoading} />
      <Router history={history}>
        {
          user.AuthorizeGuid &&
          <>
            <Header />
            <Navigation />
          </>
        }
        <Body>
          <ServerError />
          <Switch>
            <PrivateRoute exact path="/" component={Home} />
            <Route path="/login" component={Login} />
            {privateRoutes}
            {privateRoutes.length !== 0 && <Route path="*" component={PageNotFound} />}
          </Switch>
        </Body>
      </Router>
    </ThemeProvider>
  );
}

const Body = styled.div`
  font-family: 'MuseoSansRegular', sans-serif;
  font-size: 20px;
  height: 100%;
  box-sizing: border-box;
  padding: 100px 0 0 100px;
  cursor: context-menu;
  color: ${props => props.theme.colors.purple};
`;

export default App;
