import * as actions from "actions/actionTypes";

const projectReducer = (state = {}, action) => {
  switch(action.type){
    case actions.PROJECTS_LOADED:
        return{
            ...state,
            projects: action.payload.projects
        }
    case actions.PROJECT_ADDED:
      return{
        ...state,
        projects:[
          action.payload.project,
          ...state.projects
        ]
      }
    default:
      return state;
  }
};

export default projectReducer;