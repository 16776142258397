import AppContext from 'context';
import { useContext, useEffect, useState, useRef } from 'react';
import TemplateModule from '../TemplateModule';
import * as regexes from 'services/validatationRegexs';

const SystemParams = () => {
    const context = useRef(useContext(AppContext)).current;
    const [systemParams, setSystemParams] = useState([]);
    const [properties] = useState({
        display: ["ParamName", "ParamValue", "DisplayValue", "ParamDescription"],
        // dataRowViewerDisplay: ["ParamName", "ParamDescription", "ParamValue"],
        dataRowViewerDisplayInvisible: ["ParamID"],
        notEditable: ["ParamID", "ParamName", "ParamValue", "ParamDescription", "DateCreated"],
        primaryKey: ["ParamID"],
        readable: {
            removePrefix: [],
        },
        validation: {
            ParamName: [regexes.NotEmpty, regexes.MaxLength100],
            ParamValue: [regexes.NotEmpty, regexes.MaxLength100],
            ParamDescription: [regexes.NotEmpty, regexes.MaxLength250]
        },
        sortBy: ["ParamName"],
        searchBy: ["ParamName"],
        isAddable: false,
        isEditable: false,
        isDeleteable: false
    })

    useEffect(() => {
        context.GetSystemParams().then(data => {
            setSystemParams(data.map(curData => { return { ...curData, DisplayValue: "" } }));

        });

    }, [context]);

    // useEffect(() => {
    //     if(!!systemParams.length){
    //         context.GetCurrencies().then(currencies =>{
    //             const relevantParam = systemParams.find(obj => obj.ParamName === "DefaultCurrencyIDInDB")
    //             const relevantCurrency = currencies.find(currency => currency.CurrencyID === relevantParam.DefaultCurrencyIDInDB);
    //             console.log(relevantCurrency);
    //             setSystemParams(state => ([
    //                 ...state.filter(obj => obj.ParamName !== "DefaultCurrencyIDInDB"),
    //                 {
    //                     ...relevantParam,
    //                     DisplayValue: relevantCurrency
    //                 }
    //             ]))
    //         })
    //     }
    // }, [systemParams, context])

    return (
        <TemplateModule data={systemParams} properties={properties} />
    );
}

export default SystemParams;