import ActivityTypes from './indexes/ActivityTypes';
import TechnologyTypes from './indexes/TechnologyTypes';
import Labs from './indexes/Labs';
import LabTestTypes from './indexes/LabTestTypes';
import Users from './indexes/Users';
import UnitTypes from './indexes/UnitTypes';
import ItemTypes from './indexes/ItemTypes';
import Currencies from './indexes/Currencies';
import Customers from './indexes/Customers';
import ProjectDataFieldTypes from './indexes/ProjectDataFieldTypes';
import PaymentHierarchyTypes from './indexes/PaymentHierarchyTypes';
import ProjectDocumentTypes from './indexes/ProjectDocumentTypes';
import DocumentSupportedFormatTypes from './indexes/DocumentSupportedFormatTypes';
import PaymentIndexTypes from './indexes/PaymentIndexTypes';
import Roles from './menuManagement/Roles';
import Modules from './menuManagement/Modules';
import MenuTabs from './menuManagement/MenuTabs';
import IncomeGenerations from './menuManagement/IncomeGenerations';
import PermissionsPerModule from './menuManagement/PermissionsPerModule';
import SystemParams from './menuManagement/SystemParams';
import PaymentIndexTypeValues from './operational/PaymentIndexTypeValues';
import Projects from './operational/Projects';
import ProjectItems from './operational/ProjectItems';
import ProjectIncomes from './operational/ProjectIncomes';
import ProjectLabTests from './operational/ProjectLabTests';
import ActivityLabTests from './operational/ActivityLabTests';
import ActivityLabTestResults from './operational/ActivityLabTestResults';
import ProjectActivities from './operational/ProjectActivities';
import ProjectItemsPerActivity from './operational/ProjectItemsPerActivity';
import ProjectDocuments from './operational/ProjectDocuments';
import ProjectDataFieldValues from './operational/ProjectDataFieldValues';
import ProjectTechnologies from './operational/ProjectTechnologies';
import Contracts from './operational/Contracts';
import ContractPaymentHierarchyTypes from './operational/ContractPaymentHierarchyTypes';
import ContractPaymentIndexTypes from './operational/ContractPaymentIndexTypes';

const modules = {
    //<ModuleName>: Component
    "ActivityTypes": ActivityTypes,
    "TechnologyTypes": TechnologyTypes,
    "LabTestTypes": LabTestTypes,
    "Labs": Labs,
    "Users": Users,
    "UnitTypes": UnitTypes,
    "ItemTypes": ItemTypes,
    "Currencies": Currencies,
    "Customers": Customers,
    "ProjectDataFieldTypes": ProjectDataFieldTypes,
    "PaymentHierarchyTypes": PaymentHierarchyTypes,
    "ProjectDocumentTypes": ProjectDocumentTypes,
    "DocumentSupportedFormatTypes": DocumentSupportedFormatTypes,
    "PaymentIndexTypes": PaymentIndexTypes,
    "PaymentIndexTypeValues": PaymentIndexTypeValues,
    "Roles": Roles,
    "Modules": Modules,
    "MenuTabs": MenuTabs,
    "IncomeGenerations": IncomeGenerations,
    "Projects": Projects,
    "ProjectIncomes": ProjectIncomes,
    "ProjectLabTests": ProjectLabTests,
    "ActivityLabTests": ActivityLabTests,
    "ActivityLabTestResults": ActivityLabTestResults,
    "ProjectItems": ProjectItems,
    "ProjectActivities": ProjectActivities,
    "ProjectItemsPerActivity": ProjectItemsPerActivity,
    "ProjectDocuments": ProjectDocuments,
    "ProjectDataFieldValues": ProjectDataFieldValues,
    "ProjectTechnologies": ProjectTechnologies,
    "Contracts": Contracts,
    "ContractPaymentHierarchyTypes": ContractPaymentHierarchyTypes,
    "ContractPaymentIndexTypes": ContractPaymentIndexTypes,
    "Permissions": PermissionsPerModule,
    "SystemParams": SystemParams
}

export default modules;
