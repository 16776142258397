import CreatableSelect from 'react-select/creatable';
import { useTheme } from 'styled-components';

const MyCreatableSelect = (props) => {
    const theme = useTheme();
    const customStyles = {
        menu: (provided, state) => ({
            ...provided,
            fontSize: 15,
            width: props.width ? props.width : 'fit-content',
            zIndex: 2
        }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                color: (isSelected && '#FFF') || theme.colors.darkGrey,
                cursor: 'pointer',
                backgroundColor:
                    (isSelected && theme.colors.purple) ||
                    (isFocused && theme.colors.lightPurple) ||
                    'transparent',
                '&:hover': {
                    backgroundColor: isSelected ? theme.colors.purple : theme.colors.lightPurple,
                    cursor: isSelected ? 'context-menu' : 'pointer',
                }
            }
        },
        control: (base, state) => ({
            ...base,
            borderColor: state.isFocused ? theme.colors.purple : theme.colors.grey,
            borderRadius: 12,
            borderWidth: 2,
            fontSize: 15,
            fontWeight: 'bold',
            paddingLeft: 10,
            boxShadow: 'none',
            margin: props.margin ? props.margin : 0,
            cursor: 'pointer',
            whiteSpace: 'nowrap',
            width: props.width ? props.width : 'fit-content',
            '&:hover': {
                borderColor: state.isFocused ? theme.colors.purple : theme.colors.lightPurple
            }
        }),
        singleValue: (provided, state) => {
            const opacity = state.isDisabled ? 0.5 : 1;
            const transition = 'opacity 300ms';
            const color = theme.colors.darkGrey;
            const fontWeight = 'bold';
            const whiteSpace = 'nowrap';
            return { ...provided, opacity, transition, color, fontWeight, whiteSpace };
        }
    }

    return (
        <CreatableSelect
            styles={customStyles}
            {...props}
        />
    );

}

export default MyCreatableSelect;