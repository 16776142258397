import * as actions from "actions/actionTypes";
import { emptyUser } from 'store/store'

const authReducer = (state = {}, action) => {
  switch(action.type){
    case actions.USER_LOGGEDIN:
      return {
        ...state,
        user: action.payload.user
      };
    case actions.USER_LOGGEDOUT:
      return{
        ...state,
        user: {...emptyUser}
      }
    default:
      return state;
  }
};

export default authReducer;