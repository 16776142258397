import AppContext from 'context';
import { useContext, useEffect, useState, useRef } from 'react';
import TemplateModule from '../TemplateModule';
import * as regexes from 'services/validatationRegexs';

const ProjectDataFieldTypes = () => {
    const context = useRef(useContext(AppContext)).current;
    const [types, setTypes] = useState([]);
    const [properties, setProperties] = useState({
        display: ["ProjectDataFieldTypeName", "UnitTypeName", "ProjectDataFieldTypeDescription"],
        notEditable:["ProjectDataFieldTypeID"],
        primaryKey: ["ProjectDataFieldTypeID"],
        readable:{
            removePrefix:[],
            ProjectDataFieldTypeID: "Data Field ID",
            ProjectDataFieldTypeName: "Data Field Type",
            UnitTypeName: "Unit Type",
            ProjectDataFieldTypeDescription: "Description"
        },
        validation: {
            ProjectDataFieldTypeName: [regexes.Unique, regexes.NotEmpty, regexes.MaxLength100],
            ProjectDataFieldTypeDescription: [regexes.MaxLength250],
            UnitTypeName: [regexes.NotEmpty]
        },
        sortBy: ["ProjectDataFieldTypeName", "UnitTypeName"],
        searchBy: ["ProjectDataFieldTypeName", "UnitTypeName"],
        onInsertFunc: context.InsertNewProjectDataFieldType,
        onUpdateFunc: context.UpdateProjectDataFieldType
    })
    
    useEffect(() =>{
        context.GetProjectDataFieldTypes().then(data =>{
            setTypes(data); 
        });
        context.GetUnitTypes().then(data => {
            setProperties(state => ({
                ...state,
                selectOptions:{
                    ...state.selectOptions,
                    UnitTypeName: data
                }
            }))
        });
    }, [context]);

    return(
        <TemplateModule data={types} properties={properties}/>
    );
}

export default ProjectDataFieldTypes;