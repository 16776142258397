import * as actions from "actions/actionTypes";
import { emptyModule } from 'store/store';
import { isObjectsEquals } from 'services/helpFunctions';

const moduleReducer = (state = {}, action) => {
  switch(action.type){
    case actions.MODULES_LOADED:
      return {
        ...state,
        modules: action.payload.modules
      }
    case actions.MODULE_SELECTED:
      return{
        ...state,
        selectedModule: action.payload.module
      }
    case actions.MODULE_CLEARED:
      return{
        ...state,
        ...emptyModule
      }
    case actions.MODULE_DATA_LOADED:
      return{
        ...state,
        selectedModuleData: 
          action.payload.moduleData.map(rowData => {
            Object.keys(rowData).forEach(key =>{
              if(rowData[key] === null)
                rowData[key] = "";
            })
            return rowData
          })
      }
    case actions.MODULE_DATA_ROW_SELECTED:
      return{
        ...state,
        selectedDataRow: action.payload.selectedDataRow
      }
    case actions.MODULE_DATA_ISACTIVE_CHANGED:
      const { selectedDataRow, IsActive } = action.payload;
      return{
        ...state,
        selectedModuleData:[
          ...state.selectedModuleData
          .map(rowData =>{
            if(isObjectsEquals(rowData, selectedDataRow))
              rowData.IsActive = IsActive
            return rowData; 
          })
        ]
      }
    case actions.MODULE_PROPERTIES_LOADED:
      return{
        ...state,
        properties: {
          ...state.properties,
          ...action.payload.properties
        }
      }
    case actions.MODULE_DATAROW_UPDATED:
      const updatedDataRow = action.payload.updatedDataRow;
      const idParamName = Object.keys(updatedDataRow).find(key => /ID$/i.test(key));
      return{
        ...state,
        selectedModuleData:[
          updatedDataRow,
          ...state.selectedModuleData.filter(item => item[idParamName] !== updatedDataRow[idParamName]),
        ],
      }
    case actions.MODULE_UPDATED:
      const updatedModule = action.payload.updatedModule
      return{
        ...state,
        modules: [
          updatedModule,
          ...state.modules.filter(module => module.ModuleID !== updatedModule.ModuleID),
        ]
      }
    case actions.MODULES_PERMISSIONS_LOADED:
      return{
        ...state,
        permissions: action.payload.permissions
      }
    default:
      return state;
  }
};

export default moduleReducer;