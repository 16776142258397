import styled from 'styled-components';
import { useState, useContext } from 'react';
import ConfirmMsg from 'components/site/ConfirmMsg';
import { HeaderIcon } from 'components/site/Header';
import logoutIcon from 'media/icons/logout.png';
import { useDispatch } from 'react-redux';
import { LogoutUser } from 'actions/authActions';
import AppContext from 'context';

const Logout = () =>{
    const [showConfirmation, setShowConfirmation] = useState(false);
    const context = useContext(AppContext);
    let dispatch = useDispatch();

    const onAnswerChange = (answer) => {
        if(answer === true)
            logout();
        else if(answer === false)
            setShowConfirmation(false);
    };

    const handleLogoutClick = () =>{
        setShowConfirmation(true);
    }

    const logout = async() => {
        context.UserLogout();
        dispatch(LogoutUser());
    };

    return(
        <>
            <HeaderIcon src={logoutIcon} onClick={() => handleLogoutClick()}/>
            {
                showConfirmation && 
                <LogoutConfirmBox>
                    <ConfirmMsg msg="Are you sure you\n want to logout?" onChange={onAnswerChange}/>
                </LogoutConfirmBox>
            }
        </>
    );
}

const LogoutConfirmBox = styled.div`
    position: absolute;
    top: 235px;
    right: -20px;
`;

export default Logout;