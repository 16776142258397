import styled from 'styled-components';
import { useTransition, animated } from 'react-spring';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedTab } from 'actions/tabActions';
import { setDefaultSearch } from 'actions/searchActions';
import Mask from 'components/site/Mask';
import history from 'services/history';
import { useEffect, useState } from 'react';

const MenuLevel1 = () => {
    const dispatch = useDispatch();
    const selectedTab = useSelector(state => state.tabs.selectedTab);
    const tabs = useSelector(state => state.tabs.tabs);
    const [tabTop, setTabTop] = useState(0);
    const rawModules = useSelector(state => state.modules.modules);
    let modules = []

    const handleModuleClicked = (module) => {
        dispatch(setSelectedTab(0));
        dispatch(setDefaultSearch({}));
        history.push(module.ModuleUrl);
    }

    useEffect(() => {
        if (selectedTab.MenuTabID > 0)
            setTabTop(selectedTab.MenuTabTop);
    }, [selectedTab])

    if (selectedTab.MenuTabID) {
        modules = rawModules.filter(module => !module.ParentModule).map(module => {
            const tabID = tabs.filter(tab => module.MenuTabDisplayName === tab.MenuTabDisplayName)[0].MenuTabID;
            return tabID === selectedTab.MenuTabID ?
                <Module
                    key={module.ModuleID}
                    onClick={() => handleModuleClicked(module)}
                >
                    {module.ModuleDisplayName}
                </Module>
                :
                null
        });
    }

    const menuTransitions = useTransition(selectedTab.MenuTabID > 0, null, {
        from: { opacity: 0, transform: 'translateX(-30%)' },
        enter: { opacity: 1, transform: 'translateX(37.3%)' },
        leave: { opacity: 0, transform: 'translateX(-30%)' },
    })

    return (
        <>
            <Mask opacity={0} activate={selectedTab.MenuTabID > 0} click={() => dispatch(setSelectedTab(0))} zindex={50} />
            {
                menuTransitions.map(({ item, key, props }) =>
                    item &&
                    <MenuContainer
                        as={animated.div}
                        key={key}
                        style={props}
                    >
                        <ModulesContainer top={tabTop - 110}>
                            <TabName>
                                {
                                    selectedTab.MenuTabID > 0 ?
                                        tabs.filter(tab => tab.MenuTabID === selectedTab.MenuTabID)[0].MenuTabDisplayName
                                        : ""
                                }
                            </TabName>
                            {
                                modules
                            }
                        </ModulesContainer>
                    </MenuContainer>
                )
            }
        </>
    );
}

const MenuContainer = styled.div`
    position: fixed;
    display: flex;
    top: 97px;
    left: 0;
    height: 100%;
    width: 250px;
    cursor: context-menu;
    background: ${props => props.theme.colors.lightGrey};
    box-sizing: border-box;
    color: ${props => props.theme.colors.darkGrey};
    font-family: 'MuseoSansRegular', sans-serif;
    z-index: 50;
    font-size: 14px;
    padding: 10px 20px;
`;

const ModulesContainer = styled.div`
    border-radius: 20px;
    margin-top: ${props => props.top}px;
    background: #FFF;
    padding: 10px 20px;
    width: 100%;
    height: fit-content;
`;

const Module = styled.div`
    cursor: pointer;
    margin-bottom:10px;
    &:last-child{
        margin-bottom: 0;
    }
`;

const TabName = styled.div`
    text-decoration: underline;
    box-sizing: border-box;
    font-weight: bold;
    font-size: 16px;
    color: ${props => props.theme.colors.purple};
    margin-bottom: 10px;
    white-space: nowrap;
`;

export default MenuLevel1;