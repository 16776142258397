import * as actions from "actions/actionTypes";

const systemReducer = (state = {}, action) => {
    switch(action.type){
        case actions.SYSTEM_PARAMS_LOADED:
            return {
                ...state,
                systemParams: {
                    ...Object.assign({}, ...action.payload.systemParams.map(sysParam => {
                        return { [sysParam.ParamName]: sysParam }
                    }))
                }
            }
        default:
            return state;
    }
};

export default systemReducer;