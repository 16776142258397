import Select from 'react-select';
import { useTheme } from 'styled-components';

const MySelect = (props) => {
    const theme = useTheme();
    const customStyles = {

        menu: (provided, state) => {
            return {
                ...provided,
                fontSize: 15,
                width: props.width ?? 'fit-content',
                textAlign: "left",

            }
        },
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                color:
                    (isSelected && '#FFF') ||
                    (isDisabled && theme.colors.grey) ||
                    theme.colors.darkGrey,
                cursor: 'pointer',
                backgroundColor:
                    (isDisabled && theme.colors.lightGrey) ||
                    (isSelected && theme.colors.purple) ||
                    (isFocused && theme.colors.lightPurple) ||
                    'transparent',
                '&:hover': {
                    backgroundColor:
                        (isSelected && theme.colors.purple) ||
                        (isDisabled && theme.colors.lightGrey) ||
                        theme.colors.lightPurple,
                    cursor:
                        (isSelected && 'context-menu') ||
                        (isDisabled && 'not-allowed') ||
                        'pointer',
                }
            }
        },
        control: (base, state) => ({
            ...base,
            borderColor: state.isFocused ? theme.colors.purple : theme.colors.grey,
            borderRadius: 12,
            borderWidth: 2,
            fontSize: 15,
            fontWeight: 'bold',
            paddingLeft: 10,
            boxShadow: 'none',
            margin: props.margin ? props.margin : 0,
            cursor: 'pointer',
            whiteSpace: 'nowrap',
            width: props.width ? props.width : 'fit-content',
            '&:hover': {
                borderColor: state.isFocused ? theme.colors.purple : theme.colors.lightPurple
            }
        }),
        singleValue: (provided, state) => {
            const opacity = state.isDisabled ? 0.5 : 1;
            const transition = 'opacity 300ms';
            const color = theme.colors.darkGrey;
            const fontWeight = 'bold';
            const whiteSpace = 'nowrap';
            return { ...provided, opacity, transition, color, fontWeight, whiteSpace };
        },
        multiValue: (styles) => {
            return {
                ...styles,
                backgroundColor: theme.colors.lightPurple,
            };
        },
        multiValueLabel: (styles) => ({
            ...styles,
        }),
        multiValueRemove: (styles) => ({
            ...styles,
            ':hover': {
                backgroundColor: theme.colors.purple,
                color: 'white',
            },
        }),
    }

    return (
        <Select styles={customStyles}  {...props} />
    );

}

export default MySelect;