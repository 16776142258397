import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const user = useSelector(state => state.auths.user);
  return (
    <Route
      {...rest}
      render={props =>
        user.AuthorizeGuid ?
          <Component {...props} {...rest} />
          :
          <Redirect to="/login" />
      }
    />
  );
}

export default PrivateRoute;
