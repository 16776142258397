import AppContext from 'context';
import { useContext, useEffect, useState, useRef } from 'react';
import TemplateModule from '../TemplateModule';
import * as regexes from 'services/validatationRegexs';

const UnitTypes = () => {
    const context = useRef(useContext(AppContext)).current;
    const [units, setUnits] = useState([]);
    const [properties] = useState({
        display: ["UnitTypeName", "UnitTypeDescription"],
        notEditable:["UnitTypeID"],
        primaryKey: ["UnitTypeID"],
        readable: {
            removePrefix:[],
            UnitTypeName: "Unit Name",
            UnitTypeDescription: "Description"
        },
        validation: {
            UnitTypeName: [regexes.Unique, regexes.NotEmpty, regexes.MaxLength50],
            UnitTypeDescription: [regexes.MaxLength150],
        },
        sortBy: ["UnitTypeName"],
        searchBy: ["UnitTypeName"],
        onInsertFunc: context.InsertNewUnitType,
        onUpdateFunc: context.UpdateUnitType
    })
    
    useEffect(() =>{
        context.GetUnitTypes().then(data =>{
            setUnits(data); 
        });
    }, [context]);

    return(
        <TemplateModule data={units} properties={properties}/>
    );
}

export default UnitTypes;