import styled from 'styled-components';
import { useEffect, useState, useMemo } from 'react';
import CreatableSelect from 'components/form/CreatableSelect'
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedModuleData, clearModuleSelected } from 'actions/moduleActions';
import { setDefaultSearch, setShowOnlyUnactive } from 'actions/searchActions';
import CheckBox from 'components/form/CheckBox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DateRangePicker from 'components/form/DateRangePicker';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { isObjectEmpty, isObjectInList, fixColName, strTrimLower } from 'services/helpFunctions';
import moment from 'moment';

const SearchBar = ({ data, display }) => {
    const { readable, searchBy, primaryKey, datePickerSingle, datePickerRange,
        searchBarOverride, onSearchClick, onSearchClearClick } = useSelector(state => state.modules.properties);
    const [fullData, setFullData] = useState([]);
    const originalUnactive = useSelector(state => state.searchs.showUnactive);
    const [showUnactive, setShowUnactive] = useState(false);
    const { searchByDefault } = useSelector(state => state.searchs);
    const [searchItems, setSearchItems] = useState([]);
    const [multiSearch, setMultiSearch] = useState({});
    const dispatch = useDispatch();

    useEffect(() => {
        setShowUnactive(originalUnactive);
    }, [originalUnactive]);

    const handleSearchClick = () => {
        let newData;
        if (onSearchClick) {
            newData = onSearchClick(multiSearch, fullData);
        }
        else {
            newData = fullData.filter(rowData => {
                for (const key of Object.keys(rowData)) {
                    if (!Object.keys(multiSearch).find(curKey => curKey.startsWith(key)))
                        continue;
                    const isDate = datePickerSingle.includes(key) || datePickerRange.includes(key);
                    if (isDate) {
                        if (!moment(rowData[key]).isBetween(multiSearch[key + "StartDate"], multiSearch[key + "EndDate"], 'days', '[]'))
                            return false;
                    }
                    else if (!strTrimLower(rowData[key]).includes(strTrimLower(multiSearch[key])))
                        return false;
                }
                return true;
            })
        }
        dispatch(setShowOnlyUnactive(showUnactive));
        dispatch(setSelectedModuleData(newData));
    }

    const handleClear = async () => {
        if (onSearchClearClick) onSearchClearClick();
        await setSearchItems([]);
        setMultiSearch({});
        dispatch(setDefaultSearch({}));
        dispatch(setSelectedModuleData(fullData));
    }

    useMemo(() => {
        const handleSearchItemChanged = (searchKey, value) => {
            if (value === null) {
                const { [searchKey]: omit, ...res } = multiSearch;
                setMultiSearch(res);
            }
            else {
                setMultiSearch({
                    ...multiSearch,
                    [searchKey]: value.toString().trim()
                });
            }
        }

        const items = searchBy.map(searchKey => {
            let uniqueOptions = {};
            const isDate = datePickerSingle.includes(searchKey) || datePickerRange.includes(searchKey);
            let currentElement = null;
            if (searchBarOverride[searchKey]) {
                uniqueOptions = searchBarOverride[searchKey].map(option => {
                    return { label: option, value: option }
                });
            }
            else if (fullData && !!fullData.length) {
                fullData.filter(dataRow => dataRow["IsActive"] !== showUnactive && (dataRow[searchKey] || dataRow[searchKey] === 0)).forEach(dataRow => {
                    return uniqueOptions[dataRow[searchKey].toString().toLowerCase().trim()] = { label: dataRow[searchKey], value: dataRow[searchKey] }
                });
                uniqueOptions = Object.values(uniqueOptions);
                uniqueOptions.sort((option1, option2) => {
                    const val1 = option1.label.toString().toLowerCase();
                    const val2 = option2.label.toString().toLowerCase();
                    return val1 > val2 ? 1 : ((val2 > val1) ? -1 : 0);
                })
            }
            if (isDate) {
                const startDate = multiSearch[`${searchKey}StartDate`]
                const endDate = multiSearch[`${searchKey}EndDate`]
                currentElement = <DateRangePicker
                    key={searchKey}
                    title={fixColName(searchKey, readable)}
                    startDate={startDate}
                    endDate={endDate}
                    margin={"10px 10px 0 0"}
                    onDatesChange={(startDate, endDate, focusedDate) => {
                        if (focusedDate === "startDate")
                            handleSearchItemChanged(`${searchKey}StartDate`, startDate ? startDate._d : "");
                        else
                            handleSearchItemChanged(`${searchKey}EndDate`, endDate ? endDate._d : "");
                    }}
                />
            }
            else {
                currentElement = <CreatableSelect
                    key={searchKey}
                    options={uniqueOptions}
                    placeholder={fixColName(searchKey, readable)}
                    isClearable={true}
                    onChange={e => handleSearchItemChanged(searchKey, e ? e.value : null)}
                    formatCreateLabel={userInput => `Search for "${userInput}"`}
                    margin={"10px 10px 0 0"}
                    width={250}
                    z-index={2}
                />
            }
            return currentElement;
        });
        setSearchItems(items);
    }, [searchBy, showUnactive, readable, fullData, multiSearch, datePickerSingle, datePickerRange, searchBarOverride]);

    useEffect(() => {
        if (!isObjectEmpty(searchByDefault)) {
            const uniqueParams = primaryKey.length > 0 ? primaryKey : [Object.keys(searchByDefault).find(key => /ID$/i.test(key))];
            const ind = fullData.findIndex(item => uniqueParams.reduce((calc, curParam) => { return calc === true && item[curParam] === searchByDefault[curParam] }, true) && JSON.stringify(item) !== JSON.stringify(searchByDefault));
            if (ind !== -1) {//update dataRow
                const tmpFullData = fullData;
                tmpFullData.splice(ind, 1);
                tmpFullData.unshift(searchByDefault);
                setFullData(tmpFullData);
            }
            else { //insert new dataRow
                if (!isObjectInList(fullData, searchByDefault)) {
                    setFullData(state => ([searchByDefault, ...state]));
                }
            }
            dispatch(setSelectedModuleData([searchByDefault]));
        }
        else {
            dispatch(setSelectedModuleData(fullData));
        }
    }, [searchByDefault, dispatch, fullData, primaryKey])

    useEffect(() => {
        if (data)
            setFullData(data);
    }, [data])

    useEffect(() => {
        return () => {
            setFullData([]);
            dispatch(clearModuleSelected());
            dispatch(setShowOnlyUnactive(false));
        }
    }, [dispatch]);

    return (
        <SearchBarContainer display={display}>
            <VertContainer>
                <ItemsContainer numOfItems={searchItems.length}>
                    {
                        searchItems
                    }
                </ItemsContainer>
                <OnlyActiveWrapper>
                    <OnlyActiveText>
                        Show only non-active items:
                        </OnlyActiveText>
                    <CheckBox
                        cbxID={"cbxOnlyActive"}
                        isChecked={showUnactive}
                        onChange={() => setShowUnactive(!showUnactive)}
                        dataKey="IsActive"
                    />
                </OnlyActiveWrapper>
            </VertContainer>
            <ButtonsContainer>
                <Search
                    icon={faSearch}
                    onClick={() => handleSearchClick()}
                />
                <Clear onClick={() => handleClear()}>Clear</Clear>
            </ButtonsContainer>
        </SearchBarContainer>
    );
}
const SearchBarContainer = styled.div`
    display: ${props => props.display};
    margin: 15px;
    padding: 20px;
    border-radius: 15px; 
    color: ${props => props.theme.colors.darkGrey};
    box-shadow: ${props => props.theme.shadowBox.light}; 
    width: fit-content; 
`;

const OnlyActiveWrapper = styled.div`
    display: flex;
    box-sizing: initial;
    margin-top: 10px;
    align-items: center;
    padding-left: 12px;
    width: 230px;
`;

const OnlyActiveText = styled.span`
    font-size: 14px;
    margin-right: 10px;
`;

const ItemsContainer = styled.div`
    display: flex;
    width: ${props => props.numOfItems < 4 ? (props.numOfItems * 270) : ((props.numOfItems % 2 === 0 ? 0 : 1) + props.numOfItems) * 270 / 2 + 'px'};
    max-width: 1040px;
    flex-wrap: wrap;
`;

const Search = styled(FontAwesomeIcon)`
    padding: 10px 15px;
    box-sizing: initial;
    border-radius: 10px;
    background: ${props => props.theme.colors.purple};
    color: white;
    font-size: 15px;
    margin-left: 20px;
    cursor: pointer;
`;

const VertContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const Clear = styled.div`
    text-decoration: underline;
    font-weight: bold;
    font-size: 15px;
    color: black;
    margin-left: 8px;
    cursor: pointer;
`;

const ButtonsContainer = styled.div`
    display: flex;
    align-self: flex-start;
    margin-top: 12px;
    align-items: center;
`;

export default SearchBar;