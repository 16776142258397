import AppContext from 'context';
import { useContext, useEffect, useState, useRef, useMemo } from 'react';
import TemplateModule from '../TemplateModule';
import * as regexes from 'services/validatationRegexs';
import history from 'services/history';
import { useDispatch, useSelector } from 'react-redux';
import ParentHref from 'components/site/ParentHref';

const ProjectLabTests = (props) => {
    const projectID = props.match.params.ProjectID;
    const user = useSelector(state => state.auths.user);
    const { systemParams } = useSelector(state => state.system);
    const context = useRef(useContext(AppContext)).current;
    const [project, setProject] = useState(useSelector(state => state.projects.projects.filter(project => project.ProjectID.toString() === projectID)[0]));
    const [labTests, setLabTests] = useState([]);
    const dispatch = useDispatch();
    const [properties, setProperties] = useState({
        display: ["LabTestName", "Cost", "CurrencySymbol", "LabName", "TestDate", "FrequencyInMonths"],
        dataRowViewerDisplay: ["UserName", "LabTestName", "Cost", "CurrencySymbol", "LabName", "TestDate", "FrequencyInMonths", "IsActive", "DateCreated"],
        notEditable: ["ProjectID", "UserName", "DateCreated"],
        primaryKey: ["ProjectLabTestID"],
        defaultOnInsert: {
            ProjectID: projectID,
            UserName: user.UserName
        },
        readable: {
            removePrefix: [],
            LabTestName: "Test Type",
            CurrencySymbol: "Currency",
            FrequencyInMonths: "Frequency (months)"
        },
        validation: {
            LabTestName: [regexes.NotEmpty],
            UserName: [regexes.NotEmpty],
            Cost: [regexes.NotEmpty, regexes.ZeroOrPositiveNumber],
            CurrencySymbol: [regexes.NotEmpty],
            FrequencyInMonths: [regexes.PositiveNumberOrEmpty],
        },
        mandatoryOneOf: ["TestDate", "FrequencyInMonths"],
        sortBy: ["LabTestName", "LabName", "TestDate"],
        searchBy: ["LabTestName"],
        datePickerSingle: ["TestDate"],
        selectOptions: {},
        onInsertFunc: context.InsertNewProjectLabTest,
        onUpdateFunc: context.UpdateProjectLabTest,
    })

    useMemo(() => {
        if (!project) {
            context.GetProjectByID(projectID).then(project => { project ? setProject(project) : history.push('/') })
        }
    }, [project, context, projectID])

    useEffect(() => {
        context.GetProjectLabTests(projectID).then(data => {
            if (data) {
                setLabTests(data);
            }
        });
        context.GetLabTestTypes().then(labTests => {
            setProperties(state => ({
                ...state,
                selectOptions: {
                    ...state.selectOptions,
                    LabTestName: labTests ? labTests : []
                }
            }));
        });
        context.GetLabs().then(labs => {
            setProperties(state => ({
                ...state,
                selectOptions: {
                    ...state.selectOptions,
                    LabName: labs ? labs : []
                }
            }));
        });
        context.GetCurrencies().then(currencies => {
            setProperties(state => ({
                ...state,
                selectOptions: {
                    ...state.selectOptions,
                    CurrencySymbol: currencies
                },
                defaultOnInsert: {
                    ...state.defaultOnInsert,
                    CurrencySymbol: currencies.find(currency => currency.CurrencyID.toString() === systemParams.DefaultCurrencyIDInDB.ParamValue)?.CurrencySymbol
                }
            }));
        });
    }, [context, dispatch, projectID, systemParams.DefaultCurrencyIDInDB]);

    return (
        project ?
            <TemplateModule
                data={labTests}
                properties={properties}
                title={
                    <ParentHref
                        planText={["Requested Lab Tests for project: "]}
                        parentsName={[project ? `${project["ProjectName"]}` : ""]}
                        parents={[project]}
                    />
                }
            />
            : <></>
    );
}

export default ProjectLabTests;