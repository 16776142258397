import PropTypes from "prop-types";
import styled from "styled-components";
import SearchBar from "components/site/SearchBar";
import DataTable from "components/datatable/DataTable";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedModule, setSelectedModuleData, setModuleProperties } from "actions/moduleActions";
import { useEffect, useState } from "react";
import history from "services/history";

const TemplateModule = ({ data, properties, title }) => {
  const moduleNameMatch = history.location.pathname.match(/([^\/]+)\/\d+$|([^\/]+)[\/]?$/);
  const moduleName = moduleNameMatch[1] || moduleNameMatch[2];
  const module = useSelector(
    state => state.modules.modules.filter(module => module.ModuleName.toLowerCase() === moduleName.toLowerCase())[0] || {}
  );
  const [showModule, setShowModule] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    if (Object.keys(module).length > 0) {
      dispatch(setSelectedModule(module));
    }
  }, [module, dispatch]);

  useEffect(() => {
    if (data && data.length > 0) dispatch(setSelectedModuleData(data));
  }, [data, dispatch]);

  useEffect(() => {
    dispatch(setModuleProperties(properties));
  }, [properties, dispatch]);

  return (
    <ModuleContainer>
      <ModuleTitle>{title || module.ModuleDisplayName}</ModuleTitle>
      <SearchBar data={data} display={showModule === true ? "flex" : "none"} />
      <DataTable moduleName={module.ModuleName} setShowModule={setShowModule} />
    </ModuleContainer>
  );
};

TemplateModule.propTypes = {
  data: PropTypes.array,
  properties: PropTypes.object,
};

TemplateModule.defaultProps = {
  data: [],
};

const ModuleContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 10px 20px;
  box-sizing: border-box;
  overflow-y: auto;
`;

const ModuleTitle = styled.div`
  font-size: 35px;
  color: black;
  margin: 0 0 0 20px;
`;

export default TemplateModule;
