
import * as actions from "actions/actionTypes";

export const insertSystemParams = (systemParams) =>{
    return{
        type: actions.SYSTEM_PARAMS_LOADED,
        payload:{
            systemParams,
        }
    }
}
