
import AppContext from 'context';
import { useContext, useEffect, useState, useRef, useMemo } from 'react';
import TemplateModule from '../TemplateModule';
import { useSelector } from 'react-redux';
import * as regexes from 'services/validatationRegexs';
import history from 'services/history';
import ParentHref from 'components/site/ParentHref';
import { isObjectEmpty } from 'services/helpFunctions';

const ActivityLabTests = (props) => {
    const projectActivityID = props.match.params.ProjectActivityID;
    const projectID = props.match.params.ProjectID;
    const { systemParams } = useSelector(state => state.system);
    const [activity, setActivity] = useState(useSelector(state => state.modules.selectedDataRow));
    const [project, setProject] = useState(useSelector(state => state.projects.projects.filter(project => project.ProjectID.toString() === projectID)[0]));
    const user = useSelector(state => state.auths.user);
    const context = useRef(useContext(AppContext)).current;
    const [labTests, setLabTests] = useState([]);
    const [properties, setProperties] = useState({
        display: ["LabTestName", "LabName", "LabTestDate", "LabTestPrice", "CurrencySymbol", "LabTestDescription"],
        dataRowViewerDisplay: ["UserName", "LabTestName", "LabName", "LabTestDate", "LabTestPrice", "CurrencySymbol", "LabTestDescription", "IsActive", "DateCreated"],
        notEditable: ["LabTestID", "ProjectActivityID", "UserName", "DateCreated"],
        primaryKey: ["LabTestID"],
        defaultOnInsert: {
            ProjectActivityID: projectActivityID
        },
        readable: {
            removePrefix: ["Lab"],
            LabName: "Lab Name",
            CurrencySymbol: "Currency",
            LabTestDescription: "Description"
        },
        validation: {
            LabTestName: [regexes.NotEmpty, regexes.Unique, regexes.MaxLength100],
            LabName: [regexes.NotEmpty],
            LabTestDate: [regexes.NotEmpty],
            LabTestPrice: [regexes.NotEmpty, regexes.ZeroOrPositiveNumber],
            CurrencySymbol: [regexes.NotEmpty]
        },
        sortBy: ["LabTestName", "LabName", "LabTestDate", "LabTestPrice"],
        searchBy: ["LabTestName", "LabName", "LabTestDate"],
        datePickerSingle: ["LabTestDate"],
        selectOptions: {},
        jumpTo: {
            passParam: ["LabTestID"],
            replaceUrlPrefix: false,
            associatedModules: {
                "Lab Test Results": "ActivityLabTestResults",
            }
        },
        onInsertFunc: context.InsertNewActivityLabTest,
        onUpdateFunc: context.UpdateActivityLabTest,
    })

    useMemo(() => {
        if (!project) {
            context.GetProjectByID(projectID).then(project => { project ? setProject(project) : history.push('/') })
        }
    }, [project, context, projectID])

    useMemo(() => {
        if (isObjectEmpty(activity) || !activity["ActivityTypeName"]) {
            context.GetProjectActivityByID(projectActivityID).then(activity => { activity ? setActivity(activity) : history.push('/') })
        }
    }, [context, activity, projectActivityID])

    useEffect(() => {
        context.GetActivityLabTests(projectActivityID).then(data => {
            if (data) {
                setLabTests(data);
            }
        });
        context.GetCurrencies().then(currencies => {
            setProperties(state => ({
                ...state,
                selectOptions: {
                    ...state.selectOptions,
                    CurrencySymbol: currencies
                },
                defaultOnInsert: {
                    ...state.defaultOnInsert,
                    CurrencySymbol: currencies.find(currency => currency.CurrencyID.toString() === systemParams.DefaultCurrencyIDInDB.ParamValue)?.CurrencySymbol
                }
            }));
        });
        context.GetLabs().then(labs => {
            setProperties(state => ({
                ...state,
                selectOptions: {
                    ...state.selectOptions,
                    LabName: labs
                }
            }));
        });
    }, [context, projectActivityID, systemParams.DefaultCurrencyIDInDB]);

    useEffect(() => {
        setProperties(state => ({
            ...state,
            defaultOnInsert: {
                ...state.defaultOnInsert,
                UserName: user.UserName
            },
            defaultOnUpdate: {
                ...state.defaultOnUpdate,
                UserName: user.UserName
            }
        }))
    }, [user.UserName]);

    return (
        !isObjectEmpty(activity) && !!project ?
            <TemplateModule
                data={labTests}
                properties={properties}
                title={
                    <ParentHref
                        planText={["Lab Tests For Activity: ", "For project: "]}
                        parentsName={[activity && activity["ActivityTypeName"] ? `${activity["ActivityTypeName"]}` : "", project ? `${project["ProjectName"]}` : "",]}
                        parents={[activity, project]}
                    />
                }
            />
            : <></>
    );
}

export default ActivityLabTests;