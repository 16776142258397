import AppContext from 'context';
import { useContext, useEffect, useState, useRef, useMemo } from 'react';
import TemplateModule from '../TemplateModule';
import { useSelector } from 'react-redux';
import * as regexes from 'services/validatationRegexs';
import history from 'services/history';
import ParentHref from 'components/site/ParentHref';
import { saveByteArray, openByteArrayAsImage } from 'services/helpFunctions';

const ProjectDocuments = (props) =>{
    const projectID = props.match.params.ProjectID;
    const [project, setProject] = useState(useSelector(state => state.projects.projects.filter(project => project.ProjectID.toString() === projectID)[0]));
    const user = useSelector(state => state.auths.user);
    const context = useRef(useContext(AppContext)).current;
    const [documents, setDocuments] = useState([]);
    const [allowedFormats, setAllowedFormats] = useState([]);
    const [maxFileSize, setMaxFileSize] = useState(0);
    const [properties, setProperties] = useState({
        display: ["ProjectDocumentType", "ProjectDocumentFileName", "ProjectDocumentFileDescription"],
        dataRowViewerDisplay: ["UserName", "ProjectDocumentType", "ProjectDocumentFile", "ProjectDocumentFileName", "ProjectDocumentFileDescription", "IsActive"],
        dataRowViewerDisplayInvisible: ["ProjectDocumentFileName"],
        notEditable:["ProjectDocumentType", "UserName", "ProjectDocumentFile"],
        resetOnChange: {
            ProjectDocumentType: ["ProjectDocumentFile", "ProjectDocumentFileName"]
        },
        primaryKey: ["ProjectDocumentID"],
        readable: {
            removePrefix:["ProjectDocument"],
            ProjectDocumentType: "Document type",
            ProjectDocumentFileName: "File Name",
            ProjectDocumentFile: "File",
        },
        defaultOnInsert:{
            ProjectID: projectID
        },
        selectFile: {
            ProjectDocumentFile: {
                placeholderCol: "ProjectDocumentFileName",
            }
        },
        validation: {
            ProjectDocumentType: [regexes.NotEmpty, {
                                    ...regexes.ExternalFunc,
                                    func: function(originalDataRow, updateDataRow, documentType){
                                        context.GetAllowedFormats(escape(documentType)).then(formats => {
                                            setAllowedFormats(formats);
                                        });
                                        context.GetDocumentMaxFileSize(escape(documentType)).then(maxSize =>{
                                            setMaxFileSize(maxSize);
                                        })
                                        return true;
                                    },
                                }],
            ProjectDocumentFile: [regexes.NotEmpty],
            UserName: [regexes.NotEmpty]
        },
        sortBy: ["ProjectDocumentType", "ProjectDocumentFileName"],
        searchBy: ["ProjectDocumentType", "ProjectDocumentFileName"],
        selectOptions: {},
        onInsertFunc: context.InsertNewProjectDocument,
        onUpdateFunc: context.UpdateProjectDocument,
        onEditClick: (selectedDocument) => {
            context.GetAllowedFormats(escape(selectedDocument.ProjectDocumentType)).then(formats => {
                setAllowedFormats(formats);
            });
        },
        onDocumentDownloadClick: (selectedDocument => {
            context.GetDocumentByDocumentID(selectedDocument.ProjectDocumentID).then(docByteArray => {
                const formatTypeMIME = selectedDocument.FormatTypeMIME || selectedDocument.ProjectDocumentFile.type
                saveByteArray(selectedDocument.ProjectDocumentFileName, docByteArray, formatTypeMIME);
            });
        }),
        onDocumentOpenClick: (selectedDocument => {
            context.GetDocumentByDocumentID(selectedDocument.ProjectDocumentID).then(docByteArray => {
                const formatTypeMIME = selectedDocument.FormatTypeMIME || selectedDocument.ProjectDocumentFile.type
                openByteArrayAsImage(docByteArray, formatTypeMIME);
            });
        }),
        dynamicValueCallback:{
            ProjectDocumentFileName: (updatedDocument) => {
                return  updatedDocument.ProjectDocumentFile && updatedDocument.ProjectDocumentFile.name
                        ?updatedDocument.ProjectDocumentFile.name
                        :updatedDocument.ProjectDocumentFileName;
            }
        },
    })

    useMemo(() =>{
        if(!project){
            context.GetProjectByID(projectID).then(project =>{ project? setProject(project) : history.push('/') })
        }
    }, [project, context, projectID])

    useEffect(() => {
        setProperties(state => ({
            ...state,
            selectFile:{
                ...state.selectFile,
                ProjectDocumentFile: {
                    ...state.selectFile.ProjectDocumentFile,
                    accept: allowedFormats? allowedFormats.map(format => '.'+format.FormatTypeName) : [],
                    maxSize: maxFileSize*1024*1024 //MB to bytes
                }
            },
        })) 
    }, [allowedFormats, maxFileSize])

    useEffect(() =>{
        context.GetProjectDocuments(projectID).then(documents =>{
            if(documents){
                setDocuments(documents);
            }
        });
        context.GetDocumentTypes().then(docTypes => {
            setProperties(state => ({
                ...state,
                selectOptions:{
                    ProjectDocumentType:{
                        DocumentTypeName: docTypes
                    }
                }
            }))
        });
        // context.GetUsers().then(users =>{
        //     setProperties(state => ({
        //         ...state,
        //         defaultOnInsert:{
        //             ...state.defaultOnInsert,
        //             UserName: users.find(curUser => curUser.UserID === user.UserID)["UserName"]
        //         },
        //         validation:{
        //             ...state.validation,
        //             ProjectDocumentType:[
        //                 ...state.validation.ProjectDocumentType.filter(curValidation => curValidation.name !== "ExternalFunc"),
        //                 {
        //                     ...regexes.ExternalFunc,
        //                     func: function(originalDataRow, updateDataRow, documentType){
        //                         context.GetAllowedFormats(escape(documentType)).then(formats => {
        //                             setAllowedFormats(formats);
        //                         });
        //                         context.GetDocumentMaxFileSize(escape(documentType)).then(maxSize =>{
        //                             setMaxFileSize(maxSize);
        //                         })
        //                         return true;
        //                     },
        //                 }
        //             ]
        //         }
        //     }));
        // })
    }, [context, projectID]);

    useEffect(() => {
        setProperties(state=>({
            ...state,
            defaultOnInsert: {
                ...state.defaultOnInsert,
                UserName: user.UserName
            },
            defaultOnUpdate: {
                ...state.defaultOnUpdate,
                UserName: user.UserName
            }
        }))
    }, [user.UserName])

    return(
        !!project? 
        <TemplateModule 
            data={documents} 
            properties={properties} 
            title={
                <ParentHref 
                    planText={["Documents for project: "]}
                    parentsName={[project? (`${project["ProjectName"]}` || "") : ""]}
                    parents={[project]}
                />
            }
        /> 
        : <></>
    );
}

export default ProjectDocuments;