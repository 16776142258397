import AppContext from 'context';
import { useContext, useEffect, useState, useRef } from 'react';
import TemplateModule from '../TemplateModule';
import * as regexes from 'services/validatationRegexs';

const Users = () => {
    const context = useRef(useContext(AppContext)).current;
    const [users, setUsers] = useState([]);
    const [properties, setProperties] = useState({
        display: ["UserName", "RoleName", "UserEmail", "UserFName", "UserLName", "UserPhone"],
        notEditable:["UserID", "DateCreated"],
        primaryKey: ["UserID"],
        readable: {
            removePrefix:[],
            UserName: "User Name",
            RoleName: "Role",
            UserEmail: "Email",
            UserFName: "First Name",
            UserLName: "Last Name",
            UserPhone: "Phone Number"
        },
        validation: {
            UserName:     [regexes.Unique, regexes.NotEmpty, regexes.MaxLength50],
            RoleName:     [regexes.NotEmpty],
            UserPwd:      [regexes.NotEmpty, regexes.MaxLength20],
            UserEmail:    [regexes.NotEmpty, regexes.MaxLength100, regexes.Email],
            UserFName:    [regexes.NotEmpty, regexes.MaxLength50],
            UserLName:    [regexes.NotEmpty, regexes.MaxLength50],
            UserPhone:    [regexes.NotEmpty, regexes.MaxLength50],
        },
        sortBy: ["UserName", "RoleName"],
        searchBy: ["UserName", "UserFName", "UserLName", "RoleName", "UserPhone", "UserEmail"],
        onInsertFunc: context.InsertNewUser,
        onUpdateFunc: context.UpdateUser
    })
    
    useEffect(() =>{
        context.GetUsers().then(users =>{
            if(users)
                setUsers(users); 
        });
        context.GetRoles().then(data => {
            if(data){
                setProperties(state => ({
                    ...state,
                    selectOptions:{
                        ...state.selectOptions,
                        RoleName: data
                    }
                }));
            }
        });
    }, [context]);

    return(
        <TemplateModule data={users} properties={properties}/>
    );
}

export default Users;