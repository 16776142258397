import * as actions from "actions/actionTypes";

const tabReducer = (state = {}, action) => {
  switch(action.type){
    case actions.TABS_LOADED:
      return {
        ...state,
        tabs: action.payload.tabs
      };
    case actions.TAB_SELECTED:
      return {
        ...state,
        selectedTab: {
          MenuTabID: action.payload.MenuTabID,
          MenuTabTop: action.payload.MenuTabTop
        }
      };
    default:
      return state;
  }
};

export default tabReducer;