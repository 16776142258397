import { useState } from 'react';
import { DateRangePicker } from 'react-dates';
import styled from 'styled-components';
import moment from 'moment';
import PropType from 'prop-types';

const falseFunc = (() => false);
const MyDateRangePicker = ({
    onDatesChange, displayFormat, startDate, endDate, startDatePlaceholderText, endDatePlaceholderText, margin, title
}) =>{
    const [curStartDate, setStartDate] = useState(startDate);
    const [curEndDate, setEndDate] = useState(endDate);
    const [focusedInput, setFocusedInput] = useState();

    return(
        <DateRangePickerWrapper margin={margin} title={title}>
            {
                title&& 
                <Title>{title}</Title>
            }
            <DateRangePicker
                startDateId={"startDate"}
                endDateId={"EndDate"}
                startDatePlaceholderText={startDatePlaceholderText}
                endDatePlaceholderText={endDatePlaceholderText}
                startDate={curStartDate? moment(curStartDate) : null}
                endDate={curEndDate? moment(curEndDate) : null}
                onDatesChange={({ startDate, endDate }) => {
                    if(focusedInput === "startDate")
                        setStartDate(startDate);
                    else
                        setEndDate(endDate);
                    onDatesChange(startDate, endDate, focusedInput)
                }}
                displayFormat={displayFormat}
                focusedInput={focusedInput}
                onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
                isOutsideRange={falseFunc}
            />
        </DateRangePickerWrapper>
    );
} 

MyDateRangePicker.defaultProps = {
    displayFormat: 'DD/MM/YYYY',
    startDate: null,
    endDate: null,
    startDatePlaceholderText: "Start Date",
    endDatePlaceholderText: "End Date",
    title: null,
    margin: ""
}

MyDateRangePicker.propTypes = {
    onDatesChange: PropType.func.isRequired
}

const DateRangePickerWrapper = styled.div`
    display: flex;
    align-items: flex-start;
    margin: ${props => props.margin};
    flex-direction: column;
    position: relative;
    ${({ title }) => title && `
        margin-top: 0;
    `}

    .CalendarDay__selected_span {
        background: ${props => props.theme.colors.lightPurple};
        color: #FFF;
        border: 1px solid ${props => props.theme.colors.darkGrey};
    }

    .CalendarDay__selected {
        background: ${props => props.theme.colors.purple};
        color: #FFF;
        border-color: ${props => props.theme.colors.darkGrey}
    }

    .CalendarDay__selected:hover {
        background: ${props => props.theme.colors.darkGrey};
        color: #FFF;
    }

    .CalendarDay__hovered_span:hover,
    .CalendarDay__hovered_span {
        background: ${props => props.theme.colors.lightPurple};
        border: 1px solid ${props => props.theme.colors.darkGrey};
        color: #FFF;
    }

    .DayPickerKeyboardShortcuts_show__bottomRight::before {
        border-right: 33px solid ${props => props.theme.colors.lightPurple}
    }

    .DateInput_input__focused {
        border-bottom: 2px solid ${props => props.theme.colors.lightPurple};
    }

    .DateRangePickerInput_arrow_svg {
        fill: ${props => props.theme.colors.lightPurple};
    }

    .DateRangePickerInput__withBorder {
        border: 2px solid ${props => props.theme.colors.grey};
        border-radius: 12px;
    }

    .DateInput_input{
        font-size: 15px;
        color: ${props => props.theme.colors.darkGrey};
        font-weight: bold;
        padding: 6px 0px 4px 11px;
    }

    .DateInput {
        margin: 0 8px;
        width: 103px;
    }

    .DayPickerNavigation_svg__horizontal {
        fill: ${props => props.theme.colors.lightPurple};
    }

    .DateRangePicker_picker{
        z-index: 2;
    }
`;

const Title = styled.span`
    z-index: 1;
    font-size: 14px;
    background: white;
    margin-bottom: -11px;
    margin-inline-start: 10px;
    padding: 0 3px;
    color: ${props => props.theme.colors.grey};
`;

export default MyDateRangePicker;