import AppContext from 'context';
import { useContext, useEffect, useState, useRef } from 'react';
import TemplateModule from '../TemplateModule';
import { insertProjects } from 'actions/projectActions';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import ConfirmMsg from 'components/site/ConfirmMsg';
import Select from 'components/form/Select';
import styled from 'styled-components';
import { setDefaultSearch } from 'actions/searchActions';

const emptyDefaultGeneration = {
    ProjectName: null,
    ProjectID: null,
    GenerationLevel: -1,
    GenerationYear: null,
    GenerationMonth: null,
    GenerationLevelOptions: null,
};

const IncomeGenerations = () => {
    const context = useRef(useContext(AppContext)).current;
    const user = useSelector(state => state.auths.user);
    const dispatch = useDispatch();
    const projects = useSelector(state => state.projects.projects);
    const [contracts, setContracts] = useState(null);
    const [generateProject, setGenerateProject] = useState(emptyDefaultGeneration);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [incomeGenerations, setIncomeGenerations] = useState([]);
    const [properties] = useState({
        display: ["ProjectName", "IncomeGenerationID", "GenerationDate", "GenerationLevel", "GenerationStatusName", "GeneratedByUserName", "DateCreated"],
        dataRowViewerDisplay: ["GeneratedByUserName", "IncomeGenerationID", "ProjectName", "GenerationDate", "DateCreated", "GenerationLevel", "GenerationStatusName", "IsActive", "GenerationLog"],
        notEditable: ["GeneratedByUserName", "IncomeGenerationID", "ProjectName", "DateCreated", "GenerationLevel", "GenerationStatusName", "GenerationLog"],
        priamryKey: ["IncomeGenerationID"],
        readable: {
            removePrefix: [],
            ProjectName: "Project",
            GenerationStatusName: "Status",
            GeneratedByUserName: "Generated by",
            GenerationLevel: "Level",
        },
        sortBy: ["ProjectName", "GenerationDate", "GenerationStatusName", "GeneratedByUserName", "GenerationLevel"],
        searchBy: ["ProjectName", "GenerationDate", "GenerationLevel"],
        datePickerSingle: ["DateCreated", "GenerationDate"],
        jumpTo: {
            passParam: ["ProjectID", "IncomeGenerationID"],
            replaceUrlPrefix: true,
            associatedModules: {
                "Incomes": "ProjectIncomes",
            }
        },
        onAddClick: (dataRow) => {
            setShowConfirmation(true);
        },
        onInsertFunc: context.InsertNewIncomeGeneration,
        onUpdateFunc: context.UpdateIncomeGeneration,
        onDeleteMsg: (dataRow) => { return `This will delete income generation\\nfor project ${dataRow.ProjectName}\\nfor month ${moment(dataRow.GenerationDate).format('MM/YY')},\\nwith all relevant project income records.` },
        isEditable: (isActive) => { return isActive },
        smallDateFormatsKeys: ["GenerationDate"],
        filterParams: ["IncomeGenerationID"]
    })

    useEffect(() => {
        if (!projects.length) {
            context.GetProjects().then(allProjects => {
                if (allProjects) {
                    dispatch(insertProjects(allProjects));
                }
            });
        }
        else {
            context.GetIncomeGenerations().then(generations => {
                if (generations) {
                    const projectsName = Object.assign({}, ...projects.map(project => { return { [project.ProjectID]: project.ProjectName } }))
                    setIncomeGenerations(generations.map(generation => {
                        return {
                            ...generation,
                            ProjectName: projectsName[generation.ProjectID],
                            GenerationStatusName: GenerationStatus[generation.GenerationStatus],
                        }
                    }));
                }
            })
        }
    }, [context, projects, user.UserName, dispatch]);


    const onAnswerChange = async (answer) => {
        let newIncomeGeneration = {
            IncomeGenerationID: 0,
            ProjectID: generateProject.ProjectID,
            GenerationStatus: 2,
            GenerationLevel: generateProject.GenerationLevel,
            GeneratedByUserName: user.UserName,
            GenerationLog: "",
            IsActive: true,
            GenerationDate: new Date(parseInt(generateProject.GenerationYear), parseInt(generateProject.GenerationMonth), 0)
        }
        if (answer === true) {
            context.setLoading(true);
            const retIncomeGeneration = await context.InsertNewIncomeGeneration(newIncomeGeneration);
            if (retIncomeGeneration.GenerationStatus === 2) {
                //TODO: popup error from 'retIncomeGeneration.GenerationLog'
            }
            retIncomeGeneration.GenerationStatusName = GenerationStatus[retIncomeGeneration.GenerationStatus];
            retIncomeGeneration.ProjectName = generateProject.ProjectName;
            dispatch(setDefaultSearch(retIncomeGeneration));
            context.setLoading(false);
        }
        else {
            setGenerateProject(emptyDefaultGeneration);
        }
        setShowConfirmation(false);
    };

    const handleProjectSelected = async (e) => {
        let levels = [];
        if (e) {
            levels = await context.GetProjectActivitiesLevels(e.value) || [];
        }
        setGenerateProject(state => ({
            ...state,
            ProjectName: e?.label,
            ProjectID: e?.value,
            GenerationLevelOptions: levels.map(level => { return { value: level, label: level } })
        }));
    }


    useEffect(() => {
        (() => {
            if (generateProject.ProjectID)
                context.GetContracts(generateProject.ProjectID).then(data => {
                    if (data) {
                        setContracts(data);
                    }
                });
        })()
    }, [generateProject.ProjectID, context]);



    return (
        <>
            {
                showConfirmation &&
                <MsgContainer>
                    <ConfirmMsg
                        msg={`This will start income generation`}
                        onChange={onAnswerChange}
                        lockLeftButton={!(!!generateProject.ProjectName && generateProject.GenerationLevel >= 0 && !!generateProject.GenerationYear && !!generateProject.GenerationMonth)}
                        leftAns={"Generate"}
                        rightAns={"Cancel"}
                        childComponent={
                            <SelectContainer>
                                <Select
                                    placeholder={"Project Name"}
                                    options={projects.filter(project => project.IsActive).map(inc => { return { label: inc.ProjectName, value: inc.ProjectID } })}
                                    onChange={handleProjectSelected}
                                    width={266}
                                />
                                <SelectRow>
                                    <Select
                                        placeholder={"Level"}
                                        onChange={e => {

                                            setGenerateProject(state => ({ ...state, GenerationLevel: e?.value }))
                                        }}
                                        options={generateProject.GenerationLevelOptions}
                                        isDisabled={!generateProject.ProjectID}
                                        width={120}
                                    />
                                    <Select
                                        placeholder={"Date"}
                                        options={
                                            (() => {

                                                if (projects && contracts) {
                                                    const selectProjectContract = contracts.find(contract => contract.ProjectID === generateProject.ProjectID && contract.IsActive);
                                                    const ContractDateEnd = moment(selectProjectContract?.ContractDateEnd);
                                                    const ContractDateStart = moment(selectProjectContract?.ContractDateStart);
                                                    const timeValues = [];
                                                    while (ContractDateEnd > ContractDateStart || ContractDateStart.format('M') === ContractDateEnd.format('M')) {
                                                        timeValues.push(ContractDateStart.format('YYYY-MM'));
                                                        ContractDateStart.add(1, 'month');
                                                    }
                                                    return timeValues.map(d => {
                                                        const date = moment(d);
                                                        const month = moment(date).format('MM');
                                                        const year = moment(date).format('YYYY');
                                                        return { label: date.format('MM/YYYY'), value: [month, year] }
                                                    });
                                                }
                                            })()
                                        }
                                        onChange={e => setGenerateProject(state => ({ ...state, GenerationYear: e?.value[1], GenerationMonth: e?.value[0] }))}
                                        width={140}
                                        isDisabled={generateProject.GenerationLevel < 0}
                                    />
                                </SelectRow>
                            </SelectContainer>
                        }
                    />
                </MsgContainer>
            }
            <TemplateModule data={incomeGenerations} properties={properties} />
        </>
    );
}

const SelectContainer = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    gap: 10px;
    margin: -10px 0 10px 0;
`;


const SelectRow = styled.div`
    display: flex;
    justify-content: space-between;
`;

const MsgContainer = styled.div`
    position: absolute;
    top: 60%;
    right: 0;
`;

const GenerationStatus = {
    1: "Success",
    2: "Failed"
}

export default IncomeGenerations;