const Theme = {
    colors:{
        purple: '#820A5F',
        lightPurple: '#C084AF',
        grey: '#DEDEDE',
        lightGrey:'#F0F0F0',
        darkGrey: '#757575',
        darkerGrey: '#474747',
        error: '#DD0D0D',
        export: '#209E62'
    },
    shadowBox:{
        light: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);',
        bold: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);',
    },
}

export default Theme;


