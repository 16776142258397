import * as actions from "actions/actionTypes";

const searchReducer = (state = {}, action) => {
    switch(action.type){
        case actions.SEARCH_ITEM_SELECTED:
            return {
                ...state,
                searchByDefault: action.payload.searchByDefault,
            }
        case actions.SEARCH_UNACTIVE_TOGGLED:
            return{
                ...state,
                showUnactive: action.payload.showUnactive
            }
        default:
            return state;
    }
};

export default searchReducer;