import PropTypes from "prop-types";
import Dropzone from "react-dropzone";
import styled from "styled-components";

const MyDropzone = ({handleDrop, accept, maxSize, handleError, placeholder, isDisabled}) =>{
    return(
        <Dropzone 
            onDrop={(acceptedFile) => {
                handleDrop(isDisabled? null : acceptedFile[0]);
            }}
            accept={accept}
            maxSize={maxSize}
        >
            {
                ({getRootProps, getInputProps, fileRejections }) => {
                    if(fileRejections[0]){
                        let error = fileRejections[0].errors[0];
                        let retError;
                        switch(error.code){
                            case 'file-invalid-type':
                                retError = 'invalid file type';
                                break;
                            case 'file-too-large':
                                retError = error.message.replace(/^([^\d]+)([\d]+)(.*)/g,
                                    (_, a, b,) => {return `${a}${(parseInt(b, 10)/ (1024*1024)).toFixed(0)}MB`});
                                break;
                            default:
                                retError = 'invalid file.'
                        }
                        handleError(retError);
                    }
                    return(
                        <DropzoneContainer {...getRootProps({onClick: event => isDisabled? event.stopPropagation() : event})} isDisabled={isDisabled}>
                            <input {...getInputProps()} />
                            <DropzoneText>
                            {
                                placeholder? (placeholder.length > 20? '..'+placeholder.substr(-24) : placeholder ) : "Drag'n'Drop or click"
                            }
                            </DropzoneText>
                        </DropzoneContainer>
                    )
                }
            }
        </Dropzone>
    )
}

MyDropzone.propTypes ={
    handleDrop: PropTypes.func
}

const DropzoneContainer = styled.div`
    text-align: center;
    padding: 6px 20px;
    width: 100%;
    outline: none;
    border: 2px dashed #eeeeee;
    background: #fafafa;
    color: #bdbdbd;
    cursor: ${props => props.isDisabled? 'no-drop' : 'pointer'};
`;

const DropzoneText = styled.span`
    font-size: 15px;
`;

export default MyDropzone;