import AppContext from 'context';
import { useContext, useEffect, useState, useRef, useMemo } from 'react';
import { useSelector } from 'react-redux';
import TemplateModule from '../TemplateModule';
import * as regexes from 'services/validatationRegexs';
import history from 'services/history';
import ParentHref from 'components/site/ParentHref';


const ProjectDataFieldValues = (props) =>{
    const projectID = props.match.params.ProjectID;
    const user = useSelector(state => state.auths.user);
    const [project, setProject] = useState(useSelector(state => state.projects.projects.filter(project => project.ProjectID.toString() === projectID)[0]));
    const context = useRef(useContext(AppContext)).current;
    const [values, setValues] = useState([]);
    const [dataFieldTypes, setDataFielTypes] = useState([]);
    const [properties, setProperties] = useState({
        display: ["ProjectDataFieldTypeName", "ProjectDataFieldVal", "UnitTypeName", "ProjectDataFieldValueRemark", "ProjectDataFieldValueDate"],
        dataRowViewerDisplay: ["UserName", "ProjectDataFieldTypeName", "ProjectDataFieldVal", "UnitTypeName", "ProjectDataFieldValueRemark", "ProjectDataFieldValueDate", "IsActive"],
        notEditable:["UnitTypeName", "UserName", "DateCreated"],
        primaryKey: ["RecordID"],
        defaultOnInsert:{
            ProjectID: projectID
        },
        readable: {
            removePrefix: ["ProjectDataField"],
            UnitTypeName: "Unit Type",
            ProjectDataFieldVal: "Value",
        },
        validation: {
            ProjectDataFieldTypeName: [regexes.NotEmpty],
            ProjectDataFieldVal: [regexes.NotEmpty],
            ProjectDataFieldValueDate: [regexes.NotEmpty],
            UserName: [regexes.NotEmpty],
        },
        datePickerSingle: ["ProjectDataFieldValueDate"],
        sortBy: ["ProjectDataFieldTypeName", "UnitTypeName", "ProjectDataFieldValueDate"],
        searchBy: ["ProjectDataFieldTypeName", "UnitTypeName", "ProjectDataFieldValueDate" ],
        selectOptions: {},
        onInsertFunc: context.InsertNewProjectDataFieldValue,
        onUpdateFunc: context.UpdateProjectDataFieldValue
    })

    useMemo(() =>{
        if(!project){
            context.GetProjectByID(projectID).then(project =>{ project? setProject(project) : history.push('/') })
        }
    }, [project, context, projectID])

    useEffect(() => {
        setProperties(state => ({
            ...state,
            dynamicValueCallback:{
                UnitTypeName: updatedDataRow => {
                    const relevantDataFieldType = dataFieldTypes.find(fieldType => fieldType.ProjectDataFieldTypeName === updatedDataRow.ProjectDataFieldTypeName);
                    return relevantDataFieldType? relevantDataFieldType.UnitTypeName : "";
                }
            }
        }));
    }, [dataFieldTypes])
    
    useEffect(() =>{
        context.GetProjectDataFieldValues(projectID).then(data =>{
            if(data){
                setValues(data); 
            }
        });
        context.GetProjectDataFieldTypes().then(dataFieldTypes =>{
            setDataFielTypes(dataFieldTypes);
            setProperties(state => ({
                ...state,
                selectOptions:{
                    ...state.selectOptions,
                    ProjectDataFieldTypeName: dataFieldTypes
                }
            }));
        });
    }, [context, projectID]);

    useEffect(() => {
        setProperties(state=>({
            ...state,
            defaultOnInsert: {
                ...state.defaultOnInsert,
                UserName: user.UserName
            },
            defaultOnUpdate: {
                ...state.defaultOnUpdate,
                UserName: user.UserName
            }
        }))
    }, [user.UserName])

    return(
        !!project? 
        <TemplateModule 
            data={values} 
            properties={properties} 
            title={
                <ParentHref 
                    planText={["Data field values for project: "]}
                    parentsName={[project? `${project["ProjectName"]}` : ""]}
                    parents={[project]}
                />
            }
        /> 
        : <></>
    );
}

export default ProjectDataFieldValues;