import AppContext from 'context';
import { useContext, useEffect, useState, useRef, useMemo } from 'react';
import { useSelector } from 'react-redux';
import TemplateModule from '../TemplateModule';
import * as regexes from 'services/validatationRegexs';
import history from 'services/history';
import ParentHref from 'components/site/ParentHref';

const Contracts = (props) =>{
    const projectID = props.match.params.ProjectID;
    const [project, setProject] = useState(useSelector(state => state.projects.projects.filter(project => project.ProjectID.toString() === projectID)[0]));
    const user = useSelector(state => state.auths.user);
    const context = useRef(useContext(AppContext)).current;
    const data = useSelector(state => state.modules.selectedModuleData);
    const [contracts, setContracts] = useState([]);
    const [properties, setProperties] = useState({
        display: ["ContractName", "ContractDateStart", "ContractDateEnd", "Remark", "DateCreated"],
        dataRowViewerDisplay: ["UserName", "ContractName", "ContractDateStart", "Remark", "IsActive", "DateCreated"],
        dataRowViewerDisplayInvisible: ["ContractDateEnd"],
        notEditable:["UserName", "DateCreated"],
        primaryKey: ["ContractID"],
        defaultOnInsert:{
            ProjectID: projectID
        },
        readable: {
            removePrefix: [],
            ContractDateStart: "Start Date",
            ContractDateEnd: "End Date",
        },
        validation: {
            ContractDateStart: [regexes.NotEmpty],
            ContractDateEnd: [regexes.NotEmpty],
            ContractName: [regexes.NotEmpty, regexes.MaxLength100],
            UserName: [regexes.NotEmpty]
        },
        selectOptions: {},
        searchBy: ["ContractDateStart", "ContractDateEnd"],
        datePickerRange: ["ContractDateStart", "ContractDateEnd"],
        onInsertFunc: context.InsertNewContract,
        onUpdateFunc: context.UpdateContract,
        jumpTo: {
            passParam: ["ContractID"],
            replaceUrlPrefix: false,
            associatedModules: {
                "Payment Index Types": "ContractPaymentIndexTypes",
                "Payment Hierarchy Types": "ContractPaymentHierarchyTypes",
            }
        },
    })

    useMemo(() =>{
        if(!project){
            context.GetProjectByID(projectID).then(project =>{ project? setProject(project) : history.push('/') })
        }
    }, [project, context, projectID])
    
    useEffect(() =>{
        context.GetContracts(projectID).then(data =>{
            if(data){
                setContracts(data); 
            }
        });
    }, [context, projectID]);

    useEffect(() => {
        setProperties(state=>({
            ...state,
            defaultOnInsert: {
                ...state.defaultOnInsert,
                UserName: user.UserName
            },
            defaultOnUpdate: {
                ...state.defaultOnUpdate,
                UserName: user.UserName
            }
        }))
    }, [user.UserName]);

    useEffect(() =>{
        setProperties(state => ({
            ...state,
            isAddable: !!!data.find(contract => contract.IsActive === true)
        }));
    }, [data])

    return(
        !!project? 
        <TemplateModule 
            data={contracts} 
            properties={properties} 
            title={
                <ParentHref 
                    planText={["Contracts for project: "]}
                    parentsName={[project? `${project["ProjectName"]}` : ""]}
                    parents={[project]}
                />
            }
        /> 
        : <></>
    );
}

export default Contracts;