import CenteredDiv from 'components/site/CenteredDiv';
import styled from 'styled-components';



const ToBeContinued = () => {

    return(
        <CenteredDiv>
            <Container >
                Coming Soon...
            </Container>
        </CenteredDiv>
    );
}

const Container = styled.div`
    width: 350px;
    height: 350px;
    border: 1px solid ${props => props.theme.colors.purple};
    display: flex; 
    justify-content: center;
    align-items: center;
`;



export default ToBeContinued;