import styled from 'styled-components';
import PropTypes from 'prop-types';

const CenteredDiv = styled.div`
    position: fixed;
    display: flex;
    flex-direction: ${props => props.direction};
    top: 50%;
    left: 50%;
    z-index: ${props => props.zindex};
    transform: translate(-50%, -50%);
    align-items: center;
    justify-content: center;
`;

CenteredDiv.propTypes = {
    zindex: PropTypes.number
}

CenteredDiv.defaultProps = {
    zindex: 49
}

export default CenteredDiv;