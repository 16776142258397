import AppContext from 'context';
import { useContext, useEffect, useState, useRef, useMemo } from 'react';
import TemplateModule from '../TemplateModule';
import * as regexes from 'services/validatationRegexs';
import history from 'services/history';
import { useDispatch, useSelector } from 'react-redux';
import ParentHref from 'components/site/ParentHref';

const ProjectItems = (props) => {
    const projectID = props.match.params.ProjectID;
    const user = useSelector(state => state.auths.user);
    const context = useRef(useContext(AppContext)).current;
    const [project, setProject] = useState(useSelector(state => state.projects.projects.filter(project => project.ProjectID.toString() === projectID)[0]));
    const [items, setItems] = useState([]);
    const dispatch = useDispatch();
    const [properties, setProperties] = useState({
        display: ["ItemTypeName", "ItemQuantity"],
        dataRowViewerDisplay: ["UserName", "ItemTypeName", "ItemQuantity", "IsActive"],
        notEditable: ["ProjectID", "UserName", "DateCreated"],
        primaryKey: ["ProjectID", "ItemTypeName"],
        defaultOnInsert: {
            ProjectID: projectID
        },
        readable: {
            removePrefix: [],
            ItemTypeName: "Item type",
            ItemQuantity: "Quantity",
        },
        validation: {
            ItemTypeName: [regexes.NotEmpty, regexes.Unique],
            ItemQuantity: [regexes.NotEmpty, regexes.PositiveNumber],
            UserName: [regexes.NotEmpty],
        },
        sortBy: ["ItemTypeName", "ItemQuantity"],
        searchBy: ["ItemTypeName"],
        selectOptions: {},
        onInsertFunc: context.InsertNewProjectItem,
        onUpdateFunc: context.UpdateProjectItem,
    })

    useMemo(() => {
        if (!project) {
            context.GetProjectByID(projectID).then(project => { project ? setProject(project) : history.push('/') })
        }
    }, [project, context, projectID])

    useEffect(() => {
        context.GetProjectItems(projectID).then(data => {
            if (data) {
                setItems(data);
            }
        });
        context.GetItemTypes().then(items => {
            setProperties(state => ({
                ...state,
                selectOptions: {
                    ...state.selectOptions,
                    ItemTypeName: items
                }
            }));
        });
    }, [context, dispatch, projectID]);

    useEffect(() => {
        setProperties(state => ({
            ...state,
            defaultOnInsert: {
                ...state.defaultOnInsert,
                UserName: user.UserName
            },
            defaultOnUpdate: {
                ...state.defaultOnUpdate,
                UserName: user.UserName
            }
        }))
    }, [user.UserName])

    return (
        project ?
            <TemplateModule
                data={items}
                properties={properties}
                title={
                    <ParentHref
                        planText={["Items for project: "]}
                        parentsName={[project ? `${project["ProjectName"]}` : ""]}
                        parents={[project]}
                    />
                }
            />
            : <></>
    );
}

export default ProjectItems;