import AppContext from 'context';
import { useContext, useEffect, useState, useRef } from 'react';
import TemplateModule from '../TemplateModule';
import * as regexes from 'services/validatationRegexs';

const DocumentSupportedFormatTypes = () => {
    const context = useRef(useContext(AppContext)).current;
    const [formats, setFormats] = useState([]);
    const [properties] = useState({
        display: ["FormatTypeName", "FormatTypeDescription"],
        dataRowViewerDisplay: ["FormatTypeName", "FormatTypeDescription", "IsActive"],
        notEditable:["FormatTypeID", "FormatTypeName"],
        primaryKey: ["FormatTypeID"],
        readable: {
            removePrefix:[],
            FormatTypeName: "Format Type",
            FormatTypeDescription: "Description"
        },
        validation: {
            FormatTypeName: [regexes.Unique, regexes.NotEmpty, regexes.MaxLength20],
            FormatTypeDescription: [regexes.MaxLength150],
        },
        sortBy: ["FormatTypeName"],
        searchBy: ["FormatTypeName"],
        onInsertFunc: context.InsertNewFormatType,
        onUpdateFunc: context.UpdateFormatType,
        isAddable: false,
    });
    
    useEffect(() =>{
        context.GetFormatTypes().then(data =>{
            setFormats(data); 
        });
    }, [context]);

    return(
        <TemplateModule data={formats} properties={properties}/>
    ); 
}

export default DocumentSupportedFormatTypes;