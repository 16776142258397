import AppContext from 'context';
import { useContext, useEffect, useState, useRef } from 'react';
import TemplateModule from '../TemplateModule';
import * as regexes from 'services/validatationRegexs';

const TechnologyTypes = () => {
    const context = useRef(useContext(AppContext)).current;
    const [technologies, setTechnologies] = useState([]);
    const [properties] = useState({
        display: ["TechnologyTypeName", "TechnologyTypeDescription"],
        notEditable:["TechnologyTypeID"],
        primaryKey: ["TechnologyTypeID"],
        readable: {
            removePrefix:[],
            TechnologyTypeName: "Technology Name", 
            TechnologyTypeDescription: "Description"
        },
        validation: {
            TechnologyTypeName: [regexes.Unique, regexes.NotEmpty]
        },
        sortBy: ["TechnologyTypeName"],
        searchBy: ["TechnologyTypeName"],
        onInsertFunc: context.InsertNewTechnologyType,
        onUpdateFunc: context.UpdateTechnologyType
    })
    
    useEffect(() =>{
        context.GetTechnologyTypes().then(data =>{
            setTechnologies(data); 
        });
    }, [context]);

    return(
        <TemplateModule data={technologies} properties={properties}/>
    );
}

export default TechnologyTypes;