import AppContext from 'context';
import { useContext, useEffect, useState, useRef } from 'react';
import TemplateModule from '../TemplateModule';
import * as regexes from 'services/validatationRegexs';

const PaymentIndexTypes = () => {
    const context = useRef(useContext(AppContext)).current;
    const [units, setUnits] = useState([]);
    const [properties] = useState({
        display: ["PaymentIndexTypeName", "PaymentIndexTypeDescription"],
        dataRowViewerDisplay: ["PaymentIndexTypeName", "PaymentIndexTypeDescription", "IsActive"],
        notEditable:["PaymentIndexTypeID"],
        primaryKey: ["PaymentIndexTypeID"],
        readable: {
            removePrefix:[],
            PaymentIndexTypeName: "Payment Index Type",
            PaymentIndexTypeDescription: "Description"
        },
        validation: {
            PaymentIndexTypeName: [regexes.Unique, regexes.NotEmpty, regexes.MaxLength100],
            PaymentIndexTypeDescription: [regexes.MaxLength250],
        },
        sortBy: ["PaymentIndexTypeName"],
        searchBy: ["PaymentIndexTypeName"],
        onInsertFunc: context.InsertNewPaymentIndexType,
        onUpdateFunc: context.UpdatePaymentIndexType
    })
    
    useEffect(() =>{
        context.GetPaymentIndexTypes().then(data =>{
            setUnits(data); 
        });
    }, [context]);

    return(
        <TemplateModule data={units} properties={properties}/>
    );
}

export default PaymentIndexTypes;