import AppContext from 'context';
import { useContext, useEffect, useState, useRef, useMemo } from 'react';
import TemplateModule from '../TemplateModule';
import * as regexes from 'services/validatationRegexs';
import history from 'services/history';
import { useDispatch, useSelector } from 'react-redux';
import ParentHref from 'components/site/ParentHref';
import moment from 'moment';

const ProjectIncomes = (props) => {
    const projectID = props.match.params.ProjectID;
    const incomeGenerationID = props.match.params.IncomeGenerationID;
    const user = useSelector(state => state.auths.user);
    const projectsModuleUrl = useSelector(state => state.modules.modules.find(module => module.ModuleName === "Projects").ModuleUrl);
    const { systemParams } = useSelector(state => state.system);
    const context = useRef(useContext(AppContext)).current;
    const [project, setProject] = useState(useSelector(state => state.projects.projects.filter(project => project.ProjectID.toString() === projectID)[0]));
    const [incomes, setIncomes] = useState([]);
    const dispatch = useDispatch();
    const [properties, setProperties] = useState({
        display: ["ProjectActivityName", "IncomeGenerationID", "UserName", "PaymentHierarchyTypeName", "IncomeTotal", "CurrencySymbol", "IncomeLevel", "IncomePlannedName", "IncomeDate"],
        dataRowViewerDisplay: ["UserName", "IncomeGenerationID", "ProjectActivityName", "PaymentHierarchyTypeName", "IncomeTotal", "CurrencySymbol", "IncomeLevel", "IncomeDate", "IncomePlannedName", "Remark", "IsActive"],
        notEditable: ["ProjectID", "IncomeGenerationID", "UserName", "DateCreated"],
        primaryKey: ["ProjectIncomeID"],
        defaultOnInsert: {
            ProjectID: projectID,
            IncomeLevel: "0"
        },
        readable: {
            removePrefix: [],
            ProjectActivityName: "Activity",
            IncomeTotal: "Total Income",
            CurrencySymbol: "Currency",
            IncomePlannedName: "Planned Income",
            PaymentHierarchyTypeName: "Payment Hierarchy",
            IncomeGenerationID: "Generation ID"
        },
        validation: {
            PaymentHierarchyTypeName: [regexes.NotEmpty],
            UserName: [regexes.NotEmpty],
            IncomeTotal: [regexes.NotEmpty, regexes.PositiveFloat],
            CurrencySymbol: [regexes.NotEmpty],
            IncomeLevel: [regexes.NotEmpty, regexes.ZeroOrPositiveNumber],
            IncomeDate: [regexes.NotEmpty]
        },
        resetOnChange: {
            IncomeLevel: ["IncomePlannedName"]
        },
        isSelectLocked: {
            IncomePlannedName: (updatedDataRow => updatedDataRow["IncomeLevel"] > 0)
        },
        sortBy: ["ProjectActivityName", "IncomeGenerationID"],
        searchBy: ["ProjectActivityName", "IncomeDate", "IncomeLevel", "IncomeGenerationID"],
        datePickerSingle: ["IncomeDate"],
        selectOptions: {},
        isExportable: true,
        onInsertFunc: context.InsertNewProjectIncome,
        onUpdateFunc: context.UpdateProjectIncome,
    })

    useMemo(() => {
        if (!project) {
            context.GetProjectByID(projectID).then(project => { project ? setProject(project) : history.push('/') })
        }
    }, [project, context, projectID])

    useEffect(() => {
        setProperties(state => ({
            ...state,
            onInsertOrUpdateSucceeded: [
                (newDataRow) => {
                    const relevantIncome = incomes.filter(income => income["ProjectIncomeID"] === newDataRow["ProjectIncomeID"]);
                    const isNewRow = !!!relevantIncome.length;
                    const isPlannedIncomeChanged = !isNewRow && newDataRow["IncomePlannedName"] !== relevantIncome["IncomePlannedName"];
                    if (isNewRow || isPlannedIncomeChanged) {
                        context.GetProjectPlannedIncomes(projectID).then(incomes => {
                            setProperties(state => ({
                                ...state,
                                selectOptions: {
                                    ...state.selectOptions,
                                    IncomePlannedName: incomes || [],
                                }
                            }));
                        });
                    }
                }
            ]
        }))
    }, [incomes, context, projectID])

    useEffect(() => {
        context.GetProjectIncomes(projectID).then(data => {
            if (data) {
                const relevantGenerationID = props.location.state?.filterParams?.find(param => [param === "incomeGenerationID"]);
                setIncomes(
                    relevantGenerationID
                        ? data.filter(dataRow => {
                            return dataRow.IncomeGenerationID === relevantGenerationID.IncomeGenerationID
                        })
                        : data
                );
            }
        });
        context.GetCurrencies().then(currencies => {
            setProperties(state => ({
                ...state,
                selectOptions: {
                    ...state.selectOptions,
                    CurrencySymbol: currencies
                },
                defaultOnInsert: {
                    ...state.defaultOnInsert,
                    CurrencySymbol: currencies.find(currency => currency.CurrencyID.toString() === systemParams.DefaultCurrencyIDInDB?.ParamValue)?.CurrencySymbol
                }
            }));
        });
        context.GetProjectActivities(projectID).then(activities => {
            setProperties(state => ({
                ...state,
                selectOptions: {
                    ...state.selectOptions,
                    ProjectActivityName: activities ? activities.map(activity => {
                        return {
                            ...activity,
                            ProjectActivityName: activity.ActivityTypeName + ' - ' + moment(activity.ProjectActivityDate).format('DD/MM/YY')
                        }
                    }) : []
                }
            }));
        });
        context.GetPaymentHierarchyTypes().then(htypes => {
            setProperties(state => ({
                ...state,
                selectOptions: {
                    ...state.selectOptions,
                    PaymentHierarchyTypeName: htypes ?? []
                }
            }));
        });
        context.GetProjectPlannedIncomes(projectID).then(plannedIncomes => {
            setProperties(state => ({
                ...state,
                selectOptions: {
                    ...state.selectOptions,
                    IncomePlannedName: plannedIncomes ?? []
                }
            }));
        })
    }, [context, dispatch, projectID, incomeGenerationID, systemParams.DefaultCurrencyIDInDB]);

    useEffect(() => {
        setProperties(state => ({
            ...state,
            defaultOnInsert: {
                ...state.defaultOnInsert,
                UserName: user.UserName
            },
            defaultOnUpdate: {
                ...state.defaultOnUpdate,
                UserName: user.UserName
            }
        }))
    }, [user.UserName])

    return (
        project ?
            <TemplateModule
                data={incomes}
                properties={properties}
                title={
                    <ParentHref
                        planText={["Incomes for project: "]}
                        parentsName={[project ? `${project["ProjectName"]}` : ""]}
                        parents={[project]}
                        backUrls={[`${projectsModuleUrl}`]}
                    />
                }
            />
            : <></>
    );
}

export default ProjectIncomes;