
import * as actions from "actions/actionTypes";

export const setDefaultSearch = (searchByDefault) => {
    return {
        type: actions.SEARCH_ITEM_SELECTED,
        payload: {
            searchByDefault,
        }
    }
}

export const setShowOnlyUnactive = (showUnactive) => {
    return {
        type: actions.SEARCH_UNACTIVE_TOGGLED,
        payload: {
            showUnactive
        }
    }
}