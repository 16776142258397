import AppContext from 'context';
import { useContext, useEffect, useState, useRef } from 'react';
import TemplateModule from '../TemplateModule';
import * as regexes from 'services/validatationRegexs';

const ItemTypes = () => {
    const context = useRef(useContext(AppContext)).current;
    const [units, setUnits] = useState([]);
    const [properties] = useState({
        display: ["ItemTypeName", "ItemTypeDescription", "ItemTypeCatalogNumber"],
        dataRowViewerDisplay: ["ItemTypeName", "ItemTypeDescription", "ItemTypeCatalogNumber", "IsActive"],
        notEditable:["ItemTypeID"],
        primaryKey: ["ItemTypeID"],
        readable: {
            removePrefix:[],
            ItemTypeName: "Item Name",
            ItemTypeDescription: "Description",
            ItemTypeCatalogNumber: "Catalog Number"
        },
        validation: {
            ItemTypeName: [regexes.Unique, regexes.NotEmpty, regexes.MaxLength100],
            ItemTypeDescription: [regexes.MaxLength250],
            ItemTypeCatalogNumber: [regexes.MaxLength100],
        },
        sortBy: ["ItemTypeName", "ItemTypeCatalogNumber"],
        searchBy: ["ItemTypeName", "ItemTypeCatalogNumber"],
        onInsertFunc: context.InsertNewItemType,
        onUpdateFunc: context.UpdateItemType
    })
    
    useEffect(() =>{
        context.GetItemTypes().then(data =>{
            setUnits(data); 
        });
    }, [context]);

    return(
        <TemplateModule data={units} properties={properties}/>
    );
}

export default ItemTypes;