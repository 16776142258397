import AppContext from 'context';
import { useContext, useEffect, useState, useRef, useMemo } from 'react';
import { useSelector } from 'react-redux';
import TemplateModule from '../TemplateModule';
import * as regexes from 'services/validatationRegexs';
import history from 'services/history';
import ParentHref from 'components/site/ParentHref';

const ProjectActivities = props => {
	const projectID = props.match.params.ProjectID;
	const context = useRef(useContext(AppContext)).current;
	const { systemParams } = useSelector(state => state.system);
	const [project, setProject] = useState(
		useSelector(state => state.projects.projects.filter(project => project.ProjectID.toString() === projectID)[0])
	);
	const user = useSelector(state => state.auths.user);
	const [activities, setActivities] = useState([]);
	const [properties, setProperties] = useState({
		display: [
			'ActivityTypeName',
			'ProjectActivityLevel',
			'ProjectActivityPlannedActivityTypeName',
			'ProjectActivityFreeValue',
			'ProjectActivityDate',
			'ProjectActivityWorkHrs',
			'ProjectActivityIsPaidByCustomer',
			'ProjectActivityPricing',
			'ProjectActivityDescription',
		],
		dataRowViewerDisplay: [
			'UserName',
			'ActivityTypeName',
			'ProjectActivityLevel',
			'ProjectActivityDate',
			'ProjectActivityCost',
			'ProjectActivityIsPaidByCustomer',
			'ProjectActivityPricing',
			'ProjectActivityCurrencySymbol',
			'ProjectActivityWorkHrs',
			'ProjectActivityFreeValue',
			'ProjectActivityPlannedActivityTypeName',
			'ProjectActivityDescription',
			'ProjectWasDisabled',
			'ProjectDisabledHrs',
			'IsActive',
			'DateCreated',
		],
		dataRowViewerDisplayInvisible: ['ProjectID', 'ProjectActivityID'],
		notEditable: ['ProjectID', 'UserName', 'ProjectActivityID', 'DateCreated'],
		primaryKey: ['ProjectActivityID'],
		defaultOnInsert: {
			ProjectID: projectID,
			ProjectActivityLevel: '0',
			ProjectActivityIsPaidByCustomer: false,
		},
		readable: {
			removePrefix: ['ProjectActivity'],
			ProjectActivityID: 'Activity ID',
			ActivityTypeName: 'Activity type',
			ProjectActivityLevel: 'Activity level',
			ProjectActivityPlannedActivityTypeName: 'Planned Activity',
			ProjectActivityDate: 'Linking Date',
		},
		validation: {
			ActivityTypeName: [regexes.NotEmpty],
			ProjectActivityLevel: [regexes.NotEmpty, regexes.Number],
			ProjectActivityDate: [regexes.NotEmpty],
			ProjectActivityCost: [regexes.PositiveFloat],
			ProjectActivityPricing: [regexes.PositiveFloat],
			ProjectActivityWorkHrs: [regexes.PositiveFloat],
			ProjectDisabledHrs: [regexes.PositiveFloat],
			UserName: [regexes.NotEmpty],
		},
		datePickerSingle: ['ProjectActivityDate'],
		inputDependency: {
			ProjectActivityIsPaidByCustomer: updatedDataRow => {
				const isPaidByCustomerChecked = updatedDataRow['ProjectActivityIsPaidByCustomer'];
				setProperties(state => ({
					...state,
					validation: {
						...state.validation,
						ProjectActivityPricing: isPaidByCustomerChecked ? [regexes.Float, regexes.NotEmpty] : [regexes.FloatOrEmpty],
						ProjectActivityCurrencySymbol: isPaidByCustomerChecked ? [regexes.NotEmpty] : [],
					},
				}));
			},
			ActivityTypeName: async updatedDataRow => {
				const sysWaterMeterReadingID = systemParams['WaterMeterReadingActivityTypeIDInDB'].ParamValue;
				const waterMeterReadingActivity = await context.GetActivityTypeByID(sysWaterMeterReadingID);
				const isWaterMeterReading = updatedDataRow.ActivityTypeName === waterMeterReadingActivity.ActivityTypeName;
				setProperties(state => ({
					...state,
					validation: {
						...state.validation,
						ProjectActivityFreeValue: isWaterMeterReading ? [regexes.Float, regexes.NotEmpty] : [],
					},
				}));
			},
		},
		sortBy: ['ActivityTypeName', 'ProjectActivityLevel', 'ProjectActivityCost', 'ProjectActivityPricing'],
		searchBy: ['ActivityTypeName', 'UserName'],
		selectOptions: {},
		resetOnChange: {
			ProjectActivityLevel: ['ProjectActivityPlannedActivityTypeName'],
		},
		isSelectLocked: {
			ProjectActivityPlannedActivityTypeName: updatedDataRow => updatedDataRow['ProjectActivityLevel'] > 0,
		},
		jumpTo: {
			passParam: ['ProjectActivityID'],
			replaceUrlPrefix: false,
			associatedModules: {
				'Activity Items': 'ProjectItemsPerActivity',
				'Lab Tests': 'ActivityLabTests',
			},
		},
		onInsertFunc: context.InsertNewProjectActivity,
		onUpdateFunc: context.UpdateProjectActivity,
	});

	useMemo(() => {
		if (!project) {
			context.GetProjectByID(projectID).then(project => {
				project ? setProject(project) : history.push('/');
			});
		}
	}, [project, context, projectID]);

	useEffect(() => {
		setProperties(state => ({
			...state,
			onInsertOrUpdateSucceeded: [
				newDataRow => {
					const relevantActivity = activities.filter(activity => activity['ProjectActivityID'] === newDataRow['ProjectActivityID']);
					const isNewRow = !!!relevantActivity.length;
					const isPlannedActivityChanged =
						!isNewRow &&
						newDataRow['ProjectActivityPlannedActivityTypeName'] !== relevantActivity['ProjectActivityPlannedActivityTypeName'];
					if (isNewRow || isPlannedActivityChanged) {
						context.GetProjectPlannedActivities(projectID).then(activities => {
							setProperties(state => ({
								...state,
								selectOptions: {
									...state.selectOptions,
									ProjectActivityPlannedActivityTypeName: activities || [],
								},
							}));
						});
					}
				},
			],
		}));
	}, [activities, context, projectID]);

	useEffect(() => {
		context.GetProjectActivities(projectID).then(data => {
			if (data) {
				setActivities(data);
			}
		});
		context.GetActivities().then(activities => {
			setProperties(state => ({
				...state,
				selectOptions: {
					...state.selectOptions,
					ActivityTypeName: activities,
				},
			}));
		});
		context.GetProjectPlannedActivities(projectID).then(activities => {
			setProperties(state => ({
				...state,
				selectOptions: {
					...state.selectOptions,
					ProjectActivityPlannedActivityTypeName: activities || [],
				},
			}));
		});
		context.GetCurrencies().then(currencies => {
			setProperties(state => ({
				...state,
				selectOptions: {
					...state.selectOptions,
					ProjectActivityCurrencySymbol: {
						CurrencySymbol: currencies,
					},
				},
				defaultOnInsert: {
					...state.defaultOnInsert,
					ProjectActivityCurrencySymbol: currencies.find(
						currency => currency.CurrencyID.toString() === systemParams.DefaultCurrencyIDInDB.ParamValue
					)?.CurrencySymbol,
				},
			}));
		});
	}, [context, projectID, systemParams.DefaultCurrencyIDInDB]);

	useEffect(() => {
		setProperties(state => ({
			...state,
			defaultOnInsert: {
				...state.defaultOnInsert,
				UserName: user.UserName,
			},
			defaultOnUpdate: {
				...state.defaultOnUpdate,
				UserName: user.UserName,
			},
		}));
	}, [user.UserName]);

	return !!project ? (
		<TemplateModule
			data={activities}
			properties={properties}
			title={
				<ParentHref
					planText={['Activities for project: ']}
					parentsName={[project ? `${project['ProjectName']}` : '']}
					parents={[project]}
				/>
			}
		/>
	) : (
		<></>
	);
};

export default ProjectActivities;
