import AppContext from 'context';
import { useContext, useEffect, useRef, useState } from 'react';
import TemplateModule from '../TemplateModule';
import * as regexes from 'services/validatationRegexs';

const Currencies = () => {
    const context = useRef(useContext(AppContext)).current;
    const [currencies, setCurrencies] = useState([]);
    const [properties] = useState({
        display: ["CurrencyName", "CurrencyCode", "CurrencySymbol"],
        dataRowViewerDisplay: ["CurrencyName", "CurrencyCode", "CurrencySymbol", "IsActive"],
        notEditable: ["CurrencyID"],
        primaryKey: ["CurrencyID"],
        readable: {
            removePrefix:[],
            CurrencyCode: "Code",
            CurrencyName: "Currency Name",
            CurrencySymbol: "Symbol"
        },
        validation: {
            CurrencyCode: [regexes.Unique, regexes.NotEmpty, regexes.MaxLength3],
            CurrencyName: [regexes.Unique, regexes.NotEmpty, regexes.MaxLength50],
            CurrencySymbol: [regexes.Unique, regexes.NotEmpty, regexes.MaxLength3]
        },
        sortBy: ["CurrencyName", "CurrencyCode"],
        searchBy: ["CurrencyName", "CurrencyCode"],
        onInsertFunc: context.InsertNewCurrency,
        onUpdateFunc: context.UpdateCurrency
    })
    
    useEffect(() =>{
        context.GetCurrencies().then(data =>{
            setCurrencies(data); 
        });
    }, [context]);

    return(
        <TemplateModule data={currencies} properties={properties}/>
    );
}

export default Currencies;