import AppContext from 'context';
import { useContext, useEffect, useState, useRef, useMemo } from 'react';
import TemplateModule from '../TemplateModule';
import * as regexes from 'services/validatationRegexs';
import history from 'services/history';
import { useDispatch, useSelector } from 'react-redux';
import ParentHref from 'components/site/ParentHref';

const ProjectTechnologies = (props) =>{
    const projectID = props.match.params.ProjectID;
    const user = useSelector(state => state.auths.user);
    const context = useRef(useContext(AppContext)).current;
    const [project, setProject] = useState(useSelector(state => state.projects.projects.filter(project => project.ProjectID.toString() === projectID)[0]));
    const [technologies, setTechnologies] = useState([]);
    const dispatch = useDispatch();
    const [properties, setProperties] = useState({
        display: ["TechnologyTypeName", "Remark"],
        dataRowViewerDisplay: ["UserName", "TechnologyTypeName", "Remark", "IsActive", "DateCreated"],
        notEditable:["ProjectID", "TechnologyTypeName", "UserName", "DateCreated"],
        primaryKey: ["ProjectID", "TechnologyTypeName"],
        defaultOnInsert:{
            ProjectID: projectID
        },
        readable: {
            removePrefix:[],
            TechnologyTypeName: "Technology Type",
        },
        validation: {
            TechnologyTypeName: [regexes.NotEmpty, regexes.Unique],
        },
        sortBy: ["TechnologyTypeName"],
        searchBy: ["TechnologyTypeName"],
        selectOptions: {},
        onInsertFunc: context.InsertNewProjectTechnology,
        onUpdateFunc: context.UpdateProjectTechnology,
    })

    useMemo(() =>{
        if(!project){
            context.GetProjectByID(projectID).then(project =>{ project? setProject(project) : history.push('/') })
        }
    }, [project, context, projectID])
    
    useEffect(() =>{
        context.GetProjectTechnologies(projectID).then(data =>{
            if(data){
                setTechnologies(data); 
            }
        });
        context.GetTechnologyTypes().then(technologies =>{
            setProperties(state => ({
                ...state,
                selectOptions:{
                    ...state.selectOptions,
                    TechnologyTypeName: technologies
                }
            }));
        });
    }, [context, dispatch, projectID]);

    useEffect(() => {
        setProperties(state=>({
            ...state,
            defaultOnInsert: {
                ...state.defaultOnInsert,
                UserName: user.UserName
            },
            defaultOnUpdate: {
                ...state.defaultOnUpdate,
                UserName: user.UserName
            }
        }))
    }, [user.UserName])

    return(
        project? 
        <TemplateModule 
            data={technologies} 
            properties={properties} 
            title={
                <ParentHref 
                    planText={["Technologies for project: "]} 
                    parentsName={[project? `${project["ProjectName"]}` : ""]}
                    parents={[project]}
                />
            }
        /> 
        : <></>
    );
}

export default ProjectTechnologies;