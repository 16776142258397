import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from 'reducers/rootReducer';

export const emptyUser = {
    Fname: null,
    Lname: null,
    ReturnUrl: null,
    RoleID: 0,
    RoleName: null,
    UserID: 0,
    UserName: null,
    AuthorizeGuid: null,
};

export const emptyModule = {
    selectedModule: {},
    selectedModuleData: [],
    properties: {
        display: [],
        dataRowViewerDisplay: ['*'], //or list of keys
        dataRowViewerDisplayInvisible: [],
        resetOnChange: {},
        notEditable: [],
        defaultOnInsert: {}, //keep specific cols when inserting a new item
        defaultOnUpdate: {},
        dynamicValueCallback: {},
        primaryKey: [],
        readable: {
            removePrefix: []
        },
        validation: {},
        mandatoryOneOf: [],
        sortBy: [],
        searchBy: [],
        jumpTo: {
            passParam: [],
            replaceUrlPrefix: false,
            associatedModules: {},
        },
        selectOptions: {},
        isSelectLocked: {},
        multiSelectOptions: {},
        searchBarOverride: {},
        selectFile: {},
        datePickerRange: [],
        datePickerSingle: [],
        isAddable: true,
        isEditable: true,
        isDeleteable: true,
        isExportable: false,
        inputDependency: {},
        onInsertFunc: (() => { }),
        onUpdateFunc: (() => { }),
        onEditClick: (() => { }),
        onAddClick: null, //function
        onDeleteMsg: null,
        onInsertOrUpdateSucceeded: [],
        onSearchClick: null, //function
        onSearchClearClick: null, //function
        onDocumentDownloadClick: null, //function
        onDocumentOpenClick: null, //function
        smallDateFormatsKeys: [],
        filterParams: []//data filter params
    },
}

const initialState = {
    auths: {
        user: {
            ...emptyUser
        }
    },
    modules: {
        modules: [],
        permissions: [],
        selectedDataRow: {},
        ...emptyModule
    },
    tabs: {
        tabs: [],
        selectedTab: {
            MenuTabID: 0,
            top: null
        }
    },
    searchs: {
        searchByDefault: {},
        showUnactive: false,
    },
    projects: {
        projects: [],
    },
    system: {
        systemParams: {},
    }
};

const composeEnhancer = (process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null) || compose;
const store = createStore(
    rootReducer,
    initialState,
    composeEnhancer(applyMiddleware(thunk))
);

export default store;