import { combineReducers } from 'redux';
import authReducer from './authReducer';
import tabReducer from './tabReducer';
import moduleReducer from './moduleReducer';
import searchReducer from './searchReducer';
import projectReducer from './projectReducer';
import systemReducer from './systemReducer';


const rootReducer = combineReducers({
    auths: authReducer,
    tabs: tabReducer,
    modules: moduleReducer,
    searchs: searchReducer,
    projects: projectReducer,
    system: systemReducer
});

export default rootReducer;