import React, { useContext, useState } from 'react';
import CenteredDiv from 'components/site/CenteredDiv';
import Input from 'components/form/Input';
import styled from 'styled-components';
import odisLogo from 'media/images/odisAssetsLogo.png';
import ReCAPTCHA from 'react-google-recaptcha';
import Button from 'components/form/Button';
import { useDispatch } from 'react-redux';
import { LoginUser } from 'actions/authActions';
import history from 'services/history';
import AppContext from 'context';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Login = () =>{
    const [passwordShown, setPasswordShown] = useState(false);
    const [token, setToken] = useState("");
    const [error, setError] = useState("");
    const passRef = React.createRef();
    const usernameRef = React.createRef();
    const reCaptchaRef = React.useRef();
    const dispatch = useDispatch();
    const { UserLogin } = useContext(AppContext);
    
    const togglePassword = () =>{
        setPasswordShown(!passwordShown);
        passRef.current.focus();
    };
    
    const onSubmit = async() =>{
        let username = usernameRef.current.value;
        let password = passRef.current.value;
        if(!username || !password){
            setError("Please fill username and password");
            return;
        }
        if(!token){
            setError("Please verify reCAPTCHA");
            return;
        }
        setError("");
        UserLogin(username, password, token).then(data => {
            if(data){
                const user = data;
                if(user.AuthorizeGuid){
                    dispatch(LoginUser(user));
                    history.push('/');
                }
                else
                    setError(user.errorMessage);
            }
            else{
                setError("System error - please contact your system administrator");
            }
        }).catch(err=>{
            setError("System error - please contact your system administrator");
        });
    }

    const handleKeyPress = (e) =>{
        if(e.key === 'Enter'){
            if(usernameRef.current.value && passRef.current.value)
                onSubmit.call();
            else if(usernameRef.current.value)
                passRef.current.focus();
        }
    }

    return(
        <CenteredDiv direction="column">
            <OdisLogo src={odisLogo}/>
            <SignInSentence>Sign in to start your session</SignInSentence>
            <FormDiv>
                <Input 
                    type="text" 
                    name="username"
                    placeholder="Username" 
                    ref={usernameRef} 
                    onKeyPress={(e) => handleKeyPress(e)} 
                    autocomplete="username"
                    marginTop={15}
                />
                <PassDiv>
                    <Input 
                        type={passwordShown? "text":"password"}
                        name="password"
                        placeholder="Password" 
                        ref={passRef} 
                        onKeyPress={(e) => handleKeyPress(e)} 
                        autocomplete="current-password"
                        marginTop={15}
                    />
                    <EyeIcon icon={faEye} onMouseDown={togglePassword} onMouseUp={togglePassword} />
                </PassDiv>
                <RCDiv>
                    <ReCAPTCHA
                        ref={reCaptchaRef}
                        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                        onChange={token => setToken(token)}
                        onExpired={() => setToken("")}
                    />
                </RCDiv>
                <ErrorDiv>{error}</ErrorDiv>
                <BtnDiv>
                    <Button purple onClick={() => onSubmit()}>Login</Button>
                </BtnDiv>
            </FormDiv>
        </CenteredDiv>
    );
}

const OdisLogo = styled.img`
    width: 500px;
    height: auto;
`;

const SignInSentence = styled.span`
    color: ${props => props.theme.colors.darkGrey};
    text-align: center;
    margin: 50px 0px 10px 0;
    font-weight: bold;
`;

// const EyeIcon = styled.img.attrs({
//     src: showPassword
// })`
const EyeIcon = styled(FontAwesomeIcon)`
    /* width: 20px; */
    font-size: 15px;
    position: absolute;
    color: ${props => props.theme.colors.darkGrey};
    margin-top: 30px;
    margin-left: -37px;
    cursor: pointer;
`;

const PassDiv = styled.div`
    position: relative;
    width: -webkit-fill-available;
`;

const RCDiv = styled.div`
    display: flex;
    justify-content: center;
    transform: scale(0.7);
    align-items: center;
    /* margin: 20px 0 40px 0; */
    margin-top: 20px;
`;

const BtnDiv = styled.div`
    align-self: flex-end;
`;

const FormDiv = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 300px;
`;

const ErrorDiv = styled.div`
    font-size: 14px;
    color: red;
    text-align: center;
    margin-bottom: 16px;
`;

export default Login;