import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useContext, useEffect, useState } from 'react';
import { updateDataRow } from 'actions/moduleActions';
import { setDefaultSearch, setShowOnlyUnactive } from 'actions/searchActions';
import { useDispatch, useSelector } from 'react-redux';
import { strTrimLower, fixColName, getFormatedDate } from 'services/helpFunctions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/form/Button';
import Input from 'components/form/Input';
import CheckBox from 'components/form/CheckBox';
import Select from 'components/form/Select';
import Dropzone from 'components/form/Dropzone';
import AppContext from 'context';
import DateRangePicker from 'components/form/DateRangePicker';
import SingleDatePicker from 'components/form/SingleDatePicker';
import moment from 'moment';
import MandatoryOneOf from 'components/form/MandatoryOneOf';

const DataRowViewer = ({ dataRow, closePopUp, isEdit, isInsert }) => {
    const data = useSelector(state => state.modules.selectedModuleData);
    const [edited, setEdited] = useState(false);
    const [isValidForm, setIsValidForm] = useState(false);
    const [updatedDataRow, setUpdatedDataRow] = useState({ ...dataRow });
    const {
        multiSelectOptions, selectOptions, validation, readable, dynamicValueCallback, isSelectLocked,
        onInsertOrUpdateSucceeded, selectFile, dataRowViewerDisplay, dataRowViewerDisplayInvisible, inputDependency,
        notEditable, primaryKey, onInsertFunc, onUpdateFunc, resetOnChange, datePickerRange, datePickerSingle, mandatoryOneOf, smallDateFormatsKeys,
    } = useSelector(state => state.modules.properties);
    const { setShowError } = useContext(AppContext);
    const dispatch = useDispatch();
    const [mandatory, setMandatory] = useState([]);
    const [errors, setErrors] = useState([]);

    useEffect(() => {
        Object.keys(inputDependency).forEach(key => {
            inputDependency[key](dataRow);
        })
    }, [inputDependency, dataRow])

    useEffect(() => {
        setMandatory(Object.keys(validation).filter(key => !!validation[key].find(vali => vali.name === 'NotEmpty')))
    }, [validation])

    useEffect(() => {
        setErrors(
            Object.assign({},
                ...mandatory.filter(key => (updatedDataRow[key] === "")).map(key => {
                    return { [key]: "" }
                }),
                (!!!mandatoryOneOf.length || mandatoryOneOf.reduce((acc, val) => acc || updatedDataRow[val] !== "", false)) ? {} : { "mandOneOf": "" }
            )
        )
    }, [mandatory, mandatoryOneOf, isInsert, updatedDataRow])

    const validateInput = (inputElement, key, value) => {
        const curRegList = validation[key] || [];
        let borderColor = null;
        let isValid = true;
        const breakExecption = {};
        const originalVal = strTrimLower(dataRow[key]);
        const newVal = strTrimLower(value);
        try {
            curRegList.forEach(curReg => {
                const evalError =
                    (isInsert || originalVal !== newVal)
                    && (
                        !(curReg.regex).test(value) ||
                        (curReg.name === "Unique" && data.filter(curDataRow => strTrimLower(curDataRow[key]) === newVal).length > 0) ||
                        (curReg.name === "ExternalFunc" && !curReg.func(dataRow, updatedDataRow, value)
                        )
                    )
                let externalErrors = {}
                if (resetOnChange[key]) {
                    resetOnChange[key].forEach(resetKey => {
                        if (mandatory.includes(resetKey)) {
                            externalErrors = { ...externalErrors, [resetKey]: "" }
                        }
                    })
                }
                if (evalError) {
                    setErrors({ ...errors, [key]: curReg.error, ...externalErrors });
                    borderColor = "#DD0D0D";
                    throw breakExecption;
                }
                else {
                    let { [key]: omit, ...restErrors } = errors;
                    setErrors({ ...restErrors, ...externalErrors });
                }
            });
            if (mandatoryOneOf.includes(key)) {
                if (mandatoryOneOf.reduce((acc, mandKey) => (acc || (mandKey === key ? value !== "" : updatedDataRow[mandKey] !== "")), false)) {
                    let { mandOneOf: omit, ...restErrors } = errors;
                    setErrors(restErrors)
                }
                else {
                    setErrors({
                        mandOneOf: "Required at least one of [" + mandatoryOneOf.map(field => fixColName(field, readable)).join(' | ') + "]",
                        ...errors
                    })
                }
            }
        }
        catch (breakExecption) {
            isValid = false;
        };
        if (inputElement)
            inputElement.style.borderColor = borderColor;
        return isValid;
    }

    useEffect(() => {
        setIsValidForm(Object.keys(errors).length === 0);
    }, [errors])

    const handleInputChanged = (key, value, e) => {
        if (multiSelectOptions[key]) {
            value = value ? value.map(cur => cur.value).join(', ') : "";
        }
        else {
            if (!validateInput((e ? e.target : null), key, value)) return;
            switch (true) {
                case typeof (dataRow[key]) === 'boolean':
                    value = (value === true);
                    break;
                case typeof (dataRow[key]) === 'number':
                    value = parseFloat(value) || "";
                    break;
                case /datecreated/ig.test(key):
                    value = moment(value).format('DD/MM/YYYY');
                    break;
                case typeof (value) !== 'object':
                    value = value.toString();
                    break;
                default:
                    break;
            }
        }
        let newRow = { ...updatedDataRow, [key]: value };
        if (resetOnChange[key]) {
            resetOnChange[key].forEach(resetKey => {
                newRow = { ...newRow, [resetKey]: '' };
            })
        }
        if (inputDependency[key]) {
            inputDependency[key](newRow)
        }
        setUpdatedDataRow(newRow);
        setEdited(JSON.stringify(newRow) !== JSON.stringify(dataRow));
    }

    const getCurrectItem = (key) => {
        const isIcon = /icon$/ig.test(key);
        const isNumber = typeof dataRow[key] === 'number';
        const isDateRange = datePickerRange.includes(key) && datePickerRange[0] === key;
        const isDateSingle = datePickerSingle.includes(key);
        const isDate = !!isDateRange || !!isDateSingle || /datecreated/ig.test(key);
        const isDynamic = dynamicValueCallback[key];
        const isFile = !!selectFile[key];
        const isEditable = !notEditable.includes(key);


        let elem = (() => {
            if (isDate) {
                if (smallDateFormatsKeys.includes(key)) return getFormatedDate(updatedDataRow[key], 'MM/YYYY');
                return moment(updatedDataRow[key]).format('DD/MM/YYYY');
            }
            else if (isNumber) return updatedDataRow[key] + "";
            return updatedDataRow[key];
        })();


        //   let elem = isDate  //  --  omer's version 
        //       ? moment(updatedDataRow[key]).format('DD/MM/YYYY')
        //       : isNumber ? dataRow[key] + "" : dataRow[key];




        if (isDynamic) {
            elem = dynamicValueCallback[key](updatedDataRow);
            if (Object.keys(updatedDataRow).includes(key) && elem !== "" && updatedDataRow[key] !== elem) {
                handleInputChanged(key, elem);
            }
        }
        if ((isEdit || isInsert)) {
            if (typeof (dataRow[key]) === 'boolean') {
                const curID = `cbx${dataRow[primaryKey[0]] || ""}${key}`;
                elem = <CheckBox
                    cbxID={curID}
                    isChecked={elem}
                    onChange={handleInputChanged}
                    dataKey={key}
                />
            }
            else if (isFile) {
                elem = <Dropzone
                    handleDrop={fileSelected => {
                        return fileSelected ? handleInputChanged(key, fileSelected) : {}
                    }
                    }
                    accept={selectFile[key].accept}
                    maxSize={selectFile[key].maxSize}
                    isDisabled={isEdit}
                    placeholder={updatedDataRow[selectFile[key].placeholderCol] || ""}
                    handleError={error => (!errors[key] || error !== errors[key]) ? setErrors({ ...errors, [key]: error }) : {}}
                    handleKeyDown={handleKeyPress}
                />
            }
            else if (isDateRange) {
                const startDate = updatedDataRow[datePickerRange[0]];
                const endDate = updatedDataRow[datePickerRange[1]];
                elem = <DateRangePicker
                    startDate={startDate}
                    endDate={endDate}
                    onDatesChange={(startDate, endDate, focusedInput) => {
                        if (focusedInput === "startDate")
                            handleInputChanged(datePickerRange[0], startDate ?? "");
                        else
                            handleInputChanged(datePickerRange[1], endDate ?? "");
                    }}
                />
            }
            else if (isDateSingle) {
                const date = updatedDataRow[key];
                elem = <SingleDatePicker
                    id={`date_${key}`}
                    date={date ? moment(date) : null}
                    onDateChange={date => handleInputChanged(key, date ?? "")}
                    isDisabled={!isEditable}
                />
            }
            else if (selectOptions[key] || multiSelectOptions[key]) {
                let fixedOptions = [];
                let options = selectOptions[key] || multiSelectOptions[key];
                const correctKey = Object.keys(options)[0] === "0" ? key : Object.keys(options)[0]; //array or object
                options = options[correctKey] || options;
                const isMulti = multiSelectOptions[key];
                const isControlDisabled = (isEdit && !isEditable) || (!!isSelectLocked[key] ? isSelectLocked[key](updatedDataRow) : false);
                options.filter(option => !!option["IsActive"]).forEach(option => {
                    const valName = option[correctKey];
                    fixedOptions.push({
                        value: valName,
                        label: isIcon ? <FontAwesomeIcon icon={valName} /> : valName,
                        isDisabled: option["IsDisabled"] && (valName !== elem)
                    })
                });
                fixedOptions.sort((option1, option2) => {
                    const val1 = option1.value.toString().toLowerCase();
                    const val2 = option2.value.toString().toLowerCase();
                    return val1 > val2 ? 1 : ((val2 > val1) ? -1 : 0);
                })
                const defaultValue = isMulti
                    ? fixedOptions.filter(item => elem.split(', ').includes(item.value))
                    : fixedOptions.find(item => item.value === elem)
                elem = <Select
                    options={fixedOptions}
                    isDisabled={isControlDisabled}
                    isClearable
                    onChange={e => handleInputChanged(key, isMulti ? e : (e ? e.value : ""))}
                    closeMenuOnSelect={!isMulti}
                    isMulti={isMulti}
                    defaultValue={defaultValue}
                    width={266}
                    onKeyDown={handleKeyPress}
                />
            }
            else if (isEditable) {
                elem = <Input
                    defaultValue={elem ? elem.toString() : ""}
                    onChange={e => handleInputChanged(key, e.target.value, e)}
                    placeholder={''}
                    onKeyDown={handleKeyPress}
                />

            }
            return elem
        }
        else {
            switch (true) {
                case isIcon:
                    return <FontAwesomeIcon icon={elem} />;
                case isFile:
                    return dataRow[key].name || dataRow[selectFile[key].placeholderCol];
                case typeof (dataRow[key]) === 'boolean':
                    return <FontAwesomeIcon icon={elem ? "check" : "times"} />;
                default:
                    // return <TextBlock>{elem.split('\\n').map(line => <span>{line}</span> )}</TextBlock>;
                    return <TextBlock dangerouslySetInnerHTML={{ __html: elem }}></TextBlock>;
            }
        }
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && isValidForm) {
            Send();
        }
    }

    const Send = () => {
        if (isInsert) {
            onInsertFunc(updatedDataRow).then(res => {
                if (res > 0) {
                    const newDataRow = {
                        ...updatedDataRow,
                        [Object.keys(updatedDataRow).find(val => /ID$/i.test(val))]: res
                    }
                    dispatch(setDefaultSearch(newDataRow));
                    dispatch(setShowOnlyUnactive(!updatedDataRow.IsActive));
                    onInsertOrUpdateSucceeded.forEach(callback => callback(newDataRow));
                    closePopUp();
                }
                else {
                    setShowError(true);
                }
            }).catch(err => {
                setShowError(true);
            })
        }
        if (isEdit) {
            onUpdateFunc(updatedDataRow).then(res => {
                if (res === true) {
                    dispatch(updateDataRow(updatedDataRow));
                    dispatch(setDefaultSearch(updatedDataRow));
                    dispatch(setShowOnlyUnactive(!updatedDataRow.IsActive))
                    onInsertOrUpdateSucceeded.forEach(callback => callback(updatedDataRow));
                    closePopUp();
                }
                else {
                    setShowError(true);
                }
            }).catch(err => {
                setShowError(true);
            })
        }
    }

    return (
        <ViewerContainer>
            <Table >
                <tbody>
                    {
                        (dataRowViewerDisplay[0] === '*' ? Object.keys(dataRow) : dataRowViewerDisplay).map((key, index) => {
                            return (
                                <TR key={index} isInvisible={dataRowViewerDisplayInvisible.includes(key)}>
                                    <TDKey>
                                        {fixColName(key, readable)}
                                        {mandatory.includes(key) && (isEdit || isInsert) ? '*' : ''}
                                        {mandatoryOneOf.includes(key) && mandatoryOneOf.lastIndexOf(key) !== mandatoryOneOf.length - 1 && <MandatoryOneOf />}
                                        <Error>{errors[key]}</Error>
                                    </TDKey>
                                    <TDValue>
                                        {getCurrectItem(key)}
                                    </TDValue>
                                </TR>
                            )
                        })
                    }
                </tbody>
            </Table>
            {
                !!errors["mandOneOf"] &&
                <Error margin={"-20px 0 10px 0"}>{errors["mandOneOf"]}</Error>
            }
            {
                (isEdit || isInsert) ?
                    <ButtonRow>
                        <Required>* Required</Required>
                        <Button onClick={closePopUp} margin={'0 10px 0 0'}>Cancel</Button>
                        {
                            (edited && isValidForm)
                                ? <Button purple onClick={() => Send()} >Save</Button>
                                : <Button lightPurple>Save</Button>
                        }
                    </ButtonRow>
                    : <Button onClick={closePopUp} margin={'0 10px 0 auto'}>Close</Button>
            }

        </ViewerContainer>
    );
}

DataRowViewer.propTypes = {
    dataRow: PropTypes.object,
    closePopUp: PropTypes.func,
    isEdit: PropTypes.bool,
    isInsert: PropTypes.bool,
}

DataRowViewer.defaultProps = {
    dataRow: {},
    isEdit: false,
    isInsert: false
}

const ViewerContainer = styled.div`
    position: relative;
    width: fit-content;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    border: 2px solid ${props => props.theme.colors.purple};
    background: #FFFFFF;
    padding: 30px;
    align-items: center;
    margin-bottom: 30px;
`;

const Table = styled.table`
    border-collapse: collapse;
    margin-bottom: 15px;
`;

const TR = styled.tr`
    border: solid;
    border-width: 1px 0;
    display: ${props => props.isInvisible ? 'none' : 'table-row'};
    border-color: ${props => props.theme.colors.lightGrey};
    &:last-of-type{
        margin-bottom: 0;
        border-bottom: none;
    }
    &:first-of-type{
        border-top: none;
    }
`;

const TDKey = styled.td`
    position: relative;
    white-space: nowrap;
`;

const TDValue = styled.td`
    display: flex;
    font-size: 15px;
    align-items: center;
    justify-content: flex-start;
    font-weight: bold;
    padding-left: 50px;
    min-height: 55px;
    min-width: 280px;
    color: ${props => props.theme.colors.darkGrey};
`;

const Error = styled.div`
    font-size: 14px;
    color: ${props => props.theme.colors.error};
    margin: ${props => props.margin ? props.margin : "3px 0 0 0"};
`;

const ButtonRow = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
`;

const Required = styled.div`
    margin-right: auto;
    font-size: 14px;
    color: ${props => props.theme.colors.darkGrey};
`;

const TextBlock = styled.div`
    display: flex;
    flex-direction: column;
`;

export default DataRowViewer;