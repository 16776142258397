import AppContext from 'context';
import { useContext, useEffect, useState, useRef } from 'react';
import TemplateModule from '../TemplateModule';
import * as regexes from 'services/validatationRegexs';

const Roles = () => {
    const context = useRef(useContext(AppContext)).current;
    const [roles, setRoles] = useState([]);
    const [properties, setProperties] = useState({
        display: ["RoleName", "RoleDescription", "ModuleDisplayName"],
        dataRowViewerDisplay: ["RoleName", "RoleDescription", "ModuleDisplayName", "IsActive"],
        notEditable:["RoleID"],
        primaryKey: ["RoleID"],
        readable: {
            removePrefix:[],
            RoleName: "Role",
            RoleDescription: "Description",
            ModuleDisplayName: "Modules"
        },
        validation: {
            RoleName: [regexes.Unique, regexes.NotEmpty, regexes.MaxLength100],
            RoleDescription: [regexes.MaxLength250],
        },
        sortBy: ["RoleName"],
        searchBy: ["RoleName"],
        multiSelectOptions: {},
        onInsertFunc: context.InsertNewRole,
        onUpdateFunc: context.UpdateRole
    })
    
    useEffect(() =>{
        context.GetRoles().then(roles =>{
            setRoles([]);
            roles.forEach(role =>{
                context.GetModulesByRole(role.RoleID).then(modules =>{
                    modules = modules || [];
                    setRoles(state => [
                        ...state.filter(otherRole => otherRole.RoleName < role.RoleName),
                        Object.assign(role, {ModuleDisplayName: modules.map(module => module.ModuleDisplayName).join(', ')}),
                        ...state.filter(otherRole => otherRole.RoleName > role.RoleName),

                    ]);
                });
            });
        });
        context.GetModules().then(data=>{
            setProperties(state => ({
                ...state,
                multiSelectOptions:{
                    ...state.multiSelectOptions,
                    ModuleDisplayName: data
                }
            }))
        });
    }, [context]);

    return(
        <TemplateModule data={roles} properties={properties}/>
    );
}

export default Roles;