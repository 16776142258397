import AppContext from 'context';
import { useContext, useEffect, useState, useRef } from 'react';
import TemplateModule from '../TemplateModule';
import * as regexes from 'services/validatationRegexs';

const PaymentIndexTypeValues = () => {
	const context = useRef(useContext(AppContext)).current;
	const [paymentTypesValues, setPaymentTypesValues] = useState([]);
	const [properties, setProperties] = useState({
		display: ['PaymentIndexTypeName', 'PaymentIndexTypeVal', 'DateCreated', 'PaymentIndexTypeValueDate'],
		dataRowViewerDisplay: ['PaymentIndexTypeName', 'PaymentIndexTypeVal', 'PaymentIndexTypeValueDate', 'DateCreated', 'IsActive'],
		notEditable: ['RecordID', 'DateCreated'],
		primaryKey: ['RecordID'],
		readable: {
			removePrefix: [],
			PaymentIndexTypeName: 'Payment Type',
			PaymentIndexTypeVal: 'Value',
			PaymentIndexTypeValueDate: 'Linking Date',
			DateCreated: 'Date Created',
		},
		validation: {
			PaymentIndexTypeName: [regexes.NotEmpty],
			PaymentIndexTypeVal: [regexes.NotEmpty, regexes.PositiveFloat],
			PaymentIndexTypeValueDate: [regexes.NotEmpty],
		},
		datePickerSingle: ['PaymentIndexTypeValueDate'],
		sortBy: ['PaymentIndexTypeName'],
		searchBy: ['PaymentIndexTypeName'],
		onInsertFunc: context.InsertNewPaymentIndexTypeValue,
		onUpdateFunc: context.UpdatePaymentIndexTypeValue,
	});

	useEffect(() => {
		context.GetPaymentIndexTypeValues().then(data => {
			setPaymentTypesValues(data);
		});
		context.GetPaymentIndexTypes().then(data => {
			setProperties(state => ({
				...state,
				selectOptions: {
					...state.selectOptions,
					PaymentIndexTypeName: data,
				},
			}));
		});
	}, [context]);

	return <TemplateModule data={paymentTypesValues} properties={properties} />;
};

export default PaymentIndexTypeValues;
