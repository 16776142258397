import AppContext from 'context';
import { useContext, useEffect, useState, useRef } from 'react';
import TemplateModule from '../TemplateModule';
import * as regexes from 'services/validatationRegexs';

const Labs = () => {
    const context = useRef(useContext(AppContext)).current;
    const [labs, setLabs] = useState([]);
    const [properties] = useState({
        display: ["LabName", "LabDescription"],
        dataRowViewerDisplay: ["LabName", "LabDescription", "IsActive"],
        notEditable:["LabID"],
        primaryKey: ["LabID"],
        readable: {
            removePrefix:[],
            LabName: "Lab Name",
            UnitTypeName: "Unit Type",
            LabDescription: "Description"
        },
        validation: {
            LabName: [regexes.Unique, regexes.NotEmpty]
        },
        sortBy: ["LabName"],
        searchBy: ["LabName"],
        onInsertFunc: context.InsertNewLab,
        onUpdateFunc: context.UpdateLab
    })
    
    useEffect(() =>{
        context.GetLabs().then(data =>{
            setLabs(data); 
        });
    }, [context]);

    return(
        <TemplateModule data={labs} properties={properties}/>
    );
}

export default Labs;