import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useState } from 'react';

const CheckBox = ({cbxID, isChecked, onChange, dataKey}) =>{
    const [checked, setChecked] = useState(isChecked);
    
    const handleCBXClick = () =>{
        onChange(dataKey, !checked, null);
        setChecked(!checked);
    }

    return(
        <>
            <Input id={cbxID} type="checkbox" defaultChecked={isChecked} onChange={() => handleCBXClick()}/>
            <Label htmlFor={cbxID}>
                <svg width="18px" height="18px" viewBox="0 0 18 18">
                    <path d="M1,9 L1,3.5 C1,2 2,1 3.5,1 L14.5,1 C16,1 17,2 17,3.5 L17,14.5 C17,16 16,17 14.5,17 L3.5,17 C2,17 1,16 1,14.5 L1,9 Z"></path>
                    <polyline points="1 9 7 14 15 4"></polyline>
                </svg>
            </Label>
        </>
    );
}

CheckBox.propTypes = {
    id: PropTypes.string,
    isChecked: PropTypes.bool
}

const Label = styled.label`
    cursor: pointer;
    position: relative;
    margin-bottom: initial;
    font-size: 20px !important;
    width: 18px;
    height: 18px;
    -webkit-tap-highlight-color: transparent;
    transform: translate3d(0, 0, 0);

    &:before {
        content: "";
        position: absolute;
        top: -8px;
        left: -8px;
        width: 34px;
        height: 34px;
        border-radius: 50%;
        background: rgba(34, 50, 84, 0.03);
        opacity: 0;
        transition: opacity 0.2s ease;
    }

    svg {
        position: relative;
        z-index: 1;
        fill: none;
        top: -8px;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke: #c8ccd4;
        stroke-width: 1.5px;
        transform: translate3d(0, 0, 0);
        transition: all 0.2s ease;

        path {
            stroke-dasharray: 60;
            stroke-dashoffset: 0;
        }

        polyline {
            stroke-dasharray: 22;
            stroke-dashoffset: 66;
        }
    }

    &:hover {
        &:before {
            opacity: 1;
        }
        svg {
            stroke: #C084AF;
        }
    }
`;

const Input = styled.input`
    display: none;

    &:checked + ${Label}{
        svg{
            stroke: #820A5F;
            path{
                stroke-dashoffset: 60;
                transition: all 0.3s linear;
            }
            polyline{
                stroke-dashoffset: 42;
                transition: all 0.2s linear;
                transition-delay: 0.15s;
            }
        }
    }
    
`;


export default CheckBox;