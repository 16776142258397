import styled, { css } from "styled-components";

const Button = styled.a`
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 12px;
    padding: 10px 15px;
    font-size: 17px;
    background: transparent;
    width: ${props => props.fit? 'fit-content' : (props.width? props.width : '100px')};
    color: ${props => props.theme.colors.darkGrey};
    border: 1px solid ${props => props.theme.colors.darkGrey};
    margin: ${props => props.margin? props.margin : '0'};
    cursor: pointer;
    &:hover{
        color :${props => props.theme.colors.darkGrey};
        text-decoration: none;
    }

    ${props => (props.purple || props.lightPurple || props.grey || props.export) && css`
        color: white;
        border: none;
        &:hover{
            color: white;
            text-decoration: none;
        }
    `};

    ${props => props.purple && css`
        background: ${props.theme.colors.purple};
    `};

    ${props => props.lightPurple && css`
        background: ${props.theme.colors.lightPurple};
        cursor: context-menu;
    `};

    ${props => props.grey && css`
        background: ${props.theme.colors.grey};
    `};

    ${props => props.export && css`
        background: ${props.theme.colors.export};
    `};
`

export default Button;