import AppContext from 'context';
import { useContext, useEffect, useState, useRef } from 'react';
import TemplateModule from '../TemplateModule';
import * as regexes from 'services/validatationRegexs';
import { library } from '@fortawesome/fontawesome-svg-core';
import * as Icons from '@fortawesome/free-solid-svg-icons';
library.add(...Object.keys(Icons).filter(key => key !== "fas" && key !== "prefix").map(icon => Icons[icon]));

const MenuTabs = () => {
    const context = useRef(useContext(AppContext)).current;
    const [menuTabs, setMenuTabs] = useState([]);
    const [properties, setProperties] = useState({
        display: ["MenuTabIcon", "MenuTabDisplayName", "MenuTabOrder", "ModuleDisplayName", "MenuTabDescription"],
        dataRowViewerDisplay: ["MenuTabID", "MenuTabDisplayName", "MenuTabOrder", "MenuTabIcon", "MenuTabDescription", "ModuleDisplayName", "IsActive"],
        notEditable: ["MenuTabID", "MenuTabName", "DateCreated", "MenuTabUrl"],
        priamryKey: ["MenuTabID"],
        readable: {
            removePrefix: [],
            MenuTabDisplayName: "Tab Name",
            MenuTabDescription: "Description",
            MenuTabOrder: "Tab Order",
            MenuTabIcon: "Icon",
            ModuleDisplayName: "Modules"
        },
        validation: {
            MenuTabDisplayName: [regexes.Unique, regexes.NotEmpty, regexes.MaxLength100],
            MenuTabOrder: [regexes.NotEmpty, regexes.PositiveNumber],
            MenuTabDescription: [regexes.MaxLength250],
            MenuTabIcon: [regexes.NotEmpty]
        },
        sortBy: ["MenuTabDisplayName", "MenuTabOrder"],
        searchBy: ["MenuTabDisplayName"],
        selectOptions: {},
        onInsertFunc: context.InsertNewMenuTab,
        onUpdateFunc: context.UpdateMenuTab
    })

    useEffect(() => {
        context.GetTabs().then(tabs => {
            setMenuTabs([]);
            tabs.forEach(tab => {
                context.GetModulesByTab(tab.MenuTabID).then(modules => {
                    modules = modules || [];
                    setMenuTabs(state => [
                        ...state.filter(otherTab => otherTab.MenuTabOrder <= tab.MenuTabOrder),
                        Object.assign(tab, { ModuleDisplayName: modules.filter(module => module.IsActive).map(module => module.ModuleDisplayName).join(', ') }),
                        ...state.filter(otherTab => otherTab.MenuTabOrder > tab.MenuTabOrder),
                    ]);
                })
            })
        });
        context.GetModules().then(data => {
            setProperties(state => ({
                ...state,
                multiSelectOptions: {
                    ...state.multiSelectOptions,
                    ModuleDisplayName: data
                }
            }))
        });
        setProperties(state => ({
            ...state,
            selectOptions: {
                ...state.selectOptions,
                MenuTabIcon: Object.keys(Icons)
                    .filter(key => key !== "fas" && key !== "prefix")
                    .map(icon => {
                        return {
                            MenuTabIcon: Icons[icon].iconName,
                            IsActive: true
                        }
                    })
            }
        }))
    }, [context]);

    return (
        <TemplateModule data={menuTabs} properties={properties} />
    );
}

export default MenuTabs;