import styled from 'styled-components';

const MandatoryOneOf = () =>{

    return(
        <OrWrapper>
            <Span>
                *or
            </Span>
        </OrWrapper>
    );
}

const OrWrapper = styled.div`
    position: absolute;
    top: 38px;
    left: 20px;
`;

const Span = styled.span`
    font-size: 15px;
    padding: 0 5px;
    background: #FFF;
    color: ${props => props.theme.colors.grey};
`;

export default MandatoryOneOf;