import styled from 'styled-components';
import iconGear from 'media/icons/iconGear.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useEffect, useRef } from 'react';
import { setSelectedTab } from 'actions/tabActions';
import { useDispatch, useSelector } from 'react-redux';
import { setTabs as setStoreTabs } from 'actions/tabActions';
import history from 'services/history';
import AppContext from 'context';


const MainMenu = () =>{
    const tabs = useSelector(state => state.tabs.tabs);
    const selectedTab = useSelector(state => state.tabs.selectedTab);
    const dispatch = useDispatch();
    const context = useRef(useContext(AppContext)).current;
    
    useEffect(()=>{
        context.GetTabsByRole().then(data=>{
            data = data || [];
            dispatch(setStoreTabs(data));
        });
    }, [context, dispatch])

    const handleTabClick = (MenuTabID, TabTop) =>{
        if(MenuTabID === selectedTab.MenuTabID){
            dispatch(setSelectedTab(0));
            return;
        }
        dispatch(setSelectedTab(MenuTabID, TabTop));
    }

    return( 
        <MainMenuContainer>
            <TabIconImg src={iconGear} onClick={() => history.push('/')}/>
            <Seperator />
            {
                tabs &&
                tabs.filter(tab => tab.IsActive).map(tab =>{
                    return(
                        <TabContainer data-tooltip={tab.MenuTabDisplayName} key={tab.MenuTabID}>
                            <TabIconFA  
                                icon={tab.MenuTabIcon} 
                                onClick={(e) => handleTabClick(tab.MenuTabID, e.target.getBoundingClientRect().top)}
                            />
                        </TabContainer>
                    ) 
                })
            }
        </MainMenuContainer>
    );
}


const MainMenuContainer = styled.div`
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: ${props => props.theme.colors.lightGrey};
    top: 0;
    left: 0;
    width: 90px;
    height: 100%;
    z-index: 51;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
`;

const Seperator = styled.div`
    height: 2.3px;
    width: 75px;
    margin: 15px 0 10px 0;
    background: white;
`;

const TabContainer = styled.div`
    margin-top: 20px;
`;

const TabIconFA = styled(FontAwesomeIcon)`
    &&&{
        width: 40px;
        height: auto;
        cursor: pointer;
        color: ${props => props.theme.colors.purple}
    }
`;

const TabIconImg = styled.img`
    width: 60px;
    height: auto;
    cursor: pointer;
    margin-top: 20px;
`;

export default MainMenu;