import styled from 'styled-components';
import Button from 'components/form/Button';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import Mask from 'components/site/Mask';
import { useTransition, animated } from 'react-spring';


const ConfirmMsg = ({msg, leftAns, rightAns, onChange, childComponent, lockLeftButton}) =>{
    const [lockLeft, setLockLeft] = useState(lockLeftButton);
    const fixedMsg = msg.split('\\n').map((line, i) => <Span key={i}>{line}</Span>);
    const [showConfirmation, SetShowConfirmation] = useState(true);

    const blockTransitions = useTransition(showConfirmation, null, {
        from: { opacity: 0, transform: 'translateX(40%)' },
        enter: { opacity: 1, transform: 'translateX(-40%)' },
        leave: { opacity: 0, transform: 'translateX(40%)' },
    })

    useEffect(()=> {
        setLockLeft(lockLeftButton)}
    , [lockLeftButton]);

    const handleMaskClick = () =>{
        SetShowConfirmation(false);
        onChange(false);
    }

    return(
        <>
            <Mask opacity={0.1} activate={showConfirmation} click={() => handleMaskClick()} />
            {
                blockTransitions.map(({ item, key, props }) =>
                item &&
                <ConfirmMsgContainer
                    as={animated.div}
                    key={key}
                    style={props}
                >
                    <Msg>{fixedMsg}</Msg>
                    {childComponent}
                    <ButtonsContainer>
                        <ConfirmButton fit purple lockLeft={lockLeft} onClick={() => lockLeft? {} : onChange(true)}>{leftAns}</ConfirmButton>
                        <ConfirmButton fit onClick={() => onChange(false)}>{rightAns}</ConfirmButton>
                    </ButtonsContainer>
                </ConfirmMsgContainer>
                )
            }
        </>
    );
};

ConfirmMsg.propTypes = {
    rightAns: PropTypes.string,
    leftAns: PropTypes.string,
    msg: PropTypes.string,
    onChange: PropTypes.func
}

ConfirmMsg.defaultProps = {
    rightAns: "No",
    leftAns: "Yes",
}

const ConfirmMsgContainer = styled.div`
    position: absolute;
    z-index: 52;
    right: 0;
    padding: 20px;
    bottom: 10px;
    border-radius: 10px;
    width: fit-content;
    background: white;
    box-shadow: ${props => props.theme.shadowBox.light};
`;

const Msg = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    width: 100%;
    height: 80%;
    color: black;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
`;

const Span = styled.span`
    white-space: nowrap;
`;

const ButtonsContainer = styled.div`
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
`;

const ConfirmButton = styled(Button)`
    opacity: ${props => props.lockLeft? "0.3" : "1"};
    cursor: ${props => props.lockLeft? "default" : "pointer"};
    &:first-child{
        margin-right: 10px;
    }
`;

export default ConfirmMsg;