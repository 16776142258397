
import AppContext from 'context';
import { useContext, useEffect, useState, useRef, useMemo } from 'react';
import TemplateModule from '../TemplateModule';
import { useSelector } from 'react-redux';
import * as regexes from 'services/validatationRegexs';
import history from 'services/history';
import ParentHref from 'components/site/ParentHref';
import { isObjectEmpty } from 'services/helpFunctions';

const ActivityLabTestResults = (props) => {
    const labTestID = props.match.params.LabTestID;
    const activityID = props.match.params.ProjectActivityID;
    const projectID = props.match.params.ProjectID;
    const [labTest, setLabTest] = useState(useSelector(state => state.modules.selectedDataRow));
    const [activity, setActivity] = useState(null);
    const [project, setProject] = useState(useSelector(state => state.projects.projects.filter(project => project.ProjectID.toString() === projectID)[0]));
    const user = useSelector(state => state.auths.user);
    const context = useRef(useContext(AppContext)).current;
    const [labTestResults, setLabTestResults] = useState([]);
    const [properties, setProperties] = useState({
        display: ["LabTestName", "TestResultValue", "TestResultDescription"],
        dataRowViewerDisplay: ["UserName", "LabTestName", "TestResultValue", "TestResultDescription", "IsActive", "DateCreated"],
        notEditable: ["RecordID", "LabTestID", "UserName", "DateCreated"],
        primaryKey: ["RecordID"],
        defaultOnInsert: {
            LabTestID: labTestID
        },
        readable: {
            removePrefix: [""],
        },
        validation: {
            TestResultValue: [regexes.NotEmpty, regexes.MaxLength250],
            UserName: [regexes.NotEmpty],
            LabTestName: [regexes.NotEmpty]
        },
        sortBy: [],
        searchBy: [],
        selectOptions: {},
        onInsertFunc: context.InsertNewActivityLabTestResult,
        onUpdateFunc: context.UpdateActivityLabTestResult,
    })

    useMemo(() => {
        if (!project) {
            context.GetProjectByID(projectID).then(project => { project ? setProject(project) : history.push('/') })
        }
    }, [project, context, projectID])

    useMemo(() => {
        if (isObjectEmpty(labTest)) {
            context.GetActivityLabTestByLabTestID(labTestID).then(labTest => { labTest ? setLabTest(labTest) : history.push('/') })
        }
    }, [context, labTest, labTestID])

    useMemo(() => {
        context.GetProjectActivityByID(activityID).then(activity => { activity ? setActivity(activity) : history.push('/') })
    }, [context, activityID])

    useEffect(() => {
        context.GetActivityLabTestResults(labTestID).then(data => {
            if (data) {
                setLabTestResults(data);
            }
        });
        context.GetLabTestTypes().then(labTestsTypes => {
            setProperties(state => ({
                ...state,
                selectOptions: {
                    ...state.selectOptions,
                    LabTestName: labTestsTypes ? labTestsTypes : []
                }
            }));
        });
    }, [context, labTestID]);

    useEffect(() => {
        setProperties(state => ({
            ...state,
            defaultOnInsert: {
                ...state.defaultOnInsert,
                UserName: user.UserName
            },
            defaultOnUpdate: {
                ...state.defaultOnUpdate,
                UserName: user.UserName
            }
        }))
    }, [user.UserName]);

    return (
        !isObjectEmpty(labTest) && !!activity && !!project ?
            <TemplateModule
                data={labTestResults}
                properties={properties}
                title={
                    <ParentHref
                        planText={["Test results for test: ", "For Activity: ", "For project: "]}
                        parentsName={[
                            labTest ? `${labTest["LabTestName"]}` : "",
                            activity ? `${activity["ActivityTypeName"]}` : "",
                            project ? `${project["ProjectName"]}` : "",
                        ]}
                        parents={[labTest, activity, project]}
                    />
                }
            />
            : <></>
    );
}

export default ActivityLabTestResults;