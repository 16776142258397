import React from 'react';
import Mask from 'components/site/Mask';
import styled from 'styled-components'
import loader from 'media/images/loader.png'

const Loader = ({ visible }) => {
    if (visible)
        return (
            <>
                <Mask zindex={60} />
                <GearSpinner src={loader} />
            </>
        )
    return <></>;
};

const GearSpinner = styled.img`
    position: fixed;
    left: 50%;
    top: 50%;
    z-index: 61;
    transform: translate(-50%, -50%);
`;

export default Loader;