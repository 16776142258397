import AppContext from 'context';
import { useContext, useEffect, useState, useRef, useMemo } from 'react';
import TemplateModule from '../TemplateModule';
import { useDispatch, useSelector } from 'react-redux';
import * as regexes from 'services/validatationRegexs';
import history from 'services/history';
import ParentHref from 'components/site/ParentHref';
import { isObjectEmpty } from 'services/helpFunctions';

const ProjectItemsPerActivity = (props) =>{
    const projectID = props.match.params.ProjectID;
    const activityID = props.match.params.ProjectActivityID;
    const user = useSelector(state => state.auths.user);
    const projectActivityID = props.match.params.ProjectActivityID;
    const [project, setProject] = useState(useSelector(state => state.projects.projects.filter(project => project.ProjectID.toString() === projectID)[0]));
    const [activity, setActivity] = useState(useSelector(state => state.modules.selectedDataRow));
    const context = useRef(useContext(AppContext)).current;
    const dispatch = useDispatch();
    const [items, setItems] = useState([]);
    const [properties, setProperties] = useState({
        display: ["ItemTypeName", "ItemsQuantity", "DateCreated"],
        dataRowViewerDisplay: ["UserName", "ItemTypeName", "ItemsQuantity", "IsActive"],
        notEditable:["RecordID", "UserName", "ItemTypeName", "ProjectActivityID"],
        primaryKey: ["RecordID"],
        defaultOnInsert:{
            ProjectActivityID: projectActivityID
        },
        readable: {
            removePrefix:[],
            ItemTypeName: "Item type", 
            ItemsQuantity: "Quantity",
            DateCreated: "Date Added"
        },
        validation: {
            ItemTypeName: [regexes.NotEmpty],
            ItemsQuantity: [regexes.NotEmpty, regexes.ZeroOrPositiveNumber],
            UserName: [regexes.NotEmpty]
        },
        sortBy: ["ItemTypeName", "ItemsQuantity"],
        searchBy: ["ItemTypeName"],
        selectOptions: {},
        onInsertFunc: context.InsertNewProjectItemPerActivity,
        onUpdateFunc: context.UpdateProjectItemPerActivity,
    });

    useMemo(() =>{
        if(!project){
            context.GetProjectByID(projectID).then(project =>{ project? setProject(project) : history.push('/') })
        }
    }, [project, context, projectID])

    useMemo(() =>{
        if(isObjectEmpty(activity)){
            context.GetProjectActivityByID(activityID).then(activity =>{ activity? setActivity(activity) : history.push('/') })
        }
    }, [activity, context, activityID])

    
    useEffect(() =>{
        context.GetProjectItemsPerActivity(projectActivityID).then(data =>{
            if(data){
                setItems(data);
            }
        });
        context.GetProjectItems(projectID).then(items =>{
            setProperties(state => ({
                ...state,
                selectOptions:{
                    ...state.selectOptions,
                    ItemTypeName: items
                }
            }));
        });
    }, [context, dispatch, projectID, projectActivityID]);

    useEffect(() => {
        setProperties(state=>({
            ...state,
            defaultOnInsert: {
                ...state.defaultOnInsert,
                UserName: user.UserName
            },
            defaultOnUpdate: {
                ...state.defaultOnUpdate,
                UserName: user.UserName
            }
        }))
    }, [user.UserName])

    return(
        !isObjectEmpty(activity) && !!project? 
        <TemplateModule 
            data={items} 
            properties={properties} 
            title={
                <ParentHref 
                    planText={["Items for activity: ", "For project: "]}
                    parentsName={[
                        activity
                        ? `${activity["ActivityTypeName"]}` 
                        : "", project? `${project["ProjectName"]}` : ""]}
                    parents={[activity, project]}
                />
            }
            // InitialDefaultSearchOnRestart={false}
        /> 
        : <></>
    );
}

export default ProjectItemsPerActivity;