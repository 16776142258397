import { useDispatch } from "react-redux";
import history from "services/history";
import styled from "styled-components";
import { setDefaultSearch  } from 'actions/searchActions';

const ParentHref = ({planText, parentsName, parents, backUrls}) =>{
    parents = parents || [];
    const dispatch = useDispatch();
    return(
        <div>
            {
                [...Array(parents.length)].map((_, i) =>{
                    return(
                        <span key={i}>
                            {planText[i]}
                            <Href
                                onClick={()=> {
                                    dispatch(setDefaultSearch(parents[i]));
                                    backUrls
                                        ? history.push(backUrls[i]) 
                                        : [...Array(i+1)].forEach(_ =>{ history.goBack(); })
                                }}>
                                {parentsName[i]}
                            </Href>
                            <br />
                        </span>
                    )
                })
            }
        
        </div>
    );
} 

const Href = styled.a`
    cursor: pointer;
    color: black;
    text-decoration: underline;
    &:hover{
        color: black;
    }
`;

export default ParentHref