import PropTypes from "prop-types";
import moment from "moment";
import styled from "styled-components";
import { faSort, faSortUp, faSortDown } from "@fortawesome/free-solid-svg-icons";
import deleteIcon from "media/icons/delete.png";
import editIcon from "media/icons/edit.png";
import viewIcon from "media/icons/view.png";
import downloadDocIcon from "media/icons/downloadDoc.png";
import openDocIcon from "media/icons/openDoc.png";
import excelIcon from "media/icons/excel.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useState, useEffect, useCallback } from "react";
import AppContext from "context";
import ConfirmMsg from "components/site/ConfirmMsg";
import { useDispatch, useSelector } from "react-redux";
import { updateModuleDataRowActive, setSelectedModuleDataRow } from "actions/moduleActions";
import { setDefaultSearch } from "actions/searchActions";
import DataRowViewer from "./DataRowViewer";
import Button from "components/form/Button";
import { fixColName, getEmptyEntity, getFormatedDate, isFloat, saveArrayAsCSV } from "services/helpFunctions";
import Select from "components/form/Select";
import history from "services/history";

const DataTable = ({ moduleName, setShowModule }) => {
  const data = useSelector(state => state.modules.selectedModuleData);
  const selectedTab = useSelector(state => state.tabs.selectedTab);
  const [showDeleteConfirmation, setDeleteConfirmation] = useState(false);
  const [deleteMsg, setDeleteMsg] = useState("Are you sure you\\n want to delete?");
  const [selectedDataRow, setSelectedDataRow] = useState(null);
  const [viewDataRow, setViewDataRow] = useState(false);
  const [editDataRow, setEditDataRow] = useState(false);
  const [insertDataRow, setInsertDataRow] = useState(false);
  const showUnactive = useSelector(state => state.searchs.showUnactive);
  const moduleID = useSelector(state => state.modules.selectedModule.ModuleID);
  const permissions = useSelector(state => state.modules.permissions);
  const [hasEditPermission, setEditPermission] = useState(false);
  const [hasInsertPermission, setInsertPermission] = useState(false);
  const [hasDeletePermission, setDeletePermission] = useState(false);
  const context = useContext(AppContext);
  const dispatch = useDispatch();
  const modules = useSelector(state => state.modules.modules);
  const [sortByProps, setSortByProps] = useState({});
  const {
    display,
    sortBy,
    jumpTo,
    readable,
    isAddable,
    isEditable,
    isDeleteable,
    validation,
    onDocumentDownloadClick,
    onDocumentOpenClick,
    isExportable,
    onAddClick,
    defaultOnInsert,
    defaultOnUpdate,
    onEditClick,
    onUpdateFunc,
    onDeleteMsg,
    datePickerRange,
    datePickerSingle,
    smallDateFormatsKeys,
    filterParams,
  } = useSelector(state => state.modules.properties);

  const [extraCols, setExtraCols] = useState([]);
  const fixedDisplay = display.filter(key => data.reduce((acc, dataRow) => acc || dataRow[key] !== "", false));

  const handleIsEditable = useCallback(() => {
    if (typeof isEditable === "function") return isEditable(showUnactive);
    return isEditable;
  }, [showUnactive, isEditable]);

  useEffect(() => {
    if (moduleID && !!permissions.length) {
      const {
        EditPermission: editPermission,
        DeletePermission: deletePermission,
        InsertPermission: insertPermission,
      } = permissions.find(permission => permission.ModuleID === moduleID);
      setEditPermission(editPermission);
      setInsertPermission(insertPermission);
      setDeletePermission(deletePermission);
      setExtraCols(
        (onDocumentDownloadClick ? ["Open", "Download"] : [])
          .concat(!!jumpTo.passParam.length ? ["Jump To"] : [])
          .concat(["View"])
          .concat(editPermission && handleIsEditable() ? ["Edit"] : [])
          .concat(deletePermission && isDeleteable ? ["Delete"] : [])
      );
    }
  }, [moduleID, handleIsEditable, isDeleteable, permissions, onDocumentDownloadClick, jumpTo, showUnactive]);

  useEffect(() => {
    if (selectedTab.MenuTabID === 0) {
      setShowModule(true);
      setViewDataRow(false);
      setEditDataRow(false);
      setInsertDataRow(false);
    }
  }, [selectedTab]);

  useEffect(() => {
    data.sort((a, b) => {
      if (a[sortBy[0]] < b[sortBy[0]]) return -1;
      if (a[sortBy[0]] > b[sortBy[0]]) return 1;
      return 0;
    });
  }, [data, sortBy]);

  const fixTdData = (key, value) => {
    value = typeof value === "undefined" || value === null ? "" : value;
    const isFloatBool = isFloat(value);
    const isDate = datePickerRange.includes(key) || datePickerSingle.includes(key) || /datecreated/gi.test(key);
    const isEmail = validation[key] && !!validation[key].find(cur => cur.name === "Email");
    switch (true) {
      case isDate:
        return smallDateFormatsKeys.includes(key) ? getFormatedDate(value, "MM/YYYY") : getFormatedDate(value);
      case isEmail:
        return <MailTo href={`mailto:${value}`}>{value}</MailTo>;
      case typeof data[0][key] === "boolean":
        return <FontAwesomeIcon icon={value ? "check" : "times"} color="black" />;
      case /icon$/gi.test(key):
        return <FontAwesomeIcon icon={value} color="black" />;
      case isFloatBool:
        return value.toFixed(2);
      default:
        return value.toString();
    }
  };

  const handleDeleteClick = dataRow => {
    setSelectedDataRow(dataRow);
    onDeleteMsg && setDeleteMsg(onDeleteMsg(dataRow));
    setDeleteConfirmation(true);
  };

  const handleViewClick = dataRow => {
    setSelectedDataRow(dataRow);
    setViewDataRow(true);
    setShowModule(false);
  };

  const handleEditClick = dataRow => {
    const newDataRow = { ...dataRow, ...defaultOnUpdate };
    handleViewClick(newDataRow);
    setEditDataRow(true);
  };

  const handleNewRowClicked = async () => {
    let emptyDataRow = await getEmptyEntity(moduleName, data[0], defaultOnInsert, datePickerSingle, context.GetEmptyEntity);
    if (onAddClick) {
      onAddClick();
      return;
    }
    setSelectedDataRow(emptyDataRow);
    setViewDataRow(true);
    setShowModule(false);
    setInsertDataRow(true);
  };

  const onDeleteAnswerChange = answer => {
    if (answer === true) {
      onUpdateFunc({ ...selectedDataRow, IsActive: false }).then(res => {
        if (res === true) {
          dispatch(updateModuleDataRowActive(selectedDataRow, false));
        }
      });
    }
    setSelectedDataRow(null);
    setDeleteConfirmation(false);
  };

  const handleJumpTo = (moduleName, dataRow) => {
    const curPath = window.location.pathname;
    const moduleUrl = modules.filter(module => module.ModuleName === moduleName).map(module => module.ModuleUrl)[0];
    const passParam = jumpTo.passParam;
    let requestedPath;
    if (jumpTo.replaceUrlPrefix) {
      requestedPath = moduleUrl;
      passParam.forEach(param => (requestedPath = requestedPath.replace(`:${param}`, dataRow[param])));
    } else {
      requestedPath =
        curPath + moduleUrl.split(curPath.substr(curPath.lastIndexOf("/")))[1].replace(`:${passParam[0]}`, dataRow[passParam[0]]);
    }
    requestedPath = requestedPath.replace(/:(\w+)/, (_v1, v2) => dataRow[v2] ?? "");
    dispatch(setSelectedModuleDataRow(dataRow));
    dispatch(setDefaultSearch({}));
    if (filterParams) {
      history.push({
        pathname: requestedPath,
        state: {
          filterParams: filterParams.map(param => {
            return { [param]: dataRow[param] };
          }),
        },
      });
      return;
    }
    history.push(requestedPath);
  };

  const handleSortClick = sortKey => {
    if (!sortBy.includes(sortKey) || !data.length) return;
    let nextIcon = faSortDown;
    let isReveresd = false;
    const isNumeric = typeof data[0][sortKey] === "number";
    if (sortByProps[sortKey]) {
      const curIcon = sortByProps[sortKey];
      nextIcon = curIcon === faSortDown ? faSortUp : faSortDown;
      isReveresd = curIcon === faSortDown;
    }
    data.sort((dataRow1, dataRow2) => {
      const val1 = dataRow1[sortKey] ? dataRow1[sortKey].toString() : "";
      const val2 = dataRow2[sortKey] ? dataRow2[sortKey].toString() : "";
      return val1.localeCompare(val2, undefined, { numeric: isNumeric });
    });
    if (isReveresd) data.reverse();
    setSortByProps({ [sortKey]: nextIcon });
  };

  return (
    <>
      {viewDataRow && (
        <DataRowViewer
          dataRow={selectedDataRow}
          isEdit={editDataRow}
          isInsert={insertDataRow}
          closePopUp={() => {
            setShowModule(true);
            setViewDataRow(false);
            setEditDataRow(false);
            setInsertDataRow(false);
            setSelectedDataRow({});
          }}
        />
      )}
      <DataTableContainer display={viewDataRow ? "none" : "block"}>
        <TableContainer hasItems={Object.keys(data).filter(dataRow => data[dataRow]["IsActive"] !== showUnactive).length !== 0}>
          <Table>
            <thead>
              <TrHeader>
                {fixedDisplay.map((title, index) => {
                  return (
                    <ThInfo key={index} isClickable={sortBy.includes(title)} onClick={() => handleSortClick(title)}>
                      {fixColName(title, readable)}
                      {(sortByProps[title] || sortBy.includes(title)) && <SortIcon icon={sortByProps[title] || faSort} />}
                    </ThInfo>
                  );
                })}
                {extraCols.map((title, index) => (
                  <ThExtraCols key={index}>{title}</ThExtraCols>
                ))}
              </TrHeader>
            </thead>

            <tbody>
              {data.length > 0 &&
                data
                  .filter(dataRow => dataRow?.IsActive !== showUnactive)
                  .map((dataRow, index) => {
                    return (
                      <TrBody key={index}>
                        {fixedDisplay.map((colKey, index) => (
                          <TdInfo key={index}>{fixTdData(colKey, dataRow[colKey])}</TdInfo>
                        ))}
                        {!!jumpTo.passParam.length && (
                          <Td width={260}>
                            <Select
                              width={250}
                              options={Object.keys(jumpTo.associatedModules)
                                .filter(key => {
                                  const relevantModule = modules.find(module => module.ModuleName === jumpTo.associatedModules[key]);

                                  let relevantPermission = permissions.find(
                                    per => relevantModule && per.ModuleID === relevantModule.ModuleID
                                  );

                                  const isWaterSampling = dataRow.ActivityTypeName === "Water sampling";
                                  const showLabTestOnlyWaterSmapling = isWaterSampling || relevantModule.ModuleName !== "ActivityLabTests"; //show only lab test for water smapling
                                  return relevantPermission && relevantPermission.ModulePermission === true && showLabTestOnlyWaterSmapling;
                                })
                                .map(key => {
                                  return {
                                    label: key,
                                    value: jumpTo.associatedModules[key],
                                  };
                                })
                                .sort((a, b) => {
                                  if (a.label < b.label) return -1;
                                  if (a.label > b.label) return 1;
                                  return 0;
                                })}
                              onChange={e => handleJumpTo(e.value, dataRow)}
                              menuPosition={"fixed"}
                              menuShouldBlockScroll
                            />
                          </Td>
                        )}
                        {!!onDocumentOpenClick && (
                          <Td width={70}>
                            <TableImg src={openDocIcon} onClick={() => onDocumentOpenClick(dataRow)} />
                          </Td>
                        )}
                        {!!onDocumentDownloadClick && (
                          <Td width={70}>
                            <TableImg src={downloadDocIcon} onClick={() => onDocumentDownloadClick(dataRow)} />
                          </Td>
                        )}
                        <Td width={70}>
                          <TableImg src={viewIcon} onClick={() => handleViewClick(dataRow)} />
                        </Td>
                        {hasEditPermission && handleIsEditable() && (
                          <Td width={70}>
                            <TableImg
                              src={editIcon}
                              onClick={() => {
                                handleEditClick(dataRow);
                                onEditClick(dataRow);
                              }}
                            />
                          </Td>
                        )}
                        {hasDeletePermission && isDeleteable && (
                          <Td width={70}>
                            <TableImg
                              src={deleteIcon}
                              onClick={!showUnactive ? () => handleDeleteClick(dataRow) : () => {}}
                              enabled={!showUnactive || dataRow.IsActive === undefined}
                              width={"25px"}
                            />
                          </Td>
                        )}
                      </TrBody>
                    );
                  })}
            </tbody>
          </Table>
        </TableContainer>
        {Object.keys(data).filter(dataRow => data[dataRow]["IsActive"] !== showUnactive).length === 0 && (
          <NoResults>No Results Found.</NoResults>
        )}
        <AddColumn>
          {isExportable && data?.filter(dataRow => dataRow?.IsActive !== showUnactive).length > 1 && (
            <Button
              export
              fit
              onClick={() => {
                if (!data || data?.length < 1) return;
                const dataToExport = data.filter(dataRow => dataRow?.IsActive !== showUnactive);
                saveArrayAsCSV(`exported_data_${moment().format("DD/MM/YY")}`, dataToExport);
              }}
              margin={"0 10px 0 0"}
            >
              <TableImg src={excelIcon} width={"25px"} />
              {"\u00A0 Export "}
            </Button>
          )}
          {isAddable && hasInsertPermission && (
            <Button purple fit onClick={() => handleNewRowClicked()}>
              + Add New
            </Button>
          )}
        </AddColumn>
      </DataTableContainer>
      {showDeleteConfirmation && <ConfirmMsg msg={deleteMsg} onChange={onDeleteAnswerChange} />}
    </>
  );
};

DataTable.propTypes = {
  moduleName: PropTypes.string,
  setShowModule: PropTypes.func,
};

DataTable.defaultProps = {};

const DataTableContainer = styled.div`
  display: ${props => props.display};
  width: 95%;
  padding: 19px;
  border-radius: 20px;
  box-shadow: ${props => props.theme.shadowBox.light};
`;

const TableContainer = styled.div`
  min-height: 47px;
  max-height: 520px;
  overflow-y: auto;
`;

const Table = styled.table`
  width: 100%;
  border: none;
  border-collapse: collapse;
  border-radius: 30px;
`;

const TrHeader = styled.tr`
  white-space: nowrap;
`;

const TrBody = styled.tr`
  height: 50px;
  &:nth-of-type(even) {
    background-color: ${props => props.theme.colors.lightGrey};
  }
  &:hover {
    background-color: ${props => props.theme.colors.lightPurple};
  }
`;

const Td = styled.td`
  position: relative;
  border: none;
  padding: 5px 10px;
  vertical-align: middle;
  text-align: center;
  color: #000;
`;

const TdInfo = styled(Td)`
  text-align: start;
  &:first-of-type {
    padding-left: 30px;
  }
`;

const Th = styled.th`
  position: sticky;
  top: 0;
  z-index: 1;
  border: none;
  padding: 10px 10px;
  cursor: ${props => (props.isClickable ? "pointer" : "context-menu")};
  background-color: ${props => props.theme.colors.grey};
  &:first-of-type {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  &:last-of-type {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`;

const ThExtraCols = styled(Th)`
  text-align: center;
`;

const ThInfo = styled(Th)`
  text-align: start;
  &:first-of-type {
    padding-left: 30px;
  }
`;

const SortIcon = styled(FontAwesomeIcon)`
  cursor: pointer;
  margin-left: 5px;
  font-size: 15px;
  color: ${props => props.theme.colors.darkGrey};
`;

const AddColumn = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 45px;
  margin-top: 13px;
`;

const TableImg = styled.img`
  width: ${props => (props.width ? props.width : "30px")};
  cursor: ${props => (props.enabled ? "pointer" : "context-menu")};
  opacity: ${props => (props.enabled ? 1 : 0.2)};
  display: ${props => props.display};
`;

TableImg.defaultProps = {
  enabled: true,
  display: "initial",
};

const NoResults = styled.div`
  width: fit-content;
  color: ${props => props.theme.colors.darkGrey};
  font-size: 17px;
  padding: 8px 20px;
  text-align: center;
  border-radius: 10px;
`;

const MailTo = styled.a`
  color: #000;
  text-decoration: underline !important;
  &:hover {
    color: #000;
  }
`;

export default DataTable;
