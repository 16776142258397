
import AppContext from 'context';
import { useContext, useEffect, useState, useRef } from 'react';
import TemplateModule from '../TemplateModule';
import { useDispatch, useSelector } from 'react-redux';
import * as regexes from 'services/validatationRegexs';
import { insertProjects } from 'actions/projectActions';

const Projects = () => {
    const context = useRef(useContext(AppContext)).current;
    const user = useSelector(state => state.auths.user);
    const dispatch = useDispatch();
    const [projects, setProjects] = useState([]);
    const [properties, setProperties] = useState({
        display: ["ProjectName", "CustomerName", "ProjectStartDate", "TechnologyTypeName"],
        dataRowViewerDisplay: ["UserName", "ProjectName", "CustomerName", "TechnologyTypeName", "ProjectAddress", "ProjectLocationGMT", "ProjectDataGMT",
            "ProjectContactEmail", "ProjectContactFName", "ProjectContactLName", "ProjectContactPhone", "ProjectStartDate", "WaterReadingStartValue",
            "ProjectBuildupCost", "IsActive", "DateCreated"],
        notEditable: ["ProjectID", "UserName", "DateCreated"],
        primaryKey: ["ProjectID"],
        defaultOnInsert: {
            WaterReadingStartValue: "0",
        },
        readable: {
            removePrefix: [],
            ProjectName: "Project Name",
            CustomerName: "Customer",
            ProjectContactEmail: "Contact Email",
            ContactFName: "Contact First Name",
            ContactLName: "Contact Last Name",
            ProjectContactPhone: "Contact Phone",
            ProjectStartDate: "Start Date",
            TechnologyTypeName: "Technology Type",
            ProjectBuildupCost: "Buildup Cost",
        },
        validation: {
            ProjectName: [regexes.Unique, regexes.NotEmpty, regexes.MaxLength100],
            UserName: [regexes.NotEmpty],
            CustomerName: [regexes.NotEmpty],
            ProjectAddress: [regexes.NotEmpty, regexes.MaxLength250],
            ProjectLocationGMT: [regexes.Float],
            ProjectDataGMT: [regexes.PositiveFloat],
            ProjectContactEmail: [regexes.NotEmpty, regexes.Email, regexes.MaxLength100],
            ProjectContactFName: [regexes.NotEmpty, regexes.MaxLength50, regexes.Alphabetic],
            ProjectContactLName: [regexes.NotEmpty, regexes.MaxLength50, regexes.Alphabetic],
            ProjectContactPhone: [regexes.NotEmpty, regexes.MaxLength50],
            ProjectStartDate: [regexes.NotEmpty],
            ProjectBuildupCost: [regexes.PositiveFloat],
            WaterReadingStartValue: [regexes.NotEmpty, regexes.PositiveFloat],
            TechnologyTypeName: [regexes.NotEmpty]
        },
        datePickerSingle: ["ProjectStartDate"],
        sortBy: ["ProjectName", "CustomerName", "ProjectStartDate", "ProjectBuildupCost", "TechnologyTypeName"],
        searchBy: ["ProjectName", "CustomerName", "TechnologyTypeName"],
        selectOptions: {},
        multiSelectOptions: {},
        jumpTo: {
            passParam: ["ProjectID"],
            replaceUrlPrefix: false,
            associatedModules: {
                //<DiplayName>: <ComponentName+ModuleName (Same Name)>
                "Incomes": "ProjectIncomes",
                "Items": "ProjectItems",
                "Activities": "ProjectActivities",
                "Documents": "ProjectDocuments",
                "Technologies": "ProjectTechnologies",
                "Requested Lab Tests": "ProjectLabTests",
                "Data Field Values": "ProjectDataFieldValues",
                "Contracts": "Contracts",
            }
        },
        onInsertFunc: context.InsertNewProject,
        onUpdateFunc: context.UpdateProject
    })

    useEffect(() => {
        context.GetProjects().then(data => {
            if (data) {
                setProjects(data);
                dispatch(insertProjects(data));
            }
        });
        context.GetCustomers().then(data => {
            if (data) {
                setProperties(state => ({
                    ...state,
                    selectOptions: {
                        ...state.selectOptions,
                        CustomerName: data
                    }
                }));
            }
        });
        context.GetTechnologyTypes().then(data => {
            if (data) {
                setProperties(state => ({
                    ...state,
                    selectOptions: {
                        ...state.selectOptions,
                        TechnologyTypeName: data
                    }
                }));
            }
        });
    }, [dispatch, context, user.UserName]);

    useEffect(() => {
        setProperties(state => ({
            ...state,
            defaultOnInsert: {
                ...state.defaultOnInsert,
                UserName: user.UserName
            },
            defaultOnUpdate: {
                ...state.defaultOnUpdate,
                UserName: user.UserName
            }
        }))
    }, [user.UserName])

    return (
        <TemplateModule data={projects} properties={properties} />
    );
}

export default Projects;