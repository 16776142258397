import AppContext from "context";
import { useContext, useEffect, useState, useCallback, useRef } from "react";
import TemplateModule from "../TemplateModule";
import * as regexes from "services/validatationRegexs";

const PaymentHierarchyTypes = () => {
  const context = useContext(AppContext);
  const [hierarchyTypes, setHierarchyTypes] = useState([]);
  
  const UpdatePaymentHierarchyType = 
    async updatedPaymentHierarchyType => {
      const TimingType = context.GetTimingTypes();
      const { TimingTypeID } = TimingType.find(t => t.Description === updatedPaymentHierarchyType.TimingTypeDescription);
      updatedPaymentHierarchyType.TimingType = TimingTypeID;
      return context.UpdatePaymentHierarchyType(updatedPaymentHierarchyType);
    }


  const [properties, setProperties] = useState({
    display: ["PaymentHierarchyTypeName", "PaymentHierarchyTypeDescription"],
    dataRowViewerDisplay: ["PaymentHierarchyTypeName", "PaymentHierarchyTypeDescription", "IsActive", "TimingTypeDescription"],
    notEditable: ["PaymentHierarchyTypeID"],
    primaryKey: ["PaymentHierarchyTypeID"],
    readable: {
      removePrefix: [],
      PaymentHierarchyTypeName: "Payment Heirarchy Type",
      PaymentHierarchyTypeDescription: "Description",
      TimingTypeDescription: "Timing Type",
    },
    validation: {
      PaymentHierarchyTypeName: [regexes.Unique, regexes.NotEmpty, regexes.MaxLength100],
      PaymentHierarchyTypeDescription: [regexes.MaxLength250],
    },
    sortBy: ["PaymentHierarchyTypeName"],
    searchBy: ["PaymentHierarchyTypeName"],
    onInsertFunc: context.InsertNewPaymentHierarchyType,
    onUpdateFunc: UpdatePaymentHierarchyType,
    selectOptions: {
      TimingTypeDescription: [],
    },
  });

  useEffect(() => {
    context.GetPaymentHierarchyTypes().then(data => {
      setHierarchyTypes(data);
    });
    context.getPaymentHierarchyTypeTimingTypes().then(timingTypes => {
      setProperties(state => ({
        ...state,
        onUpdateFunc: UpdatePaymentHierarchyType,
        selectOptions: {
          ...state.selectOptions,
          TimingTypeDescription: {
            Description: timingTypes,
          },
        },
      }));
    });
  }, []);

  return <TemplateModule data={hierarchyTypes} properties={properties} />;
};

export default PaymentHierarchyTypes;
